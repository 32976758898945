import {
  Component,
  OnChanges,
  Input,
  Inject,
  PLATFORM_ID,
  OnInit,
} from '@angular/core';
import { Constants } from '@base/src/app/core/constants/constants';
import { UtilsService } from '@app/services/utils.service';
import { environment } from '@base/environments/environment';
import { Listing } from '@app/core/models/listing.model';
import { PictureSource } from '@app/core/models/picture-source';
import { PictureSourceComponent } from '../ui/picture-source/picture-source.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { QrNumberPipe } from '../../pipes/qr-number.pipe';
import { QrButtonComponent } from '../qr-button/qr-button.component';

import { L10N_LOCALE, L10nLocale, L10nTranslatePipe } from 'angular-l10n';

@Component({
  selector: 'qr-card-details-emprendimientos-item',
  templateUrl: './qr-card-details-emprendimientos-item.component.html',
  styleUrls: ['./qr-card-details-emprendimientos-item.component.scss'],
  standalone: true,
  imports: [
    PictureSourceComponent,
    MatTooltipModule,
    QrNumberPipe,
    QrButtonComponent,
    L10nTranslatePipe,
  ],
})
export class QrCardDetailsEmprendimientosItemComponent
  implements OnChanges, OnInit
{
  @Input() unit: any;
  readonly imageCloudfront: string = environment.imageCloudfront;
  resizedImageWebp: string = '';

  imageBucketResizeUrl: string = environment.imageBucketResizeUrl;
  buttonSeeMore = {
    style: Constants.BUTTON_COLOR_PRIMARY_BORDER,
    height: Constants.BUTTON_HEIGHT_48PX,
    text: 'Ver más',
  };

  imageError = false;
  data = {
    photo: this.imageCloudfront + 'home/emprendimientos-home.webp',
    name: 'algo',
    rawPhoto: this.imageCloudfront + 'home/emprendimientos-home.webp',
  };

  features = [
    {
      icon: 'm2totals',
      name: 'm2 Totales',
      quantity: 0,
    },

    {
      icon: 'rooms',
      name: 'Ambientes',
      quantity: 0,
    },

    {
      icon: 'bathroom',
      name: 'Baños',
      quantity: 0,
    },
    {
      icon: 'parking',
      name: 'Cocheras',
      quantity: 0,
    },
  ];

  // Picture and Source
  entrepreneurshipResizedImages: PictureSource = {
    text: Constants.FOLDER_ENTREPRENEURSHIPS,
    docType: 'image/' + Constants.DOCTYPE_WEBP,
    loading: PictureSource.LOADING_LAZY,
  };

  constructor(
    public utils: UtilsService,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {}

  ngOnInit(): void {
    this.resizePhotosWebp();
  }

  ngOnChanges(): void {
    //     if (isPlatformServer(this.platformId) && this.unit) {
    this.generateFeatures(this.unit);
    //     }
  }

  public onOpenUnit(): void {
    const link = '/units/' + this.unit?.slug;
    window.open(link, '_blank');
  }

  private generateFeatures(unit: Listing): void {
    if (unit.type.value == 'terrenos_y_lotes') {
      const item = [
        {
          icon: 'm2totals',
          name: 'm2 Totales',
          quantity: unit.dimensionLand || 0,
        },
      ];
      this.features = Object.assign([], item);
    } else {
      this.features[0].quantity = unit.dimensionTotalBuilt || 0;
      this.features[1].quantity = unit.totalRooms || 0;
      this.features[2].quantity = unit.bathrooms || 0;
      this.features[3].quantity = unit.parkingSpaces || 0;
      this.features = Object.assign([], this.features);
    }

    if (this.unit.photos && this.unit.photos.length > 0) {
      if (this.unit.photos[0].rawValue) {
        this.data.rawPhoto = this.unit.photos[0].rawValue;
      }
      if (this.unit.photos[0].value) {
        this.data.photo = this.unit.photos[0].value;
      }
      this.data.name = this.unit.title;
    }
  }

  private resizePhotosWebp(): void {
    if (this.data.rawPhoto == null) {
      this.resizedImageWebp = '';
      return;
    }

    this.resizedImageWebp = this.utils.generateImageWithSize(
      this.data.rawPhoto,
      '180x180'
    );
  }
}
