import { Injectable, Inject, Optional, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import {
  L10nConfig,
  L10nStorage,
  L10nLocale,
  L10nTranslationLoader,
  L10nProvider,
  L10nValidation,
  L10N_LOCALE,
  L10nNumberFormatOptions,
  parseDigits,
} from 'angular-l10n';
import { environment } from '@base/environments/environment';

const getDefaultLocaleNode = function (): any {
  switch (environment.node) {
    case 'uy':
      return {
        language: 'es-UY',
        currency: '$',
        timeZone: 'America/Montevideo',
      };

    case 'ec':
      return {
        language: 'es-EC',
        currency: '$',
        timeZone: 'America/Ecuador',
      };

    default:
      return {
        language: 'es-AR',
        currency: '$',
        timeZone: 'America/Buenos_Aires',
      };
  }
};

const getSchemaNode = function (): any[] {
  const schema = [
    {
      locale: {
        language: 'en-US',
        currency: 'USD',
        timeZone: 'America/Los_Angeles',
      },
      dir: 'ltr',
      text: 'United States',
    },
  ];
  switch (environment.node) {
    case 'uy':
      schema.push({
        locale: {
          language: 'es-UY',
          currency: '$',
          timeZone: 'America/Montevideo',
        },
        dir: 'ltr',
        text: 'Uruguay',
      });
      break;

    case 'ec':
      schema.push({
        locale: {
          language: 'es-EC',
          currency: '$',
          timeZone: 'America/Ecuador',
        },
        dir: 'ltr',
        text: 'Ecuador',
      });
      break;

    default:
      schema.push({
        locale: {
          language: 'es-AR',
          currency: '$',
          timeZone: 'America/Buenos_Aires',
        },
        dir: 'ltr',
        text: 'Argentina',
      });
      break;
  }

  return schema;
};

const defaultLocaleNode = getDefaultLocaleNode();
const schemaNode = getSchemaNode();

export const l10nConfig: L10nConfig = {
  format: 'language-region',
  providers: [
    {
      name: 'app',
      asset: './assets/i18n/app',
      options: { version: '11.0.0' },
    },
    // { name: 'app', asset: './dev/assets/i18n/app', options: { version: '11.0.0' } }
  ],
  fallback: false,
  cache: true,
  keySeparator: '.',
  defaultLocale: defaultLocaleNode,
  schema: schemaNode,
};

@Injectable()
export class AppStorage implements L10nStorage {
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private cookieService: CookieService
  ) {}

  public async read(): Promise<L10nLocale | null> {
    const value = this.cookieService.get('locale');
    if (value === '') return null;
    return Promise.resolve(JSON.parse(value) as L10nLocale);
  }

  public async write(locale: L10nLocale): Promise<void> {
    if (isPlatformBrowser(this.platformId)) {
      this.cookieService.set('locale', JSON.stringify(locale));
    }
  }
}

@Injectable()
export class HttpTranslationLoader implements L10nTranslationLoader {
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(@Optional() private http: HttpClient) {}

  public get(
    language: string,
    provider: L10nProvider
  ): Observable<{ [key: string]: any }> {
    // Harcodeado para que siempre este en castellano
    // Solicitado el 17-02-2021
    if (environment.node == 'uy') {
      language = 'es-UY';
    } else if (environment.node == 'ec') {
      language = 'es-EC';
    } else {
      language = 'es-AR';
    }
    // fin Harcodeo
    //
    const url = `${provider.asset}-${language}.json`;
    const options = {
      headers: this.headers,
      params: new HttpParams().set('v', provider.options.version),
    };
    return this.http.get(url, options);
  }
}

@Injectable()
export class LocaleValidation implements L10nValidation {
  constructor(@Inject(L10N_LOCALE) private locale: L10nLocale) {}

  public parseNumber(
    value: string,
    options?: L10nNumberFormatOptions,
    language = this.locale.language
  ): number | null {
    if (value === '' || value == null) return null;

    let format: any = {
      minimumIntegerDigits: 1,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    };
    if (options && options.digits) {
      format = { ...format, ...parseDigits(options.digits) };
    }

    let decimalSeparator: string;
    switch (language) {
      case 'it-IT':
        decimalSeparator = ',';
        break;
      default:
        decimalSeparator = '.';
    }

    const pattern = `^-?[\\d]{${format.minimumIntegerDigits},}(\\${decimalSeparator}[\\d]{${format.minimumFractionDigits},${format.maximumFractionDigits}})?$`;
    const regex = new RegExp(pattern);
    return regex.test(value)
      ? parseFloat(value.replace(decimalSeparator, '.'))
      : null;
  }

  public parseDate(): Date | null {
    return null;
  }
}
