<swiper-container
  [id]="'tabs-swiper-container-' + randomID"
  [config]="swiperTabs"
  class="tabs"
  [style.height.px]="config?.size"
  [class.disabled]="isDisabled">
  @for (tab of config.tabs; track tab; let i = $index) {
    <div
      [id]="'tab-' + i + '-' + randomID"
      class="tab"
      [class.active]="config.tabSelected === i">
      <button
        class="tab-wrapper subheading-03-bold"
        (click)="select(i)"
        matRipple
        aria-hidden="true"
        aria-label="Tabs">
        @if (tab.icon) {
          <qr-icon
            [config]="tab.icon"
            class="icon"
            [class.active]="config.tabSelected === i"></qr-icon>
        }
        {{ tab.text }}
      </button>
      <div class="border"></div>
    </div>
  }
</swiper-container>
