import { Component, Input } from '@angular/core';
import { QRButtonComponent } from '../../../../../components/ui/qr-button/qr-button.component';
import { QRButton } from '@base/src/app/core/models/qr-button.model';
import { QRIcon } from '@base/src/app/core/models/qr-icon.model';
import { ActivatedRoute, Params } from '@angular/router';
import { Auth } from 'aws-amplify';
import { Constants } from '@base/src/app/core/constants/constants';
import {
  QRDialogConfig,
  QrDialogComponent,
} from '@base/src/app/components/qr-dialog/qr-dialog.component';

@Component({
  selector: 'button-save-search',
  standalone: true,
  imports: [QRButtonComponent, QrDialogComponent],
  templateUrl: './button-save-search.component.html',
  styleUrl: './button-save-search.component.scss',
})
export class ButtonSaveSearchComponent {
  @Input() isButtonSaveSearchAbbreviated: boolean = false;
  public readonly BUTTON_SAVE_SEARCH: QRButton = {
    id: 'button-save-search',
    hierarchy: QRButton.HIERARCHY_PRIMARY_BORDER,
    size: QRButton.SIZE_48_PX,
    value: 'list.saveSearch',
    icon: QRIcon.NAME_BOOKMARK,
  };

  public readonly BUTTON_SAVE_SEARCH_ABBR: QRButton = {
    id: 'button-save-search',
    hierarchy: QRButton.HIERARCHY_PRIMARY_BORDER,
    size: QRButton.SIZE_48_PX,
    value: 'list.saveSearchAbbr',
    icon: QRIcon.NAME_BOOKMARK,
  };

  public dialogDataSaveSearch: QRDialogConfig = {
    isDialogOpen: false,
    titleFilter: 'Guardá esta búsqueda',
    configButtonLeft: {
      style: Constants.BUTTON_COLOR_PRIMARY_BASIC,
      height: Constants.BUTTON_HEIGHT_48PX,
      text: 'Cancelar',
      id: 'clean-filters',
      selected: false,
      isDisabled: false,
      changeStyleOnSelected: false,
      styleOnSelected: '',
    },
    configButtonRight: {
      style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
      height: Constants.BUTTON_HEIGHT_48PX,
      text: 'Guardar búsqueda',
      id: 'apply',
      selected: false,
      isDisabled: false,
      changeStyleOnSelected: false,
      styleOnSelected: '',
    },
    dinamicComponent: 'save-search',
    isTopbarVisible: true,
    isBotbarVisible: true,
    isHeightAutoFitContent: true,
  };

  public dialogDataNotSaveSearch: QRDialogConfig = {
    isDialogOpen: false,
    titleFilter: 'Guardá esta búsqueda',
    configButtonRight: {
      style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
      height: Constants.BUTTON_HEIGHT_48PX,
      text: 'Aceptar',
      id: 'apply',
      selected: false,
      isDisabled: false,
      changeStyleOnSelected: false,
      styleOnSelected: '',
    },
    dinamicComponent: 'not-save-search',
    isTopbarVisible: true,
    isBotbarVisible: true,
    isHeightAutoFitContent: true,
  };

  public dialogCreateAccount: QRDialogConfig = {
    isDialogOpen: false,
    titleFilter: '¡Bienvenido!',
    configButtonLeft: {
      style: Constants.BUTTON_COLOR_PRIMARY_BASIC,
      height: Constants.BUTTON_HEIGHT_48PX,
      text: 'Cancelar',
      id: 'clean-filters',
      selected: false,
      isDisabled: false,
      changeStyleOnSelected: false,
      styleOnSelected: '',
    },
    configButtonRight: {
      style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
      height: Constants.BUTTON_HEIGHT_48PX,
      text: 'Crear mi cuenta',
      id: '@apply',
      selected: false,
      isDisabled: false,
      changeStyleOnSelected: false,
      styleOnSelected: '',
    },
    dinamicComponent: 'invitation-to-register',
    isTopbarVisible: true,
    isBotbarVisible: false,
    isHeightAutoFitContent: true,
    fixedWidth: 460,
  };

  constructor(private route: ActivatedRoute) {}

  public openSaveSearch(): void {
    const PARAMS: Params = this.route.snapshot.queryParams;
    const HAS_TYPEID: number = PARAMS['in:typeId'];
    const HAS_LOCATION: string = PARAMS['locations'];

    Auth.currentAuthenticatedUser()
      .then(() => {
        if (HAS_TYPEID && HAS_LOCATION) {
          this.dialogDataSaveSearch = {
            ...this.dialogDataSaveSearch,
            isDialogOpen: true,
          };
          this.dialogDataSaveSearch = Object.assign(
            {},
            this.dialogDataSaveSearch
          );
        } else {
          this.dialogDataNotSaveSearch = {
            ...this.dialogDataNotSaveSearch,
            isDialogOpen: true,
          };
          this.dialogDataNotSaveSearch = Object.assign(
            {},
            this.dialogDataNotSaveSearch
          );
        }
      })
      .catch(() => {
        this.dialogCreateAccount = {
          ...this.dialogCreateAccount,
          isDialogOpen: true,
        };
        this.dialogCreateAccount = Object.assign({}, this.dialogCreateAccount);
      });
  }
}
