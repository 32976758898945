import { EventEmitter, Inject } from '@angular/core';
import { Output } from '@angular/core';
import { Component, Input } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { L10nLocale, L10N_LOCALE, L10nTranslationModule } from 'angular-l10n';

export interface ButtonWithIconConfig {
  text: string; // Texto que se muestra en el botón
  textSecondary?: string; // Texto que acompaña al texto principal
  icon: string; // Nombre del icono para clase que va en el SCSS
  height: number; // Altura del botón
  paddingHorizontal: number; // Padding para left y right
  paddingVertical: number; // Padding para top y bottom
  style: string; // Clases para darle estilos definidos
  isEntrepreneurship?: boolean; // Para saber si es emprendimientos o no
  // NOTA: para posicionar el icono del lado derecho pasar en style la clase 'row-reverse'
}
@Component({
  selector: 'qr-button-with-icon',
  templateUrl: './qr-button-with-icon.component.html',
  styleUrls: ['./qr-button-with-icon.component.scss'],
  standalone: true,
  imports: [L10nTranslationModule, MatRippleModule],
})
export class QrButtonWithIconComponent {
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() click: EventEmitter<any> = new EventEmitter<any>();
  @Input() config!: ButtonWithIconConfig;

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale) {}

  onClick($event: Event): void {
    if (this.click) {
      $event.stopPropagation();
      this.click.emit($event);
    }
  }
}
