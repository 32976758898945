import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Constants } from '@base/src/app/core/constants/constants';
import { Auth } from 'aws-amplify';
import { QrMediaImgComponent } from '@app/components/qr-media-img/qr-media-img.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { QrButtonComponent } from '@app/components/qr-button/qr-button.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CountriesPhoneListComponent } from '@app/components/countries-phone-list/countries-phone-list.component';
import { QrIconButtonComponent } from '@app/components/qr-icon-button/qr-icon-button.component';
import { MatInputModule } from '@angular/material/input';
import { QRMediaImage } from '@base/src/app/core/models/qr-media-img';

@Component({
  selector: 'app-user-register',
  standalone: true,
  host: { ngSkipHydration: '' },
  imports: [
    QrMediaImgComponent,
    MatInputModule,
    CountriesPhoneListComponent,
    QrIconButtonComponent,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    QrButtonComponent,
    FormsModule,
    MatCheckboxModule,
    MatFormFieldModule,
  ],
  templateUrl: './user-register.component.html',
  styleUrl: './user-register.component.scss',
})
export class UserRegisterPage implements OnInit {
  // Icon Check
  iconCheckSVG: QRMediaImage = {
    text: 'Icon Check',
    baseURL: '',
    docFolder: 'assets/icons',
    docName: 'check',
    docType: Constants.DOCTYPE_SVG,
    alt: 'Icon Check',
    width: '24px',
    height: '24px',
    loading: QRMediaImage.LOADING_LAZY,
    decoding: QRMediaImage.DECODING_ASYNC,
  };

  configButtonContinue = {
    style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
    height: Constants.BUTTON_HEIGHT_48PX,
    text: 'Registrarme',
    isDisabled: true,
  };

  prefixes = ['+54', '+598'];
  configButtonSeePassword = {
    height: Constants.BUTTON_HEIGHT_48PX,
  };

  showPassword = false;
  passwordError = false;
  loading = false;
  registerSuccess = false;

  errorServiceLogin = false;
  errorServiceLoginMessage = '';

  myFormGroup: UntypedFormGroup;
  email = new UntypedFormControl('', [Validators.required, Validators.email]);
  password = new UntypedFormControl('', [
    Validators.required,
    Validators.minLength(6),
    Validators.pattern(/^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]+$/),
  ]);

  firstName = new UntypedFormControl('', Validators.required);
  lastName = new UntypedFormControl('', Validators.required);
  phone = new UntypedFormControl('', []);
  prefixPhone = '';

  correctFields = false;

  checkboxTerms = false;

  constructor(
    fb: UntypedFormBuilder,
    private titleService: Title
  ) {
    this.myFormGroup = fb.group({
      email: this.email,
      password: this.password,
      lastName: this.lastName,
      firstName: this.firstName,
      phone: this.phone,
    });
  }

  ngOnInit(): void {
    this.titleService.setTitle('Registro de usuario | RE/MAX');
    this.myFormGroup.valueChanges.subscribe(() => {
      this.enableRegisterButton();
    });
  }

  onSeePassword(): void {
    this.showPassword = !this.showPassword;
  }

  getErrorMessage(field: string): string {
    let errorMsg = '';

    switch (field) {
      case 'email':
        if (this.email.hasError('required')) errorMsg = 'Ingresá un email';
        if (this.email.hasError('email'))
          errorMsg = 'Ingresá un email con formato válido';
        break;

      case 'password':
        if (this.password.hasError('required'))
          errorMsg = 'Ingresá una contraseña';
        if (
          this.password.hasError('pattern') ||
          this.password.hasError('minlength')
        ) {
          errorMsg = 'Usá un mínimo de 6 caracteres con números y letras';
          this.passwordError = true;
        }
        break;

      case 'firstName':
        if (this.firstName.hasError('required')) errorMsg = 'Ingresá tu nombre';
        break;

      case 'lastName':
        if (this.lastName.hasError('required'))
          errorMsg = 'Ingresá tu apellido';
        break;

      default:
        errorMsg = 'Error';
        break;
    }

    return errorMsg;
  }

  enableRegisterButton(): void {
    this.correctFields =
      !this.email.hasError('required') &&
      !this.email.hasError('email') &&
      !this.password.hasError('required') &&
      !this.password.hasError('pattern') &&
      !this.password.hasError('minlength') &&
      !this.firstName.hasError('required') &&
      !this.lastName.hasError('required');

    const enableBtn = this.correctFields && this.checkboxTerms;

    this.configButtonContinue = {
      ...this.configButtonContinue,
      isDisabled: !enableBtn, //true: desactivado(gris), false: habilitado
    };
  }

  changePrefixMobile($event: string): any {
    this.prefixPhone = $event
      .replace('(', '')
      .replace(')', '')
      .replace('+', '');
  }

  onSubmit(): void {
    this.loading = true;

    //Si todos los campos son correctos
    if (
      !this.email.invalid &&
      !this.password.invalid &&
      !this.firstName.invalid &&
      !this.lastName.invalid
    ) {
      //arma data para AWS Cognito
      const userData = {
        username: this.myFormGroup.get('email')?.value,
        password: this.myFormGroup.get('password')?.value,
        attributes: {
          email: this.myFormGroup.get('email')?.value,
          given_name: this.myFormGroup.get('firstName')?.value,
          family_name: this.myFormGroup.get('lastName')?.value,
          'custom:mobile':
            '(+' +
            this.prefixPhone +
            ')' +
            this.myFormGroup.get('phone')?.value,
        },
      };

      //Servicio AWS Cognito
      Auth.signUp(userData)
        .then(() => {
          this.registerSuccess = true;
          this.loading = false;
        })
        .catch(error => {
          const typeError = error.message || JSON.stringify(error);
          this.loading = false;
          this.errorServiceLogin = true;

          switch (typeError) {
            case 'An account with the given email already exists.':
              this.errorServiceLoginMessage =
                'El mail ingresado ya está registrado';
              break;
            default:
              this.errorServiceLoginMessage = 'Ha ocurrido un error';
              break;
          }
        });
    } else {
      this.loading = false;
      this.errorServiceLogin = true;
      this.errorServiceLoginMessage = 'Complete los campos correctamente';
    }
  }
}
