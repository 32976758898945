<!-- Navbar -->
<qr-list-listing-sub-navbar
  [isEntrepreneurship]="true"
  (onChangeView)="changeView($event)">
</qr-list-listing-sub-navbar>

<!-- Skeleton loader -->
@if (isResultsLoading || isMapResultsLoading) {
<listing-list-loader [viewMode]="searchDomain.viewMode"></listing-list-loader>
} @else { @if (searchDomain.viewMode === LISTINGS_VIEW_MODE_LIST ||
searchDomain.viewMode === LISTINGS_VIEW_MODE_GRID) {
<header-results [isEntrepreneurshipsSection]="true"></header-results>
}
<!------------------------->
<!-- Modo de vista: Listado y Grilla -->
<!------------------------->
@if (searchDomain.listingAndEntrepreneurship.length > 0) {
<div>
  @if (searchDomain.viewMode !== LISTINGS_VIEW_MODE_MAP) {
  <div
    class="container"
    [ngClass]="searchDomain.viewMode === LISTINGS_VIEW_MODE_LIST ? 'viewListTemplate': 'viewGridTemplate'">
    @for (prop of searchDomain.listingAndEntrepreneurship; track prop) {
    <qr-card-property
      [isImageSwiper]="true"
      [data]="prop"
      [isViewList]="searchDomain.viewMode === LISTINGS_VIEW_MODE_LIST"
      [isViewGrid]="searchDomain.viewMode === LISTINGS_VIEW_MODE_GRID"
      (cardChange)="cardPropertyChange($event)"></qr-card-property>
    }
  </div>
  <div id="pagination">
    <qr-pagination
      [actualPage]="searchDomain.page"
      [itemsArray]="searchDomain.totalItems"
      [pageSize]="searchDomain.pageSize"
      (onPagination)="pagination($event)">
    </qr-pagination>
  </div>
  } @if (searchDomain.viewMode === LISTINGS_VIEW_MODE_MAP) {
  <!------------------------->
  <!-- Modo de vista: Mapa -->
  <!------------------------->
  @if (isBrowser) {
  <div id="viewMapTemplate">
    @if (isDesktop) {

    <div id="card-container" [class.hidden]="isSidebarHidden">
      @for (prop of searchDomain.listingAndEntrepreneurship; track prop) {
      <qr-card-property
        id="card"
        [isImageSwiper]="true"
        [data]="prop"
        [isViewList]="searchDomain.viewMode === LISTINGS_VIEW_MODE_LIST"
        [isViewGrid]="searchDomain.viewMode === LISTINGS_VIEW_MODE_GRID"
        (cardChange)="cardPropertyChange($event)"></qr-card-property>
      }
      <div id="pagination">
        <qr-pagination
          [actualPage]="searchDomain.page"
          [itemsArray]="searchDomain.totalItems"
          [pageSize]="searchDomain.pageSize"
          (onPagination)="pagination($event)">
        </qr-pagination>
      </div>
    </div>
    }
    <div id="map">
      <qr-button
        id="toggle-sidebar"
        (click)="toggleSidebar()"
        [config]="BUTTON_ARROW">
      </qr-button>
      <qr-map-google
        [propHoverID]="propHoverID"
        [coords]="this.coords"
        [center]="mapCenter"
        [zoom]="zoom"
        (onclickmarker)="onclickmarker($event)"
        (polygonCoordinatesChanged)="polygonCoordinatesChanged($event)"></qr-map-google>
    </div>
    @if (statusCardPropMapMode.isVisible && cardPropMapModeSelected) {
    <qr-card-property
      [data]="cardPropMapModeSelected"
      [isOverlayMode]="true"
      [status]="statusCardPropMapMode"
      [isImageSwiper]="false"
      [isViewList]="true"
      (cardChange)="cardPropertyChange($event)"></qr-card-property>
    }
  </div>
  } }
</div>
} @else {
<div
  class="no-results-container"
  fxLayoutAlign="center center"
  fxLayout="column">
  <div id="no-results-illustration"></div>
  <p class="no-results" id="first">
    No hay propiedades que coincidan con tu búsqueda.
  </p>
  <p class="no-results">
    Modifica o elimina tus filtros para encontrar más propíedades
  </p>
  <div id="clean-filter-button">
    <qr-button
      (click)="cleanFilters()"
      [config]="BUTTON_CLEAN_FILTERS"></qr-button>
  </div>
</div>
} }

<!-- Diálogo: Creá tu cuenta -->
<qr-dialog [data]="dialogCreateAccount"></qr-dialog>
