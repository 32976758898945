<!-- Detalle de propiedad: Galeria de fotos -->
@if (this.type === 'photo-gallery-property-detail') {
  <div id="photo-gallery-property-detail">
    <svg
      class="svg-container"
      id="photo"
      role="img"
      width="100%"
      height="440"
      aria-labelledby="loading-aria"
      viewBox="0 0 100 440"
      preserveAspectRatio="none">
      <rect
        x="0"
        y="0"
        width="100%"
        height="100%"
        clip-path="url(#clip-path)"
        style="fill: url('#fill')"></rect>
      <defs>
        <clipPath id="clip-path">
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="440" />
        </clipPath>
        <linearGradient id="fill">
          <stop offset="0.599964" stop-color="#f3f3f3" stop-opacity="1">
            <animate
              attributeName="offset"
              values="-2; -2; 1"
              keyTimes="0; 0.25; 1"
              dur="2s"
              repeatCount="indefinite"></animate>
          </stop>
          <stop offset="1.59996" stop-color="#ecebeb" stop-opacity="1">
            <animate
              attributeName="offset"
              values="-1; -1; 2"
              keyTimes="0; 0.25; 1"
              dur="2s"
              repeatCount="indefinite"></animate>
          </stop>
          <stop offset="2.59996" stop-color="#f3f3f3" stop-opacity="1">
            <animate
              attributeName="offset"
              values="0; 0; 3"
              keyTimes="0; 0.25; 1"
              dur="2s"
              repeatCount="indefinite"></animate>
          </stop>
        </linearGradient>
      </defs>
    </svg>
    <div id="thumbnails">
      <svg
        class="svg-container thumbnail"
        role="img"
        width="100%"
        height="159"
        aria-labelledby="loading-aria"
        viewBox="0 0 100 24"
        preserveAspectRatio="none">
        <rect
          x="0"
          y="0"
          width="100%"
          height="100%"
          clip-path="url(#clip-path)"
          style="fill: url('#fill')"></rect>
        <defs>
          <clipPath id="clip-path">
            <rect x="0" y="-1" rx="4" ry="4" width="200" height="159" />
          </clipPath>
          <linearGradient id="fill">
            <stop offset="0.599964" stop-color="#f3f3f3" stop-opacity="1">
              <animate
                attributeName="offset"
                values="-2; -2; 1"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"></animate>
            </stop>
            <stop offset="1.59996" stop-color="#ecebeb" stop-opacity="1">
              <animate
                attributeName="offset"
                values="-1; -1; 2"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"></animate>
            </stop>
            <stop offset="2.59996" stop-color="#f3f3f3" stop-opacity="1">
              <animate
                attributeName="offset"
                values="0; 0; 3"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"></animate>
            </stop>
          </linearGradient>
        </defs>
      </svg>
      <svg
        class="svg-container thumbnail"
        role="img"
        width="100%"
        height="159"
        aria-labelledby="loading-aria"
        viewBox="0 0 100 159"
        preserveAspectRatio="none">
        <rect
          x="0"
          y="0"
          width="100%"
          height="100%"
          clip-path="url(#clip-path)"
          style="fill: url('#fill')"></rect>
        <defs>
          <clipPath id="clip-path">
            <rect x="0" y="-1" rx="4" ry="4" width="200" height="159" />
          </clipPath>
          <linearGradient id="fill">
            <stop offset="0.599964" stop-color="#f3f3f3" stop-opacity="1">
              <animate
                attributeName="offset"
                values="-2; -2; 1"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"></animate>
            </stop>
            <stop offset="1.59996" stop-color="#ecebeb" stop-opacity="1">
              <animate
                attributeName="offset"
                values="-1; -1; 2"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"></animate>
            </stop>
            <stop offset="2.59996" stop-color="#f3f3f3" stop-opacity="1">
              <animate
                attributeName="offset"
                values="0; 0; 3"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"></animate>
            </stop>
          </linearGradient>
        </defs>
      </svg>
      <svg
        class="svg-container"
        role="img"
        width="100%"
        height="159"
        aria-labelledby="loading-aria"
        viewBox="0 0 100 159"
        preserveAspectRatio="none">
        <rect
          x="0"
          y="0"
          width="100%"
          height="100%"
          clip-path="url(#clip-path)"
          style="fill: url('#fill')"></rect>
        <defs>
          <clipPath id="clip-path">
            <rect x="0" y="-1" rx="4" ry="4" width="200" height="159" />
          </clipPath>
          <linearGradient id="fill">
            <stop offset="0.599964" stop-color="#f3f3f3" stop-opacity="1">
              <animate
                attributeName="offset"
                values="-2; -2; 1"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"></animate>
            </stop>
            <stop offset="1.59996" stop-color="#ecebeb" stop-opacity="1">
              <animate
                attributeName="offset"
                values="-1; -1; 2"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"></animate>
            </stop>
            <stop offset="2.59996" stop-color="#f3f3f3" stop-opacity="1">
              <animate
                attributeName="offset"
                values="0; 0; 3"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"></animate>
            </stop>
          </linearGradient>
        </defs>
      </svg>
    </div>
  </div>
}

<!-- Datos básicos -->
@if (this.type === 'basic-data') {
  <div id="basic-data">
    <svg
      class="svg-container"
      id="basic-data-title"
      role="img"
      width="70%"
      height="40"
      viewBox="0 0 70 40"
      preserveAspectRatio="none">
      <rect
        x="0"
        y="0"
        width="70%"
        height="40"
        clip-path="url(#clip-path-basic-data-title)"
        style="fill: url('#fill-basic-data-title')"></rect>
      <defs>
        <clipPath id="clip-path-basic-data-title">
          <rect x="0" y="0" rx="0" ry="0" width="70" height="40" />
        </clipPath>
        <linearGradient id="fill-basic-data-title">
          <stop offset="0.599964" stop-color="#f3f3f3" stop-opacity="1">
            <animate
              attributeName="offset"
              values="-2; -2; 1"
              keyTimes="0; 0.25; 1"
              dur="2s"
              repeatCount="indefinite"></animate>
          </stop>
          <stop offset="1.59996" stop-color="#ecebeb" stop-opacity="1">
            <animate
              attributeName="offset"
              values="-1; -1; 2"
              keyTimes="0; 0.25; 1"
              dur="2s"
              repeatCount="indefinite"></animate>
          </stop>
          <stop offset="2.59996" stop-color="#f3f3f3" stop-opacity="1">
            <animate
              attributeName="offset"
              values="0; 0; 3"
              keyTimes="0; 0.25; 1"
              dur="2s"
              repeatCount="indefinite"></animate>
          </stop>
        </linearGradient>
      </defs>
    </svg>
    <svg
      class="svg-container basic-data-text"
      id="basic-data-text"
      role="img"
      width="80%"
      height="16"
      viewBox="0 0 100 16"
      preserveAspectRatio="none">
      <rect
        x="0"
        y="0"
        width="100%"
        height="16"
        clip-path="url(#clip-path-basic-data-text)"
        style="fill: url('#fill-basic-data-text')"></rect>
      <defs>
        <clipPath id="clip-path-basic-data-text">
          <rect x="0" y="0" rx="0" ry="0" width="100" height="16" />
        </clipPath>
        <linearGradient id="fill-basic-data-text">
          <stop offset="0.599964" stop-color="#f3f3f3" stop-opacity="1">
            <animate
              attributeName="offset"
              values="-2; -2; 1"
              keyTimes="0; 0.25; 1"
              dur="2s"
              repeatCount="indefinite"></animate>
          </stop>
          <stop offset="1.59996" stop-color="#ecebeb" stop-opacity="1">
            <animate
              attributeName="offset"
              values="-1; -1; 2"
              keyTimes="0; 0.25; 1"
              dur="2s"
              repeatCount="indefinite"></animate>
          </stop>
          <stop offset="2.59996" stop-color="#f3f3f3" stop-opacity="1">
            <animate
              attributeName="offset"
              values="0; 0; 3"
              keyTimes="0; 0.25; 1"
              dur="2s"
              repeatCount="indefinite"></animate>
          </stop>
        </linearGradient>
      </defs>
    </svg>
    <svg
      class="svg-container"
      id="basic-data-text"
      role="img"
      width="60%"
      height="16"
      viewBox="0 0 100 16"
      preserveAspectRatio="none">
      <rect
        x="0"
        y="0"
        width="100%"
        height="16"
        clip-path="url(#clip-path-basic-data-text)"
        style="fill: url('#fill-basic-data-text')"></rect>
      <defs>
        <clipPath id="clip-path-basic-data-text">
          <rect x="0" y="0" rx="0" ry="0" width="100" height="16" />
        </clipPath>
        <linearGradient id="fill-basic-data-text">
          <stop offset="0.599964" stop-color="#f3f3f3" stop-opacity="1">
            <animate
              attributeName="offset"
              values="-2; -2; 1"
              keyTimes="0; 0.25; 1"
              dur="2s"
              repeatCount="indefinite"></animate>
          </stop>
          <stop offset="1.59996" stop-color="#ecebeb" stop-opacity="1">
            <animate
              attributeName="offset"
              values="-1; -1; 2"
              keyTimes="0; 0.25; 1"
              dur="2s"
              repeatCount="indefinite"></animate>
          </stop>
          <stop offset="2.59996" stop-color="#f3f3f3" stop-opacity="1">
            <animate
              attributeName="offset"
              values="0; 0; 3"
              keyTimes="0; 0.25; 1"
              dur="2s"
              repeatCount="indefinite"></animate>
          </stop>
        </linearGradient>
      </defs>
    </svg>
  </div>
}

<!-- Texto -->
@if (this.type === 'text') {
  <div>
    <svg
      class="svg-container"
      id="text"
      role="img"
      width="100%"
      height="32"
      viewBox="0 0 100 32"
      preserveAspectRatio="none">
      <rect
        x="0"
        y="0"
        width="100%"
        height="32"
        clip-path="url(#clip-path-text)"
        style="fill: url('#fill-text')"></rect>
      <defs>
        <clipPath id="clip-path-text">
          <rect x="0" y="0" rx="0" ry="0" width="100" height="32" />
        </clipPath>
        <linearGradient id="fill-text">
          <stop offset="0.599964" stop-color="#f3f3f3" stop-opacity="1">
            <animate
              attributeName="offset"
              values="-2; -2; 1"
              keyTimes="0; 0.25; 1"
              dur="2s"
              repeatCount="indefinite"></animate>
          </stop>
          <stop offset="1.59996" stop-color="#ecebeb" stop-opacity="1">
            <animate
              attributeName="offset"
              values="-1; -1; 2"
              keyTimes="0; 0.25; 1"
              dur="2s"
              repeatCount="indefinite"></animate>
          </stop>
          <stop offset="2.59996" stop-color="#f3f3f3" stop-opacity="1">
            <animate
              attributeName="offset"
              values="0; 0; 3"
              keyTimes="0; 0.25; 1"
              dur="2s"
              repeatCount="indefinite"></animate>
          </stop>
        </linearGradient>
      </defs>
    </svg>
  </div>
}

<!-- Texto pequeño -->
@if (this.type === 'text-small') {
  <div>
    <svg
      class="svg-container"
      id="text-small"
      role="img"
      width="100%"
      height="16"
      viewBox="0 0 100 16"
      preserveAspectRatio="none">
      <rect
        x="0"
        y="0"
        width="100%"
        height="16"
        clip-path="url(#clip-path-text-small)"
        style="fill: url('#fill-text-small')"></rect>
      <defs>
        <clipPath id="clip-path-text-small">
          <rect x="0" y="0" rx="0" ry="0" width="100" height="16" />
        </clipPath>
        <linearGradient id="fill-text-small">
          <stop offset="0.599964" stop-color="#f3f3f3" stop-opacity="1">
            <animate
              attributeName="offset"
              values="-2; -2; 1"
              keyTimes="0; 0.25; 1"
              dur="2s"
              repeatCount="indefinite"></animate>
          </stop>
          <stop offset="1.59996" stop-color="#ecebeb" stop-opacity="1">
            <animate
              attributeName="offset"
              values="-1; -1; 2"
              keyTimes="0; 0.25; 1"
              dur="2s"
              repeatCount="indefinite"></animate>
          </stop>
          <stop offset="2.59996" stop-color="#f3f3f3" stop-opacity="1">
            <animate
              attributeName="offset"
              values="0; 0; 3"
              keyTimes="0; 0.25; 1"
              dur="2s"
              repeatCount="indefinite"></animate>
          </stop>
        </linearGradient>
      </defs>
    </svg>
  </div>
}

<!-- Información de perfil -->
@if (this.type === 'profile-info') {
  <div id="profile-info">
    <!-- Foto de perfil -->
    <div id="profile-info-photo-container">
      <svg
        role="img"
        width="85px"
        height="85"
        viewBox="0 0 85 85"
        preserveAspectRatio="none">
        <rect
          x="0"
          y="0"
          width="85px"
          height="100%"
          clip-path="url(#clip-path-profile-info-photo)"
          style="fill: url('#fill-profile-info-photo')"></rect>
        <defs>
          <clipPath id="clip-path-profile-info-photo">
            <circle cx="40" cy="40" r="40" />
          </clipPath>
          <linearGradient id="fill-profile-info-photo">
            <stop offset="0.599964" stop-color="#f3f3f3" stop-opacity="1">
              <animate
                attributeName="offset"
                values="-2; -2; 1"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"></animate>
            </stop>
            <stop offset="1.59996" stop-color="#ecebeb" stop-opacity="1">
              <animate
                attributeName="offset"
                values="-1; -1; 2"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"></animate>
            </stop>
            <stop offset="2.59996" stop-color="#f3f3f3" stop-opacity="1">
              <animate
                attributeName="offset"
                values="0; 0; 3"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"></animate>
            </stop>
          </linearGradient>
        </defs>
      </svg>
    </div>
    <div id="profile-info-text-container">
      <!-- Texto -->
      <div class="profile-info-text margin-bottom">
        <svg
          class="svg-container"
          id="text"
          role="img"
          width="60%"
          height="16"
          viewBox="0 0 100 16"
          preserveAspectRatio="none">
          <rect
            x="0"
            y="0"
            width="100%"
            height="16"
            clip-path="url(#clip-path-profile-info-text)"
            style="fill: url('#fill-profile-info-text')"></rect>
          <defs>
            <clipPath id="clip-path-profile-info-text">
              <rect x="0" y="0" rx="0" ry="0" width="100" height="16" />
            </clipPath>
            <linearGradient id="fill-profile-info-text">
              <stop offset="0.599964" stop-color="#f3f3f3" stop-opacity="1">
                <animate
                  attributeName="offset"
                  values="-2; -2; 1"
                  keyTimes="0; 0.25; 1"
                  dur="2s"
                  repeatCount="indefinite"></animate>
              </stop>
              <stop offset="1.59996" stop-color="#ecebeb" stop-opacity="1">
                <animate
                  attributeName="offset"
                  values="-1; -1; 2"
                  keyTimes="0; 0.25; 1"
                  dur="2s"
                  repeatCount="indefinite"></animate>
              </stop>
              <stop offset="2.59996" stop-color="#f3f3f3" stop-opacity="1">
                <animate
                  attributeName="offset"
                  values="0; 0; 3"
                  keyTimes="0; 0.25; 1"
                  dur="2s"
                  repeatCount="indefinite"></animate>
              </stop>
            </linearGradient>
          </defs>
        </svg>
      </div>
      <!-- Texto -->
      <div class="profile-info-text margin-bottom">
        <svg
          class="svg-container"
          id="text"
          role="img"
          width="100%"
          height="16"
          viewBox="0 0 100 16"
          preserveAspectRatio="none">
          <rect
            x="0"
            y="0"
            width="100%"
            height="16"
            clip-path="url(#clip-path-profile-info-text)"
            style="fill: url('#fill-profile-info-text')"></rect>
          <defs>
            <clipPath id="clip-path-profile-info-text">
              <rect x="0" y="0" rx="0" ry="0" width="100" height="16" />
            </clipPath>
            <linearGradient id="fill-profile-info-text">
              <stop offset="0.599964" stop-color="#f3f3f3" stop-opacity="1">
                <animate
                  attributeName="offset"
                  values="-2; -2; 1"
                  keyTimes="0; 0.25; 1"
                  dur="2s"
                  repeatCount="indefinite"></animate>
              </stop>
              <stop offset="1.59996" stop-color="#ecebeb" stop-opacity="1">
                <animate
                  attributeName="offset"
                  values="-1; -1; 2"
                  keyTimes="0; 0.25; 1"
                  dur="2s"
                  repeatCount="indefinite"></animate>
              </stop>
              <stop offset="2.59996" stop-color="#f3f3f3" stop-opacity="1">
                <animate
                  attributeName="offset"
                  values="0; 0; 3"
                  keyTimes="0; 0.25; 1"
                  dur="2s"
                  repeatCount="indefinite"></animate>
              </stop>
            </linearGradient>
          </defs>
        </svg>
      </div>
      <!-- Texto -->
      <div class="profile-info-text">
        <svg
          class="svg-container"
          id="text"
          role="img"
          width="40%"
          height="16"
          viewBox="0 0 100 16"
          preserveAspectRatio="none">
          <rect
            x="0"
            y="0"
            width="100%"
            height="16"
            clip-path="url(#clip-path-profile-info-text)"
            style="fill: url('#fill-profile-info-text')"></rect>
          <defs>
            <clipPath id="clip-path-profile-info-text">
              <rect x="0" y="0" rx="0" ry="0" width="100" height="16" />
            </clipPath>
            <linearGradient id="fill-profile-info-text">
              <stop offset="0.599964" stop-color="#f3f3f3" stop-opacity="1">
                <animate
                  attributeName="offset"
                  values="-2; -2; 1"
                  keyTimes="0; 0.25; 1"
                  dur="2s"
                  repeatCount="indefinite"></animate>
              </stop>
              <stop offset="1.59996" stop-color="#ecebeb" stop-opacity="1">
                <animate
                  attributeName="offset"
                  values="-1; -1; 2"
                  keyTimes="0; 0.25; 1"
                  dur="2s"
                  repeatCount="indefinite"></animate>
              </stop>
              <stop offset="2.59996" stop-color="#f3f3f3" stop-opacity="1">
                <animate
                  attributeName="offset"
                  values="0; 0; 3"
                  keyTimes="0; 0.25; 1"
                  dur="2s"
                  repeatCount="indefinite"></animate>
              </stop>
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>
  </div>
}

<!-- Card: Propiedad -->
@if (this.type === 'card-prop') {
  <div>
    <div id="card-prop">
      <svg
        role="img"
        width="100%"
        height="200"
        viewBox="0 0 100 200"
        preserveAspectRatio="none">
        <rect
          x="0"
          y="0"
          width="100%"
          height="200"
          clip-path="url(#clip-path-card-prop)"
          style="fill: url('#fill-card-prop')"></rect>
        <defs>
          <clipPath id="clip-path-card-prop">
            <rect x="0" y="0" rx="0" ry="0" width="360" height="200" />
          </clipPath>
          <linearGradient id="fill-card-prop">
            <stop offset="0.599964" stop-color="#f3f3f3" stop-opacity="1">
              <animate
                attributeName="offset"
                values="-2; -2; 1"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"></animate>
            </stop>
            <stop offset="1.59996" stop-color="#ecebeb" stop-opacity="1">
              <animate
                attributeName="offset"
                values="-1; -1; 2"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"></animate>
            </stop>
            <stop offset="2.59996" stop-color="#f3f3f3" stop-opacity="1">
              <animate
                attributeName="offset"
                values="0; 0; 3"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"></animate>
            </stop>
          </linearGradient>
        </defs>
      </svg>
      <svg
        id="card-prop-info"
        class="svg-container"
        role="img"
        width="100%"
        height="240"
        viewBox="0 0 100 240"
        preserveAspectRatio="none">
        <rect
          x="0"
          y="0"
          width="100%"
          height="240"
          clip-path="url(#clip-path-card-prop-2)"
          style="fill: url('#fill-card-prop-2')"></rect>
        <defs>
          <clipPath id="clip-path-card-prop-2">
            <rect
              id="card-prop-title"
              class="card-prop-info"
              x="0"
              y="24"
              rx="0"
              ry="0"
              width="312"
              height="40" />
            <rect
              id="card-prop-price"
              class="card-prop-info"
              x="0"
              y="80"
              rx="0"
              ry="0"
              width="168"
              height="24" />
            <rect
              id="card-prop-desc"
              class="card-prop-info"
              x="0"
              y="168"
              rx="0"
              ry="0"
              width="208"
              height="40" />
          </clipPath>
          <linearGradient id="fill-card-prop-2">
            <stop offset="0.599964" stop-color="#f3f3f3" stop-opacity="1">
              <animate
                attributeName="offset"
                values="-2; -2; 1"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"></animate>
            </stop>
            <stop offset="1.59996" stop-color="#ecebeb" stop-opacity="1">
              <animate
                attributeName="offset"
                values="-1; -1; 2"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"></animate>
            </stop>
            <stop offset="2.59996" stop-color="#f3f3f3" stop-opacity="1">
              <animate
                attributeName="offset"
                values="0; 0; 3"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"></animate>
            </stop>
          </linearGradient>
        </defs>
      </svg>
    </div>
  </div>
}
