@if (data) {
  <div class="card" itemscope itemtype="https://schema.org/RealEstateAgent">
    @if (agentResizedImage) {
      <picture-source
        class="card__photo"
        [config]="PHOTO_PICTURE_SOURCE"
        [src]="agentResizedImage"
        [alt]="'Foto'"></picture-source>
    }
    <div class="card__person">
      <h1 class="card__name" itemprop="name">{{ data?.name }}</h1>
      @if (
        (!data?.license ||
          data?.title === USER_AGENT_TITLE_AGENT_LICENSED_BROKER) &&
        !data?.auctioneerOffice
      ) {
        <h2 class="card__office" itemprop="worksFor">
          {{ data?.office?.name }}
        </h2>
      }
      @if (data?.auctioneerOffice || data?.auctioneerAssociate) {
        <h2 class="card__title">
          {{ NODE !== NODE_ECUADOR ? 'Corredor Público Inmo.' : 'Broker' }}
        </h2>
      }
      @if (data?.license) {
        <p class="card__license">
          {{ data?.license }}
        </p>
      }
    </div>
    @if (
      !data?.license || data?.title === USER_AGENT_TITLE_AGENT_LICENSED_BROKER
    ) {
      <div class="card__contact-methods">
        @for (phone of data?.phones; track phone) {
          <!-- Celular -->
          @if (phone?.type === PHONE_TYPE_WORK) {
            <div class="card__contact-method">
              <qr-icon [config]="ICON_PHONE"></qr-icon>
              <a itemprop="telephone" href="tel:{{ phone?.value }}">{{
                phone?.value
              }}</a>
            </div>
          }
          <!-- WhatsApp -->
          @if (phone?.type === PHONE_TYPE_MOBILE) {
            <div class="card__contact-method">
              <qr-icon [config]="ICON_WHATSAPP"></qr-icon>
              <a
                itemprop="telephone"
                href="https://wa.me/{{ phoneWhatsAppAPIFormat }}?text={{
                  messageContact
                }}"
                target="_blank"
                >{{ phoneWhatsAppFormat }}</a
              >
            </div>
          }
        }
        <!-- Email -->
        @for (email of data?.emails; track email) {
          <div class="card__contact-method">
            <qr-icon [config]="ICON_EMAIL"></qr-icon>
            <a
              itemprop="email"
              href="mailto:{{ email?.value }}?subject={{
                property?.title
              }}&body={{ messageContact }}"
              >{{ email?.value }}</a
            >
          </div>
        }
      </div>
    }
    @if (data?.office?.address) {
      <div class="card__address" itemprop="address">
        {{ data?.office?.address }}
      </div>
    }
  </div>
}
