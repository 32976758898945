import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { QRInputAutocomplete } from '@app/core/models/qr-input-autocomplete.model';
import { QRInputComponent } from '../qr-input/qr-input.component';

@Component({
  selector: 'qr-input-autocomplete',
  templateUrl: './qr-input-autocomplete.component.html',
  styleUrls: ['./qr-input-autocomplete.component.scss'],
  standalone: true,
  imports: [QRInputComponent],
})
export class QRInputAutocompleteComponent implements OnInit {
  @Input() config?: QRInputAutocomplete;
  @Input() isDisabled?: boolean = false;
  @Input() hasError?: boolean = false;
  // @Input() value?: string | number | undefined;
  @Output() selectedChange: EventEmitter<string | undefined> = new EventEmitter<
    string | undefined
  >();

  filteredOptions: string[] = [];
  isOptionsVisible: boolean = false;
  selectedOption: string | number | undefined;

  constructor(private _eref: ElementRef) {}

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  @HostListener('document:click', ['$event', '$event.target'])
  onDocumentClicked(event: MouseEvent, targetElement: HTMLElement) {
    if (
      targetElement &&
      document.body.contains(targetElement) &&
      !this._eref.nativeElement.contains(targetElement)
    ) {
      this.isOptionsVisible = false;
    }
  }

  ngOnInit(): void {
    this.isOptionsVisible = false;
    this.filteredOptions = this.config?.options ?? [];
  }

  inputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value: string | number | undefined = input.value;

    if (value != undefined) {
      const filtered = this.config?.options.filter(o =>
        o.toLowerCase().includes(value.toString().toLowerCase())
      );
      if (filtered) {
        this.filteredOptions = filtered;
      }
    } else {
      this.filteredOptions = this.config?.options ?? [];
      this.selectedChange.emit(undefined);
    }
  }

  showOptions(): void {
    if (this.isDisabled == false) {
      this.isOptionsVisible = true;
    }
  }

  select(option: string): void {
    this.isOptionsVisible = false;
    this.selectedOption = option;
    this.selectedChange.emit(this.selectedOption);
  }
}
