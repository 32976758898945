@if (options.isOpen) {
  <div class="overlay">
    <qr-icon-button
      class="overlay__button-close"
      icon="close"
      [config]="BUTTON_CLOSE"
      (click)="close()">
    </qr-icon-button>
    <div class="swiper">
      <swiper-container
        #photosSwiperFS
        appSwiper
        init="false"
        [config]="SWIPER_PHOTOS"
        (indexChange)="galleryIndexChange($event)">
        @for (photo of resizedImages; track photo) {
          <swiper-slide class="swiper__slide"
            ><picture class="swiper__picture">
              <source
                media="(min-width: 1280px)"
                [attr.srcset]="photo.webpL"
                type="image/webp" />
              <source
                media="(min-width: 1px)"
                [attr.srcset]="photo.webpS"
                type="image/webp" />
              <source
                media="(min-width: 1280px)"
                [attr.srcset]="photo.jpgL"
                type="image/jpeg" />
              <source
                media="(min-width: 1px)"
                [attr.srcset]="photo.jpgS"
                type="image/jpeg" />
              <img
                alt=""
                [attr.src]="photo.jpgL"
                (error)="errorImg($event)"
                class="swiper-lazy" />
            </picture>
            <div class="swiper-lazy-preloader"></div>
          </swiper-slide>
        }
      </swiper-container>
    </div>

    <div class="thumbnails">
      <swiper-container
        #thumbnailSwiperFS
        appSwiper
        init="false"
        [config]="SWIPER_THUMBNAILS"
        (indexChange)="thumbnailsIndexChange($event)">
        @for (photo of resizedImages; track photo; let i = $index) {
          <swiper-slide>
            <picture class="thumbnails__picture">
              <source
                [attr.data-srcset]="photo.webpS"
                type="image/webp"
                class="swiper-lazy" />
              <source
                [attr.data-srcset]="photo.jpgS"
                type="image/jpeg"
                class="swiper-lazy" />
              <img
                [id]="'thumbnail-' + i"
                (click)="slideTo(i)"
                alt=""
                [attr.src]="photo.jpgS"
                (error)="errorImg($event)"
                class="swiper-lazy" />
            </picture>
            <div class="swiper-lazy-preloader"></div>
          </swiper-slide>
        }
      </swiper-container>
    </div>

    <div class="overlay__pagination">
      <p>{{ this.options.indexSelected + 1 }}/{{ this.photos?.length }}</p>
    </div>
    <div class="overlay__button-nav overlay__button-nav--prev">
      <div class="overlay__button-prev" matRipple></div>
    </div>
    <div class="overlay__button-nav overlay__button-nav--next">
      <div class="overlay__button-next" matRipple></div>
    </div>
    <div class="overlay__shadow" aria-hidden="true" (click)="close()"></div>
  </div>
}
