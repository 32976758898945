<mat-sidenav-container id="layout-wrapper" (backdropClick)="close()">
  <!-- Menú: Mobile -->
  <mat-sidenav #drawer [mode]="'over'">
    <!-- Botón: Cerrar -->
    <qr-icon-button
      (click)="closeSideNav()"
      id="menu-mobile-button-close"
      icon="close"
      [config]="this.configMenuButtonClose">
    </qr-icon-button>
    <!-- Listado de secciones -->
    <mat-nav-list>
      @for (item of menuItems; track item) {
        <div
          aria-hidden="true"
          (click)="closeSideNav()"
          class="mobile-nav-item"
          matRipple>
          <!-- Enlaces externos  -->
          @if (item.link?.includes('http')) {
            <a [href]="item.link" target="_blank" role="button">
              {{ item.label | translate: locale.language }}
            </a>
          } @else {
            <!-- Enlaces internos -->
            @if (item.activeIsContain !== '/') {
              <a
                [href]="item.activeIsContain"
                routerLinkActive="selected"
                [href]="item.link"
                role="button">
                {{ item.label | translate: locale.language }}
              </a>
            } @else {
              <a
                [ngClass]="{
                  selected: isHomeSelected(),
                }"
                [href]="item.link"
                role="button">
                {{ item.label | translate: locale.language }}
              </a>
            }
            <!-- Enlance: Home -->
          }
        </div>
      }
    </mat-nav-list>
  </mat-sidenav>

  <!-- Cuenta -->
  <mat-sidenav #sideNavAccount [mode]="'over'" position="end">
    <!-- Botón: Cerrar -->
    <div class="user-acount-content">
      <qr-icon-button
        (click)="close()"
        id="menu-mobile-button-close"
        icon="close"
        [config]="this.configMenuButtonClose">
      </qr-icon-button>
      @if (!this.userLoged) {
        <div class="login" aria-hidden="true" (click)="closeSideNavAccount()">
          <p>Creá tu cuenta y accedé a tus favoritos</p>
          <qr-button [config]="this.configButtonLogin"></qr-button>
        </div>
      }
      @if (this.userLoged) {
        <div class="user-logged-in">
          <h3 class="user-name">
            {{ this.userData.name }} {{ this.userData.lastname }}
          </h3>
          @for (item of itemsMenuMobile; track item; let i = $index) {
            @if (i !== itemsMenuMobile.length - 1) {
              <div class="mobile-nav-item">
                <a
                  [href]="item.route"
                  routerLinkActive="selected"
                  (click)="close()">
                  @if (item.icon) {
                    <img src="/assets/icons/{{ item.icon }}.svg" alt="" />
                  }
                  {{ item.name }}</a
                >
              </div>
            } @else {
              <div class="line"></div>
              <div class="mobile-nav-item">
                <a (click)="onLogout()" [href]="'/'"
                  ><img src="/assets/icons/logout.svg" alt="" /> Cerrar
                  sesión</a
                >
              </div>
            }
          }
        </div>
      }
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <app-navbar
      (onOpenSideNav)="openSideNav()"
      (onOpenSideNavAccount)="openSideNavAccount()"></app-navbar>
    <div class="main-content">
      <div class="page-content">
        <router-outlet></router-outlet>
      </div>
    </div>
    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
