<!-- Campo: ¿Dónde querés mudarte? -->
<div class="filter-button">
  <locations-searcher
    class="locations"
    [selected]="searchDomain.filterGeoList$"
    [isHomeSearcher]="true"
    (locationsSearcherChange)="
      locationsSearcherChange($event)
    "></locations-searcher>
</div>

<!-- Lista desplegable: Tipo de propiedad -->
@if (friendlyListingTypes.length > 0) {
  <div>
    <div id="property-type">
      <mat-form-field class="mat-form-field-property-type" appearance="outline">
        <mat-select
          (selectionChange)="listingTypesSelected($event)"
          placeholder="{{ 'list.propertyType' | translate: locale.language }} "
          [formControl]="propertyTypesFormControl"
          [multiple]="true">
          @for (item of friendlyListingTypes; track item) {
            <mat-option [value]="item.id">
              {{ 'property-type.' + item.value | translate: locale.language }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  </div>
} @else {
  <mat-spinner diameter="24"></mat-spinner>
}
<!-- Botón: Buscar-->
<button
  id="button-search"
  [class]="style"
  (click)="search()"
  matRipple
  aria-label="Buscador de propiedades">
  <qr-icon [config]="ICON_SEARCH"></qr-icon>
</button>
