import { Office } from './office.model';
import { AssociateEmail } from './associate-email.model';
import { AssociatePhone } from './associate-phone.model';

export class Associate {
  id: string;
  office: Office;
  officeId: string;
  officeName: string;
  name: string;
  slug: string;
  title: string;
  biography: string;
  role: string;
  photo: string;
  rawPhoto: string;
  phones?: AssociatePhone[];
  emails?: AssociateEmail[];
  internalId: string;
  auctioneerOffice: boolean;
  auctioneerAssociate: boolean;
  license: string;
  certificates: any[];
  languages?: string[];

  constructor() {
    this.id = '';
    this.officeId = '';
    this.office = new Office();
    this.name = '';
    this.officeName = '';
    this.slug = '';
    this.role = '';
    this.title = '';
    this.biography = '';
    this.photo = '';
    this.rawPhoto = '';
    this.phones = [];
    this.emails = [];
    this.internalId = '';
    this.auctioneerOffice = false;
    this.auctioneerAssociate = false;
    this.license = '';
    this.certificates = [];
  }
}
