import {
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { GeoSearch } from './../../../components/geo-search/geo-search.model';
import { environment } from '@base/environments/environment';
import { Constants } from '../../../core/constants/constants';
import { isPlatformBrowser } from '@angular/common';
import { L10nLocale, L10N_LOCALE, L10nTranslationModule } from 'angular-l10n';
import { ParamsDomain } from '../../../domain/params.domain';
import { SearchDomain } from '../../../domain/search.domain';
import { UtilsService } from '@app/services/utils.service';
import { QrDisclaimerComponent } from '@app/components/qr-disclaimer/qr-disclaimer.component';
import { QrSearchbarComponent } from '@app/components/qr-searchbar/qr-searchbar.component';
import { QrDialogComponent } from '@app/components/qr-dialog/qr-dialog.component';
import { ListingType } from '@base/src/app/core/models/listing-types.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
  standalone: true,
  imports: [
    QrDisclaimerComponent,
    QrSearchbarComponent,
    L10nTranslationModule,
    QrDialogComponent,
  ],
})
export class CollectionHomePage implements OnInit {
  title = environment.companyName;
  node = environment.node;
  readonly imageCloudfront: string = environment.imageCloudfront;
  readonly imageCountry: string = 'ar';
  readonly imageCloudfrontAndCountry: string =
    this.imageCloudfront + '/' + this.imageCountry;

  styleSearchBar = Constants.BUTTON_COLOR_COLLECTION_SOLID;
  geoSearch: GeoSearch | null = null;
  dialogPropertyType: any;
  propertyType: ListingType | null = null;

  public innerWidth: any;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.innerWidth = window.innerWidth;
    }
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    public paramDomain: ParamsDomain,
    public searchDomain: SearchDomain,
    private utils: UtilsService
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.innerWidth = window.innerWidth;
    }

    const title = 'RE/MAX Collection | Especialistas en propiedades premium';
    const description =
      'Encontrá inmuebles premium a través de equipos liderados por profesionales matriculados, especializados y comprometidos en superar las expectativas de servicio de sus clientes.';

    this.utils.setPageMetaTags(title, description);
  }

  search(): void {
    if (this.innerWidth < 960) {
      const data: any = {
        isDialogOpen: true,
        configButtonLeft: {
          style: Constants.BUTTON_COLOR_GREY_BASIC,
          height: Constants.BUTTON_HEIGHT_48PX,
          text: 'Cancelar',
        },
        configButtonRight: {
          style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
          height: Constants.BUTTON_HEIGHT_48PX,
          text: 'Buscar',
        },
        dinamicComponent: 'property-type-home',
      };
      this.dialogPropertyType = Object.assign({}, data);
    } else {
      this.applyFilterAndRedirect();
    }
  }

  dialogPropertyTypeButtonLeft(): void {
    this.searchDomain.cancelSelectedFilter();
  }

  dialogPropertyTypeButtonRight(): void {
    this.applyFilterAndRedirect();
  }

  private applyFilterAndRedirect(): void {
    this.searchDomain.filterRemaxCollection = true;
    this.searchDomain.windowLocationHref();
  }
}
