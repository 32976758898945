import {
  Component,
  Inject,
  PLATFORM_ID,
  ViewChild,
  OnInit,
} from '@angular/core';
import { MenuItem } from '../../core/models/menu.model';
import { MENU } from '../menu';
import { Constants } from '../../core/constants/constants';
import { L10nLocale, L10N_LOCALE, L10nTranslationModule } from 'angular-l10n';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '@base/environments/environment';
import { SearchDomain } from '../../domain/search.domain';
import { FooterComponent } from '../public-footer/footer.component';
import { RouterModule } from '@angular/router';
import { CollectionNavbarComponent } from '../collection-navbar/navbar.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { QrIconButtonComponent } from '../../components/qr-icon-button/qr-icon-button.component';

@Component({
  selector: 'app-collection-layout',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss'],
  standalone: true,
  imports: [
    FooterComponent,
    RouterModule,
    CollectionNavbarComponent,
    MatSidenavModule,
    MatListModule,
    QrIconButtonComponent,
    L10nTranslationModule,
  ],
})
export class CollectionLayout implements OnInit {
  @ViewChild('drawer') drawer: any;
  node: string = environment.node;
  public menuItems: MenuItem[] = [];

  configMenuButtonClose = {
    style: Constants.BUTTON_COLOR_GREY_BORDER,
    height: Constants.BUTTON_HEIGHT_48PX,
  };

  pathSelected = '';

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    @Inject(PLATFORM_ID) private platformId: any,
    public searchDomain: SearchDomain
  ) {}

  ngOnInit(): void {
    this.collectionMenuFilter();
    if (isPlatformBrowser(this.platformId)) {
      this.pathSelected = window.location.href;
    }
  }

  public applyFilterAndRedirect(): void {
    this.searchDomain.filterRemaxCollection = true;
    this.searchDomain.windowLocationHref();
  }

  public closeSideNav(): void {
    if (this.drawer._mode == 'over') {
      this.drawer.close();
    }
  }

  public openSideNav(): void {
    this.drawer.open();
  }

  private collectionMenuFilter(): void {
    this.menuItems = MENU.filter(item => {
      const isNodeEcuador = this.node === Constants.NODE_ECUADOR;
      const isNodeUruguay = this.node === Constants.NODE_URUGUAY;
      const isMenuEntrepreneurship = item.label === 'home.emprendimientos';
      const isMenuBlog = item.label === 'home.blog';
      const isMenuRent = item.label === 'home.rent';
      const isHome = item.label === 'home.home';
      return (
        !(isNodeEcuador && (isMenuBlog || isMenuEntrepreneurship)) &&
        !(isNodeUruguay && isMenuEntrepreneurship) &&
        !isMenuRent &&
        !isHome
      );
    });
  }
}
