import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { environment } from '@base/environments/environment';
import { Constants } from '@base/src/app/core/constants/constants';
import { SearchDomain } from '@base/src/app/domain/search.domain';
import { ButtonSaveSearchComponent } from '../button-save-search/button-save-search.component';
import { ItemParam } from '@base/src/app/core/models/ItemParam';
import { ParamsDomain } from '@base/src/app/domain/params.domain';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { Subject, takeUntil } from 'rxjs';
import {
  L10N_LOCALE,
  L10nLocale,
  L10nTranslationModule,
  L10nTranslationService,
} from 'angular-l10n';
import { QRDropdown } from '@base/src/app/core/models/qr-dropdown.model';
import { QRDropdownComponent } from '../../../../../components/ui/qr-dropdown/qr-dropdown.component';

@Component({
  selector: 'header-results',
  standalone: true,
  imports: [
    ButtonSaveSearchComponent,
    CommonModule,
    MatSelectModule,
    L10nTranslationModule,
    QRDropdownComponent,
  ],
  templateUrl: './header-results.component.html',
  styleUrl: './header-results.component.scss',
})
export class HeaderResultsComponent implements OnInit, OnDestroy {
  @Input() isEntrepreneurshipsSection: boolean = false;

  public readonly NODE: string = environment.node;

  public readonly NODE_ECUADOR: string = Constants.NODE_ECUADOR;

  public orderBy$: Promise<ItemParam[]> | undefined;

  public dropdownOrderBy: QRDropdown = {
    size: QRDropdown.SIZE_48_PX,
    placeholder: 'list.orderBy',
    options: [],
  };

  public dropdownOrderBySelected: string | undefined;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    public searchDomain: SearchDomain,
    public paramsDomain: ParamsDomain,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private translationService: L10nTranslationService
  ) {
    this.loadOrderBy();
  }

  ngOnInit() {
    this.setOrderBySelected();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public dropdownOrderByChange(e: string | string[] | undefined): void {
    this.orderBy$?.then((itemParams: ItemParam[]) => {
      const ITEM_PARAM_SELECTED: ItemParam | undefined = itemParams.find(
        (item: ItemParam) => {
          return item.i18nValue == e;
        }
      );
      if (ITEM_PARAM_SELECTED) {
        this.updateOrderBySD([ITEM_PARAM_SELECTED.id]);
      }
    });
  }

  private updateOrderBySD(value: number[]): void {
    if (value != null) {
      this.searchDomain.filterOrderBy = [...value];
      if (this.searchDomain.isFilterChange()) {
        this.searchDomain.page = 0;
        if (!this.isEntrepreneurshipsSection) {
          this.searchDomain.windowLocationHref();
        } else {
          this.searchDomain.windowLocationHrefEmpre();
        }
      }
    }
  }

  private loadOrderBy(): void {
    this.orderBy$ = this.paramsDomain
      .getOrdersBy()
      .then((items: ItemParam[]) => {
        const UPDATED_ITEMS: ItemParam[] = items.map((item: ItemParam) => {
          item.i18nKey = 'list.' + item.value;
          item.i18nValue = this.translationService.translate(
            'list.' + item.value
          );
          this.dropdownOrderBy.options.push(item.i18nValue ?? '-');
          return item;
        });
        return UPDATED_ITEMS;
      });
  }

  private setOrderBySelected(): void {
    this.searchDomain.filterOrderBy$
      .pipe(takeUntil(this.destroy$))
      .subscribe((orderBySelected: number[]) => {
        if (orderBySelected.length > 0) {
          const ORDER_BY_SELECTED: number = orderBySelected[0]; // ID de opción seleccionada
          this.orderBy$?.then((itemParams: ItemParam[]) => {
            const ITEM_FOUND: ItemParam | undefined = itemParams.find(item => {
              return item.id == ORDER_BY_SELECTED; // Obtener ItemParam seleccionado a partir de su id
            });
            if (ITEM_FOUND) {
              this.dropdownOrderBySelected = ITEM_FOUND.i18nValue;
            }
          });
        }
      });
  }
}
