@if ((config && config?.canBeSelected) || config?.canBeHovered) {
  <div
    class="chip"
    [class.chip--can-be-hovered]="config?.canBeHovered"
    [class.chip--selected]="isSelected"
    (click)="select()"
    matRipple>
    {{ value }}
  </div>
} @else {
  <div class="chip">
    {{ value }}
  </div>
}
<ng-template #chip
  ><div class="chip">
    {{ value }}
  </div>
</ng-template>
