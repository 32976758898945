import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { TransferState } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from '../../../core/constants/constants';
import { AssociateService } from '../../../services/associate.service';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from '@base/environments/environment';
import { DOCUMENT } from '@angular/common';
import { SEOUtilsService } from '@app/services/seo-utils.service';
import { IQrResponse } from '@app/core/models/IQrResponse';
import { QrCardOfficeMemberComponent } from '@app/components/qr-card-office-member/qr-card-office-member.component';
import { QrButtonComponent } from '@base/src/app/components/qr-button/qr-button.component';
import { QrCardInfoAgentComponent } from '@base/src/app/components/qr-card-info-agent/qr-card-info-agent.component';
import { QrBiographyCardComponent } from '@base/src/app/components/qr-biography-card/qr-biography-card.component';
import { Office } from '@base/src/app/core/models/office.model';
import { Associate } from '@base/src/app/core/models/associate.model';

@Component({
  selector: 'app-broker-detail',
  templateUrl: './broker-detail.page.html',
  styleUrls: ['./broker-detail.page.scss'],
  standalone: true,
  imports: [
    QrCardOfficeMemberComponent,
    QrButtonComponent,
    QrCardInfoAgentComponent,
    QrBiographyCardComponent,
  ],
})
export class BrokerDetailPage implements OnInit {
  resourceId: string = '';
  loading: boolean = false;
  role: string = '';
  found: boolean = false;
  office: Office = new Office();
  coords: any[] = [];
  centerMap: any;
  staffs: Associate[] = [];
  teams: Associate[] = [];
  teamsShort: Associate[] = [];
  auctioneerAssociates: Associate[] = [];
  auctioneerOffices: Associate[] = [];
  auctioneer: Associate = new Associate();
  onSeeAll: boolean = false;
  onSeeAllMobile: boolean = false;
  configButtonAllFeatured = {
    style: Constants.BUTTON_COLOR_GREY_BORDER,
    height: Constants.BUTTON_HEIGHT_48PX,
    text: 'Ver todos',
  };

  configSwiperFeatured: any = {
    paginationClickable: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    watchSlidesProgress: true,
    watchSlidesVisibility: true,
    slidesPerView: 'auto',
    spaceBetween: 12,
    watchOverflow: true,
  };

  isBrowser = false;
  node = environment.node;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private route: ActivatedRoute,
    private state: TransferState,
    private associatesService: AssociateService,
    private titleService: Title,
    private metaTagService: Meta,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    public seoUtilsService: SEOUtilsService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.resourceId = this.route.snapshot.params['id'];
    this._fetchData(this.resourceId);
  }

  generateCoords(): void {
    this.coords = [
      {
        lng: this.office.location.coordinates[0],
        lat: this.office.location.coordinates[1],
        id: this.office.id,
      },
    ];

    this.centerMap = {
      lng: this.office.location.coordinates[0],
      lat: this.office.location.coordinates[1],
    };
  }

  onClickSeeAll(): void {
    this.teamsShort = this.teams;
  }

  setMetaTags(data: any): void {
    //No se está usando más, al parecer se definen en <qr-card-info-agent>
    const title: string =
      data.name + ' - CPI parte del equipo ' + data.office.name;
    //No se está usando más, al parecer se definen en <qr-card-info-agent>
    const description: string =
      'Conocé a ' +
      data.name +
      ', Corredor Público Inmobiliario integrante del equipo de ' +
      data.office.name +
      ' en ' +
      data.office.address +
      '.';

    this.titleService.setTitle(title);
    this.metaTagService.updateTag({
      name: 'description',
      content: description,
    });
    this.metaTagService.updateTag({
      property: 'og:image',
      content: data.photo,
    });

    this.metaTagService.updateTag({
      property: 'og:description',
      content: description,
    });
    this.metaTagService.updateTag({
      property: 'og:title',
      content: title,
    });
    this.metaTagService.updateTag({
      property: 'og:url',
      content: this.document.location.href,
    });

    this.metaTagService.updateTag({
      name: 'robots',
      content: 'index, follow',
    });

    this.seoUtilsService.setCanonicalTag();
  }

  private async _fetchData(resourceId: string): Promise<void> {
    try {
      const result: IQrResponse<Associate> =
        await this.associatesService.findBySlug(resourceId);
      if (result.code == 200) {
        this.auctioneer = result.data;
        this.auctioneer.title =
          this.node == 'ec' ? 'Broker' : this.auctioneer.title;
        this.getOfficeStaff(this.auctioneer.officeId);
        this.getOfficeTeam(this.auctioneer.officeId);
        this.getOfficeAuctioneerAssociate(this.auctioneer.officeId);
        this.getOfficeAuctioneerOffice(this.auctioneer.officeId);
        this.setMetaTags(result.data);
        this.seoUtilsService.setCanonicalTag();

        if (this.auctioneer.role != Constants.USER_ROLE_BROKER) {
          if (isPlatformServer(this.platformId)) {
            window.location.href =
              '/' + Constants.USER_ROLE_AGENT + '/' + this.auctioneer.slug;
          }
        }
      } else if (result.code == 500) {
        this.router.navigateByUrl('/');
      } else {
        console.warn(result);
      }
    } catch (error) {
      console.error(error);
    }
  }

  private async getOfficeStaff(id: string): Promise<void> {
    try {
      const result: IQrResponse<Associate[]> =
        await this.associatesService.findByOfficeIdStaff(id);

      if (result.code == 200) {
        this.staffs = result.data;
      } else {
        console.warn(result);
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  private async getOfficeTeam(id: string): Promise<void> {
    try {
      const result: IQrResponse<Associate[]> =
        await this.associatesService.findByOfficeIdTeam(id);

      if (result.code == 200) {
        this.teams = result.data;

        if (this.teams.length > 3) {
          this.teamsShort = [];
          for (let index = 0; index < 4; index++) {
            this.teamsShort.push(this.teams[index]);
          }
        } else {
          this.teamsShort = [];
        }
      } else {
        console.warn(result);
      }
    } catch (error) {
      console.error(error);
    }
  }

  private async getOfficeAuctioneerAssociate(id: string): Promise<void> {
    try {
      const result: IQrResponse<Associate[]> =
        await this.associatesService.findByOfficeIdAuctioneerAssociate(id);

      if (result.code == 200) {
        this.auctioneerAssociates = result.data;
      } else {
        console.warn(result);
      }
      this.loading = false;
    } catch (error) {
      console.error(error);
    }
  }

  private async getOfficeAuctioneerOffice(id: string): Promise<void> {
    try {
      const result: IQrResponse<Associate[]> =
        await this.associatesService.findByOfficeIdAuctioneerOffice(id);

      if (result.code == 200) {
        this.auctioneerOffices = result.data;
        for (let index = 0; index < this.auctioneerOffices.length; index++) {
          if (this.auctioneerOffices[index].id == this.auctioneer.id) {
            this.auctioneerOffices.splice(index, 1);
          }
        }
        this.found = true;
      } else {
        console.warn(result);
      }
      this.loading = false;
    } catch (error) {
      console.error(error);
    }
  }
}
