import { Component, OnInit } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Constants } from '@base/src/app/core/constants/constants';
import { Auth } from 'aws-amplify';
import { QrButtonComponent } from '../qr-button/qr-button.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CountriesPhoneListComponent } from '../countries-phone-list/countries-phone-list.component';

import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-user-account-data',
  templateUrl: './user-account-data.component.html',
  styleUrls: ['./user-account-data.component.scss'],
  standalone: true,
  imports: [
    MatProgressSpinnerModule,
    QrButtonComponent,
    MatFormFieldModule,
    MatInputModule,
    CountriesPhoneListComponent,
    ReactiveFormsModule,
  ],
})
export class UserAccountDataComponent implements OnInit {
  prefixes = [1, 1, 1];
  changedForm = false;
  configButtonContinue = {
    style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
    height: Constants.BUTTON_HEIGHT_48PX,
    text: 'Guardar cambios',
    isDisabled: true,
  };

  myFormGroup: UntypedFormGroup;
  email = new UntypedFormControl('', [Validators.required, Validators.email]);
  password = new UntypedFormControl('', [
    Validators.required,
    Validators.minLength(6),
    Validators.pattern(/^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]+$/),
  ]);

  firstName = new UntypedFormControl('', Validators.required);
  lastName = new UntypedFormControl('', Validators.required);
  phone = new UntypedFormControl('', []);

  prefixPhone = '';

  userData = {
    email: '',
    name: '',
    lastname: '',
    phone: '',
    prefixPhone: '',
  };

  loadingDataUser = false;
  successSave = false;

  constructor(fb: UntypedFormBuilder) {
    this.myFormGroup = fb.group({
      email: [{ value: this.email, disabled: true }],
      password: this.password,
      lastName: this.lastName,
      firstName: this.firstName,
      phone: this.phone,
    });
  }

  ngOnInit(): void {
    this.getUserInfo();

    //Suscribirse a los cambios en el formulario
    this.myFormGroup.valueChanges.subscribe(() => {
      this.onchangesDetected();
    });
  }

  getUserInfo(): void {
    this.loadingDataUser = true;
    Auth.currentUserInfo()
      .then(response => {
        if (response && response.attributes) {
          this.userData.name = response.attributes.given_name;
          this.userData.lastname = response.attributes.family_name;
          this.userData.email = response.attributes.email;

          if (response.attributes['custom:mobile']) {
            let prefixPhoneUser =
              response.attributes['custom:mobile'].match(/\(([^()]+)\)/g);

            if (prefixPhoneUser) {
              prefixPhoneUser = prefixPhoneUser[0]
                .replace('(', '')
                .replace(')', '')
                .replace('+', '');
            }

            this.prefixPhone = prefixPhoneUser;
            this.userData.prefixPhone = prefixPhoneUser;

            this.userData.phone = response.attributes['custom:mobile'].replace(
              response.attributes['custom:mobile'].match(/\(([^()]+)\)/g),
              ''
            );
          }

          this.myFormGroup.controls['email'].setValue(this.userData.email);
          this.myFormGroup.controls['firstName'].setValue(this.userData.name);
          this.myFormGroup.controls['lastName'].setValue(
            this.userData.lastname
          );
          this.myFormGroup.controls['phone'].setValue(this.userData.phone);
          this.loadingDataUser = false;
        }
      })
      .catch(error => {
        console.log('Error al conseguir info del user');
        console.log(error.message || JSON.stringify(error));
        this.loadingDataUser = false;
      });
  }

  //Busca cambios en el form, Si hubo algun cambio habilita el botón de guardar
  onchangesDetected(): void {
    const buttonDisabled =
      !(
        this.userData.name !== this.firstName.value ||
        this.userData.lastname !== this.lastName.value ||
        this.userData.phone !== this.phone.value ||
        this.userData.prefixPhone !== this.prefixPhone
      ) ||
      this.firstName.hasError('required') ||
      this.lastName.hasError('required');

    this.configButtonContinue = {
      ...this.configButtonContinue,
      isDisabled: buttonDisabled,
    };
  }

  //Select prefijo telefónico
  changePrefixMobile($event: string): any {
    this.prefixPhone = $event
      .replace('(', '')
      .replace(')', '')
      .replace('+', '');
    this.onchangesDetected();
  }

  //Actualiza atriburos del usuario
  async updateUser(): Promise<void> {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      given_name: this.firstName.value,
      family_name: this.lastName.value,
      'custom:mobile': '(+' + this.prefixPhone + ')' + this.phone.value,
    }).then(() => {
      this.successSave = true;

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    });
  }
}
