<div id="list-container">
  <form [formGroup]="myFormGroup">
    <mat-form-field appearance="outline">
      <mat-label>Código país</mat-label>
      <mat-select
        matTooltip="{{ this.tooltip }}"
        [multiple]="false"
        formControlName="prefix">
        @for (option of options; track option.phone_code) {
          <mat-option [value]="option.phone_code">
            <p>
              <img [src]="option.img" alt="" />
              <span>(+{{ option.phone_code }})</span> {{ option.country_en }}
            </p>
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </form>
</div>
