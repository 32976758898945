import {
  Component,
  Input,
  Output,
  EventEmitter,
  afterNextRender,
  OnChanges,
} from '@angular/core';
import { QRToggle } from '@app/core/models/qr-toggle.model';
import { QRIconComponent } from '../qr-icon/qr-icon.component';
import { QRIcon } from '@app/core/models/qr-icon.model';

@Component({
  selector: 'qr-toggle',
  standalone: true,
  imports: [QRIconComponent],
  templateUrl: './qr-toggle.component.html',
  styleUrl: './qr-toggle.component.scss',
})
export class QRToggleComponent implements OnChanges {
  @Input() config!: QRToggle;
  @Input() optionSelected!: number;
  @Output()
  optionSelectedChange: EventEmitter<number> = new EventEmitter<number>();

  RANDOM_NUMBER: number | null = null;

  constructor() {
    // @Inject(L10N_LOCALE) public locale: L10nLocale // @Inject(PLATFORM_ID) private platformId: Object,
    afterNextRender(() => {
      this.RANDOM_NUMBER = this.getSecureRandomNumber();
    });
  }

  ngOnChanges(): void {
    this.setIconsColors();
  }

  select(OPTION: number): void {
    this.optionSelected = OPTION;
    this.setIconsColors();
    this.optionSelectedChange.emit(this.optionSelected);
  }

  setIconsColors(): void {
    for (let i = 0; i < this.config.options.length; i++) {
      if (i == this.optionSelected) {
        let iconColor: string = QRIcon.COLOR_WHITE;
        if (this.config.hierarchy == QRToggle.HIERARCHY_PRIMARY_BORDER) {
          iconColor = QRIcon.COLOR_PRIMARY;
        }
        this.config.options[i].icon = {
          ...this.config.options[i].icon,
          color: iconColor,
        };
      } else {
        this.config.options[i].icon = {
          ...this.config.options[i].icon,
          color: QRIcon.COLOR_GREY_WOLF,
        };
      }
    }
  }

  getSecureRandomNumber(): number {
    const RANDOM_ARRAY: Uint32Array = new Uint32Array(1);
    window.crypto.getRandomValues(RANDOM_ARRAY);
    const SECURE_RANDOM_NUMBER: number = RANDOM_ARRAY[0] % 101;
    return SECURE_RANDOM_NUMBER;
  }
}
