import { Component, Inject, Input, OnInit } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';
import { L10nLocale, L10N_LOCALE, L10nTranslationModule } from 'angular-l10n';
import {
  ButtonWithIconConfig,
  QrButtonWithIconComponent,
} from '../qr-button-with-icon/qr-button-with-icon.component';

@Component({
  selector: 'qr-biography-card',
  templateUrl: './qr-biography-card.component.html',
  styleUrls: ['./qr-biography-card.component.scss'],
  standalone: true,
  imports: [QrButtonWithIconComponent, L10nTranslationModule, CommonModule],
})
export class QrBiographyCardComponent implements OnInit {
  @Input() data: string = '';
  @Input() title: string = '';
  @Input() hideButton = false;
  @Input() showExtraDescription = true;
  button: ButtonWithIconConfig = {
    text: 'Ver más',
    icon: 'plus',
    height: 32,
    style: 'button-color-grey-nero icon-color-primary',
    paddingHorizontal: 0,
    paddingVertical: 0,
  };

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        const element = document.getElementById('bio');
        if (element && element.clientHeight < 144) {
          this.hideButton = true;
        }
      }, 1);
    }
  }

  public toggleExtraDescription(): void {
    this.showExtraDescription = !this.showExtraDescription;
  }
}
