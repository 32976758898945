<div id="terms-main-container">
  @if(this.node === 'ar'){
  <div class="main-wrapper">
    <h1>Términos y condiciones de uso</h1>
    <p>
      Bienvenido al sitio web
      <a href="https://www.remax.com.ar/"> https://www.remax.com.ar/</a>
      (en adelante, el “Sitio”). Este documento describe los términos y
      condiciones de uso del Sitio (en adelante, “T&C”). Se deja constancia que
      cualquier persona que no acepte estos términos y condiciones, que son de
      carácter obligatorio y vinculante, deberá abstenerse de utilizar el Sitio.
    </p>

    <p class="title">1. Sobre RE/MAX Argentina y Uruguay S.R.L.</p>

    <p>
      Remax Argentina S.R.L. (“RE/MAX Argentina y Uruguay”) es una sociedad
      constituida conforme las leyes de la República Argentina dedicada a la
      prestación de servicios complementarios o conexos a la actividad
      inmobiliaria y al otorgamiento de una autorización del uso de la marca
      RE/MAX y sus logos distintivos en nuestro país en el marco de contratos de
      franquicia.
    </p>
    <p>
      Se deja aclarado y entendido que RE/MAX Argentina y Uruguay no ejerce el
      corretaje inmobiliario y, por ende, no intermedia entre las partes en una
      operación inmobiliaria. Cada propiedad que Ud. verá en Sitio es publicada
      por un corredor público inmobiliario matriculado a cargo de una oficina
      franquiciada por RE/MAX Argentina y Uruguay (en adelante, la/s
      “Oficina/s”).
    </p>
    <p>
      En este sentido, Usted acepta que el sitio web es simplemente una
      plataforma y que RE/MAX Argentina y Uruguay es ajena a cualquier relación
      que pudiere entablarse entre Usted y quienes publiquen avisos, anuncios
      y/o propuestas en el Sitio.
    </p>

    <p class="title">2. Las Oficinas</p>

    <p>
      2.1. Cada Oficina que realiza publicaciones en el Sitio es de propiedad y
      gestión independiente y se encuentra a cargo de un corredor público
      inmobiliario debidamente matriculado. Por consiguiente, RE/MAX Argentina y
      Uruguay no tiene injerencia ni participación alguna en la actividad de
      cada corredor público inmobiliario y, por ende, en ninguna de las
      publicaciones que estos realizan en el Sitio.
    </p>
    <p>
      2.2. Usted reconoce expresamente que RE/MAX Argentina y Uruguay: no
      realiza actividades de corretaje inmobiliario; no actúa en representación
      de las Oficinas; no actúa como mandatario de las Oficinas; no es
      propietario, poseedor, titular, depositario, administrador ni tiene ningún
      derecho sobre los inmuebles publicados en el Sitio; no comercializa por sí
      ni por cuenta ajena los inmuebles publicados en el Sitio; no realiza
      tasaciones; no cobra comisiones por las operaciones inmobiliarias que se
      realicen. Cada publicación que Ud. verá en el Sitio fue realizada por un
      corredor inmobiliario a cargo de una Oficina. Todas las operaciones
      inmobiliarias son objeto de intermediación y conclusión por parte de un
      corredor público inmobiliario colegiado, cuyos datos se exhiben en cada
      publicación.
    </p>
    <p class="title">3. Aceptación</p>
    <p>
      3.1. Toda persona que utilice el Sitio acepta todos y cada uno de los T&C,
      sus modificaciones y documentos complementarios. Se deja constancia que
      los T&C tienen carácter obligatorio y vinculante, por lo que es importante
      que los lea con detenimiento.
    </p>
    <p>
      3.2. El Sitio se reserva el derecho, a su entera discreción, de actualizar
      estos T&C en cualquier momento. Al utilizar este Sitio luego de dichas
      modificaciones, usted acepta estar sujeta a las mismas, así como también a
      sus modificaciones y/o actualizaciones. Por ello, es importante que el Ud.
      revise los T&C previo a navegar el presente sitio web.
    </p>
    <p class="title">4. El Sitio</p>
    <p>
      4.1 El Sitio es una plataforma de publicación de avisos de inmuebles que
      RE/MAX Argentina y Uruguay provee a las Oficinas franquiciadas, para que
      los corredores públicos inmobiliarios matriculados a cargo de cada Oficina
      puedan publicar las propiedades que se encuentran comercializando Por
      ende, RE/MAX Argentina y Uruguay no participa en ninguna de las
      publicaciones del Sitio y cualquier interacción que Ud. realice en el
      Sitio será directamente con la Oficina que efectuó la publicación y en
      ningún caso con RE/MAX Argentina y Uruguay.
    </p>

    <p>
      4.2 En Sitio, conectamos a las personas interesadas en adquirir o alquilar
      inmuebles con corredores inmobiliarios que cuentan con propiedades a su
      cargo.
    </p>
    <p>
      La persona interesada se pondrá en contacto para las negociaciones que
      consideren. Es decir que todas las negociaciones se realizan única y
      exclusivamente entre la Oficina a cargo del corredor matriculado
      correspondiente cuyos datos se exhiben en la publicación y la persona
      interesada.
    </p>

    <p class="title">5. Publicaciones</p>
    <p>
      5.1. Cada propiedad que Ud. verá publicada en Sitio es realizada
      directamente por un corredor público inmobiliario matriculado a cargo de
      una Oficina.
    </p>
    <p>
      5.2. La información provista en cada publicación fue provista
      exclusivamente por el corredor público inmobiliario matriculado (cuyos
      datos se exhiben en cada publicación). Por cualquier inquietud respecto a
      la eventual actualización de las medidas, descripciones arquitectónicas y
      funcionales, valores de expensas, servicios, impuestos, precios y demás
      información que se requiera a sus efectos -cuyos valores son aproximados-
      deberá consultar al corredor público inmobiliario matriculado responsable
      de la operación y publicación.
    </p>
    <p>
      5.3. El corredor inmobiliario cuyo nombre y datos de matriculación se
      exhiben en cada publicación es responsable del contenido de la publicación
      y de sus condiciones, tales como precio, descripción, fotos, calidad,
      garantía, entro otros.
    </p>
    <p class="title">6. Datos de Contacto</p>
    <p>
      6.1. Los datos de contacto que se exhiben en cada publicación pueden
      corresponder al corredor público inmobiliario matriculado y/o a las
      personas que colaboran con el mencionado en cada Oficina. Cualquier
      persona que se comunique con Ud. actúa bajo el liderazgo de un Corredor
      Público Inmobiliario matriculado responsable de cada Oficina.
    </p>
    <p>
      6.2. En todas las publicaciones del Sitio Usted podrá encontrar el nombre
      y datos de matriculación del corredor público inmobiliario matriculado a
      cargo de la correspondiente publicación.
    </p>

    <p class="title">7. Registro de Usuario</p>

    <p>
      7.1. El visitante del sitio podrá registrarse como Usuario, pero este no
      es un requisito para poder acceder y visualizar los anuncios publicados
      por los corredores inmobiliarios. El registro como Usuario les permitirá a
      los visitantes acceder a funcionalidades adicionales, tales como la
      posibilidad de guardar los avisos que resulten de su interés en un
      catálogo de favoritos al cual podrán acceder cada vez que se inicie sesión
      como Usuario y ubicarlos con mayor facilidad en tanto se encuentren
      disponibles en el Sitio.
    </p>

    <p>
      7.2. El visitante para registrarse como Usuario deberá: (i) completar el
      formulario de registración en todos sus campos con datos válidos, exactos,
      precisos, verdaderos y completos. Los datos que se requieren para el
      registro como Usuarios son los siguientes: (a) correo electrónico y (b)
      contraseña personal. Adicionalmente, quedara a disposición del registrante
      la posibilidad de incluir su número telefónico en el proceso de registro.
      (ii) a través de aplicaciones de terceros, tales como Facebook o Google.
      En este supuesto, el Usuario deberá aceptar los términos y condiciones y
      las políticas de privacidad de dicha aplicación.
    </p>

    <p>
      7.3. El Usuario dispondrá de una cuenta con su correo electrónico y
      contraseña que le permitirá el acceso personalizado, confidencial y seguro
      del sitio. El Usuario tendrá la posibilidad de cambiar la clave de acceso,
      para la cual deberá sujetarse al procedimiento establecido en el sitio. El
      Usuario comprende y acepta que dicha clave es de uso personal e
      intransferible y es exclusivamente responsable de mantenerla bajo
      confidencialidad y no entregarla ni divulgarla con terceros.
    </p>

    <p>
      7.4. RE/MAX Argentina y Uruguay podrá negarse a conceder al visitante el
      registro como Usuario cuando: (i) el correo electrónico utilizado
      pertenece a otro Usuario registrado previamente y/o (ii) un correo
      electrónico que resulte invalido o inexistente.
    </p>

    <p>
      7.5. El Usuario es completamente responsable de todas las interacciones en
      el sitio que se produzca en relación con su cuenta, y deberá notificar
      inmediatamente a RE/MAX Argentina y Uruguay a atencion&#64;remax.com.ar
      cualquier uso no autorizado de su cuenta o contraseña o de cualquier otra
      violación de la seguridad relacionada con su cuenta. RE/MAX no será
      responsable por el mal uso o uso negligente de la cuenta ni por cualquier
      perdida o daño causado por el incumplimiento de cualquier de las
      obligaciones anteriores.
    </p>

    <p>
      7.6. RE/MAX Argentina y Uruguay no se hacen responsables por la certeza o
      falsedad de los datos suministrados por los Usuarios. El Usuario es quien
      garantiza y responde por la veracidad, exactitud, vigencia y autenticidad
      de los datos suministrados.
    </p>

    <p>
      7.7. El registro como Usuario no garantiza, en ninguna circunstancia, que
      su solicitud de información o interés sea tratada de forma preferente por
      ninguno de los corredores inmobiliarios. Los corredores inmobiliarios son
      los únicos encargados y responsables de gestionar cada uno de los avisos
      que publican.
    </p>

    <p>
      7.8. El Usuario reconoce que RE/MAX Argentina y Uruguay podrá eliminar o
      suspender su cuenta como Usuario en caso de incumplir con alguna de las
      disposiciones y obligaciones establecidas en los presentes Términos y
      Condiciones.
    </p>

    <p>
      7.9. El Usuario podrá eliminar su cuenta a través de la opción que se
      habilite a tal fin en su perfil de usuario. El Usuario comprenden que, al
      solicitar la eliminación de su cuenta, también esta solicitando la
      eliminación de todos los datos asociados a ella.
    </p>

    <p class="title">8. Agentes adheridos a una Oficina RE/MAX</p>
    <p>
      8.1. Un Agente adherido a una Oficina NO es un corredor público
      inmobiliario y, por ende, no concluye operaciones inmobiliarias siendo
      ello una facultad indelegable de los corredores públicos inmobiliarios
      debidamente matriculados a cargo de cada Oficina. En efecto, los Agentes
      no realizan acto alguno que corresponda de manera exclusiva e indelegable
      al Corredor Público Inmobiliario responsable de la Oficina.
    </p>
    <p>
      8.2. Las tareas de los Agentes adheridos a cada Oficina se limitan a
      Auxiliar al Corredor Inmobiliario de cada Oficina y actúan bajo su
      liderazgo y supervisión.
    </p>
    <p>
      8.3. Los Agentes adheridos a una Oficina se vinculan únicamente con las
      distintas Oficinas franquiciadas, no existiendo ningún vínculo de ellos
      con RE/MAX Argentina y Uruguay.
    </p>
    <p class="title">9. Modificaciones de los T&C.</p>
    <p>
      RE/MAX Argentina y Uruguay podrá modificar, a su entera discreción, estos
      T&C en cualquier momento. El uso del Sitio y/o sus servicios implica la
      aceptación de estos Términos y Condiciones.
    </p>
    <p class="title">10. Datos Personales.</p>
    <p>
      10.1. RE/MAX Argentina y Uruguay mantendrá bajo estricta confidencialidad
      los Datos Personales suministrados en el Sitio, salvo en los casos en los
      que la Ley de Protección de Datos Personales permita su divulgación y con
      la salvedad descripta en el siguiente párrafo. En igual forma, RE/MAX
      Argentina y Uruguay exige a sus proveedores los mismos estándares de
      privacidad.
    </p>
    <p>
      10.2. Para conocer el detalle de los términos y condiciones sobre las
      Políticas de Privacidad que se aplican al Sitio lo invitamos a revisar el
      siguiente link:
      <a href="https://www.remax.com.ar/politica-de-privacidad">
        Política de Privacidad</a
      >
    </p>
    <p class="title">11. Indemnidad.</p>
    <p>
      11.1. La aceptación de los T&C implica en forma expresa e irrevocable
      reconocer que RE/MAX Argentina y Uruguay no ejerce el corretaje
      inmobiliario y que el Sitio es una plataforma utilizada por los corredores
      públicos inmobiliarios de cada Oficina. En consecuencia, Ud. se obliga a
      mantener indemne a RE/MAX Argentina y Uruguay y/o a sus sociedades
      vinculadas, funcionarios, directivos, administradores, representantes y
      empleados, respecto a cualquier reclamo relacionado a una publicación
      realizada en el Sitio por un corredor inmobiliario de una Oficina.
    </p>
    <p>
      11.2. Usted acepta mantener indemne a RE/MAX Argentina y Uruguay de y
      contra cualquier cargo, acción o demanda, incluyendo, pero no limitándose
      a, los gastos legales razonables, que resulten del uso que Usted haga del
      Sitio. Usted acepta y reconoce que el Sitio es una mera plataforma, ajena
      a cualquier relación que pudiera entablarse entre Usted y quienes
      publiquen avisos, anuncios y/o propuestas a través del Sitio. En tal
      sentido, Usted acepta no reclamar a RE/MAX Argentina y Uruguay por ningún
      tipo de consecuencia derivada de tales relaciones.
    </p>
    <p class="title">12. Información correspondiente a las propiedades.</p>
    <p>
      12.1. RE/MAX Argentina y Uruguay no es titular, propietario, poseedor,
      depositario, administrador ni tenedor de los inmuebles ofrecidos, ni
      tampoco interviene en la confección de las publicaciones ni el
      perfeccionamiento de las operaciones ni en las condiciones estipuladas en
      cada publicación, ni tampoco ejerce control alguno sobre los datos
      señalados en ellas, por lo que no es responsable por la existencia,
      calidad, cantidad, estado, o integridad de los inmuebles ofrecidos, ni por
      la exactitud, veracidad, exhaustividad y actualidad de la información
      contenida en las publicaciones efectuadas.
    </p>
    <p>
      12.2. Toda la información acerca de los inmuebles ofrecidos en el Sitio es
      proporcionada por los corredores públicos inmobiliarios matriculados,
      siendo -por ende- de su exclusiva responsabilidad.
    </p>
    <p>
      12.3. RE/MAX Argentina y Uruguay no ofrece garantía alguna ni será
      responsable por la calidad, precio, condiciones de venta, estado, vicios
      ocultos o redhibitorios ni por las consecuencias que deriven de la
      compraventa y/o uso de los inmuebles que pueda adquirir a raíz de las
      publicaciones realizadas por los corredores públicos inmobiliarios
      matriculados de cada Oficina.
    </p>
    <p class="title">13. Seguridad física.</p>
    <p>
      13.1. RE/MAX Argentina y Uruguay no garantiza las condiciones de seguridad
      y protección a quienes pauten con una Oficina la visita de un inmueble
      publicado en el Sitio o cualquier otro tipo de encuentro con una Oficina
      y/o corredor público inmobiliario y/o persona de cualquier manera
      vinculada a una Oficina.
    </p>
    <p>
      13.2. COVID-19: RE/MAX Argentina y Uruguay no verifica la debida adopción
      de las Normas y Protocolos de Seguridad Sanitaria ni garantiza el efectivo
      cumplimiento de las mismas por cada Oficina, y en consecuencia, no será
      responsable por cualquier incumplimiento a las Normas y Protocolos de
      Seguridad Sanitaria dictadas por la pandemia COVID-19. Usted deberá
      consultar a cada Oficina respecto de cualquier información relativa a la
      implementación de las Normas y Protocolos de Seguridad Sanitaria dictadas
      por la pandemia COVID-19.
    </p>
    <p class="title">14. Ley aplicable y tribunal competente.</p>
    <p>
      Los presentes T&C y las normas que lo complementan se regirán por las
      leyes de la República Argentina, siendo competentes para cualquier
      controversia que pudiere llegar a suscitarse, los Tribunales Nacionales en
      lo Comercial, con asiento en la Ciudad de Buenos Aires.
    </p>
  </div>
  } @if(this.node === 'uy'){
  <div class="main-wrapper">
    <h1>Términos y condiciones de uso</h1>
    <p>
      Bienvenido al sitio web
      <a href="https://www.remax.com.uy/"> https://www.remax.com.uy/</a>
      Cada propiedad que Ud. verá en el Sitio es publicada por una oficina
      franquiciada por RE/MAX Argentina y Uruguay (en adelante, la/s
      “Oficina/s”). En este sentido, Usted acepta que el sitio web es
      simplemente una plataforma y que RE/MAX Argentina y Uruguay es ajena a
      cualquier relación que pudiere entablarse entre Usted y quienes publiquen
      avisos, anuncios y/o propuestas en el Sitio.
    </p>
    <p>
      Cada propiedad que Ud. verá en Sitio es publicada por un agente
      inmobiliario que forma parte de una oficina franquiciada por RE/MAX
      Argentina y Uruguay (en adelante, la/s “Oficina/s”). En este sentido,
      Usted acepta que el sitio web es simplemente una plataforma y que RE/MAX
      Argentina y Uruguay es ajena a cualquier relación que pudiere entablarse
      entre Usted y quienes publiquen avisos, anuncios y/o propuestas en el
      Sitio.
    </p>

    <p class="title">2. Las Oficinas</p>
    <p>
      2.1 Cada Oficina que realiza publicaciones en el Sitio es de propiedad y
      gestión independiente. Por consiguiente, RE/MAX Argentina y Uruguay no
      tiene injerencia ni participación alguna en la actividad de cada oficina
      y, por ende, en ninguna de las publicaciones que estos realizan en el
      Sitio.
    </p>
    <p>
      2.2 Usted reconoce expresamente que RE/MAX Argentina y Uruguay: no actúa
      en representación de las Oficinas; no actúa como mandatario de las
      Oficinas; no es propietario, poseedor, titular, depositario, administrador
      ni tiene ningún derecho sobre los inmuebles publicados en el Sitio; no
      comercializa por sí ni por cuenta ajena los inmuebles publicados en el
      Sitio; no realiza tasaciones; no cobra comisiones por las operaciones
      inmobiliarias que se realicen. Cada publicación que Ud. verá en el Sitio
      fue realizada por una Oficina.
    </p>
    <p class="title">3. Aceptación</p>
    <p>
      3.1 Toda persona que utilice el Sitio acepta todos y cada uno de los T&C,
      sus modificaciones y documentos complementarios. Se deja constancia que
      los T&C tienen carácter obligatorio y vinculante, por lo que es importante
      que los lea con detenimiento.
    </p>
    <p>
      3.2 El Sitio se reserva el derecho, a su entera discreción, de actualizar
      estos T&C en cualquier momento. Al utilizar este Sitio luego de dichas
      modificaciones, usted acepta estar sujeta a las mismas, así como también a
      sus modificaciones y/o actualizaciones. Por ello, es importante que el Ud.
      revise los T&C previo a navegar el presente sitio web.
    </p>
    <p class="title">4. El Sitio</p>
    <p>
      4.1 El Sitio es una plataforma de publicación de avisos de inmuebles que
      RE/MAX Argentina y Uruguay provee a las Oficinas franquiciadas, para que
      cada Oficina pueda publicar las propiedades que se encuentran
      comercializando. Por ende, RE/MAX Argentina y Uruguay no participa en
      ninguna de las publicaciones del Sitio y cualquier interacción que Ud.
      realice en el Sitio será directamente con la Oficina que efectuó la
      publicación y en ningún caso con RE/MAX Argentina y Uruguay.
    </p>
    <p class="title">5. Publicaciones</p>
    <p>
      5.1 Cada propiedad que Ud. verá publicada en Sitio es realizada a través
      de una Oficina franquiciada.
    </p>
    <p>
      5.2 La información provista en cada publicación fue provista
      exclusivamente por dicha Oficina franquiciada (cuyos datos se exhiben en
      cada publicación). Por cualquier inquietud respecto a la eventual
      actualización de las medidas, descripciones arquitectónicas y funcionales,
      valores de gastos comunes, servicios, impuestos, precios y demás
      información que se requiera a sus efectos -cuyos valores son aproximados-
      deberá consultar a la Oficina correspondiente responsable de la operación
      y publicación.
    </p>
    <p class="title">6. Datos de Contacto</p>
    <p>
      6.1 Los datos de contacto que se exhiben en cada publicación corresponden
      a las personas que integran cada Oficina. Cualquier persona que se
      comunique con Ud. actúa bajo el liderazgo del responsable de cada Oficina.
    </p>
    <p class="title">7. Agentes adheridos a una Oficina RE/MAX</p>
    <p>
      7.1. El visitante del sitio podrá registrarse como Usuario, pero este no
      es un requisito para poder acceder y visualizar los anuncios publicados
      por los corredores inmobiliarios. El registro como Usuario les permitirá a
      los visitantes acceder a funcionalidades adicionales, tales como la
      posibilidad de guardar los avisos que resulten de su interés en un
      catálogo de favoritos al cual podrán acceder cada vez que se inicie sesión
      como Usuario y ubicarlos con mayor facilidad en tanto se encuentren
      disponibles en el Sitio.
    </p>
    <p>
      7.2. El visitante para registrarse como Usuario deberá: (i) completar el
      formulario de registración en todos sus campos con datos válidos, exactos,
      precisos, verdaderos y completos. Los datos que se requieren para el
      registro como Usuarios son los siguientes: (a) correo electrónico y (b)
      contraseña personal. Adicionalmente, quedara a disposición del registrante
      la posibilidad de incluir su número telefónico en el proceso de registro.
      (ii) a través de aplicaciones de terceros, tales como Facebook o Google.
      En este supuesto, el Usuario deberá aceptar los términos y condiciones y
      las políticas de privacidad de dicha aplicación.
    </p>
    <p>
      7.3. El Usuario dispondrá de una cuenta con su correo electrónico y
      contraseña que le permitirá el acceso personalizado, confidencial y seguro
      del sitio. El Usuario tendrá la posibilidad de cambiar la clave de acceso,
      para la cual deberá sujetarse al procedimiento establecido en el sitio. El
      Usuario comprende y acepta que dicha clave es de uso personal e
      intransferible y es exclusivamente responsable de mantenerla bajo
      confidencialidad y no entregarla ni divulgarla con terceros.
    </p>
    <p>
      7.4. RE/MAX Argentina y Uruguay podrá negarse a conceder al visitante el
      registro como Usuario cuando: (i) el correo electrónico utilizado
      pertenece a otro Usuario registrado previamente y/o (ii) un correo
      electrónico que resulte invalido o inexistente.
    </p>
    <p>
      7.5. El Usuario es completamente responsable de todas las interacciones en
      el sitio que se produzca en relación con su cuenta, y deberá notificar
      inmediatamente a RE/MAX Argentina y Uruguay a
      <a href="mailto:atencion&#64;remax.com.uy ">atencion&#64;remax.com.uy</a>
      cualquier uso no autorizado de su cuenta o contraseña o de cualquier otra
      violación de la seguridad relacionada con su cuenta. RE/MAX no será
      responsable por el mal uso o uso negligente de la cuenta ni por cualquier
      perdida o daño causado por el incumplimiento de cualquier de las
      obligaciones anteriores.
    </p>
    <p>
      7.6. RE/MAX Argentina y Uruguay no se hacen responsables por la certeza o
      falsedad de los datos suministrados por los Usuarios. El Usuario es quien
      garantiza y responde por la veracidad, exactitud, vigencia y autenticidad
      de los datos suministrados.
    </p>
    <p>
      7.7. El registro como Usuario no garantiza, en ninguna circunstancia, que
      su solicitud de información o interés sea tratada de forma preferente por
      ninguno de los corredores inmobiliarios. Los corredores inmobiliarios son
      los únicos encargados y responsables de gestionar cada uno de los avisos
      que publican.
    </p>
    <p>
      7.8. El Usuario reconoce que RE/MAX Argentina y Uruguay podrá eliminar o
      suspender su cuenta como Usuario en caso de incumplir con alguna de las
      disposiciones y obligaciones establecidas en los presentes Términos y
      Condiciones.
    </p>
    <p>
      7.9. El Usuario podrá eliminar su cuenta a través de la opción que se
      habilite a tal fin en su perfil de usuario. El Usuario comprenden que, al
      solicitar la eliminación de su cuenta, también está solicitando la
      eliminación de todos los datos asociados a ella.
    </p>
    <p class="title">8. Modificaciones de los T&C.</p>
    <p>
      8.1 Los Agentes adheridos a una Oficina se vinculan únicamente con las
      distintas Oficinas franquiciadas, no existiendo ningún vínculo de ellos
      con RE/MAX Argentina y Uruguay.
    </p>
    <p class="title">9. Datos Personales.</p>
    <p>
      9.1 RE/MAX Argentina y Uruguay podrá modificar, a su entera discreción,
      estos T&C en cualquier momento. El uso del Sitio y/o sus servicios implica
      la aceptación de estos Términos y Condiciones.
    </p>
    <p>
      9.2 Para conocer el detalle de los términos y condiciones sobre las
      Políticas de Privacidad que se aplican al Sitio lo invitamos a revisar el
      siguiente link:
      <a href="https://www.remax.com.uy/politica-de-privacidad">
        Política de Privacidad</a
      >
    </p>
    <p class="title">10. Indemnidad.</p>
    <p>
      10.1 RE/MAX Argentina y Uruguay mantendrá bajo estricta confidencialidad
      los Datos Personales suministrados en el Sitio, salvo en los casos en los
      que la Ley de Protección de Datos Personales permita su divulgación y con
      la salvedad descripta en el siguiente párrafo. En igual forma, RE/MAX
      Argentina y Uruguay exige a sus proveedores los mismos estándares de
      privacidad.
    </p>
    <p>
      10.2 Para conocer el detalle de los términos y condiciones sobre las
      Políticas de Privacidad que se aplican al Sitio lo invitamos a revisar el
      siguiente link:
      <a href="/politica-de-privacidad">Politica de privacidad</a>
    </p>
    <p class="title">11. Información correspondiente a las propiedades.</p>
    <p>
      11.1 La aceptación de los T&C implica en forma expresa e irrevocable
      reconocer que el Sitio es una plataforma utilizada por cada Oficina del
      sistema. En consecuencia, Ud. se obliga a mantener indemne a RE/MAX
      Argentina y Uruguay y/o a sus sociedades vinculadas, funcionarios,
      directivos, administradores, representantes y empleados, respecto a
      cualquier reclamo relacionado a una publicación realizada en el Sitio por
      una Oficina.
    </p>
    <p>
      11.2 Usted acepta mantener indemne a RE/MAX Argentina y Uruguay de y
      contra cualquier cargo, acción o demanda, incluyendo, pero no limitándose
      a, los gastos legales razonables, que resulten del uso que Usted haga del
      Sitio. Usted acepta y reconoce que el Sitio es una mera plataforma, ajena
      a cualquier relación que pudiera entablarse entre Usted y quienes
      publiquen avisos, anuncios y/o propuestas a través del Sitio. En tal
      sentido, Usted acepta no reclamar a RE/MAX Argentina y Uruguay por ningún
      tipo de consecuencia derivada de tales relaciones.
    </p>
    <p class="title">12. Seguridad física.</p>
    <p>
      12.1 RE/MAX Argentina y Uruguay no es titular, propietario, poseedor,
      depositario, administrador ni tenedor de los inmuebles ofrecidos, ni
      tampoco interviene en la confección de las publicaciones ni el
      perfeccionamiento de las operaciones ni en las condiciones estipuladas en
      cada publicación, ni tampoco ejerce control alguno sobre los datos
      señalados en ellas, por lo que no es responsable por la existencia,
      calidad, cantidad, estado, o integridad de los inmuebles ofrecidos, ni por
      la exactitud, veracidad, exhaustividad y actualidad de la información
      contenida en las publicaciones efectuadas.
    </p>

    <p>
      12.2 Toda la información acerca de los inmuebles ofrecidos en el Sitio es
      proporcionada por las oficinas, siendo -por ende- de su exclusiva
      responsabilidad.
    </p>
    <p>
      12.3 RE/MAX Argentina y Uruguay no ofrece garantía alguna ni será
      responsable por la calidad, precio, condiciones de venta, estado, vicios
      ocultos o redhibitorios ni por las consecuencias que deriven de la
      compraventa y/o uso de los inmuebles que pueda adquirir a raíz de las
      publicaciones realizadas por cada Oficina.
    </p>
    <p class="title">13. Ley aplicable y tribunal competente.</p>
    <p>
      13.1 RE/MAX Argentina y Uruguay no garantiza las condiciones de seguridad
      y protección a quienes pauten con una Oficina la visita de un inmueble
      publicado en el Sitio o cualquier otro tipo de encuentro con una Oficina
      y/o persona de cualquier manera vinculada a una Oficina.
    </p>
    <p>
      13.2 COVID-19: RE/MAX Argentina y Uruguay no verifica la debida adopción
      de las Normas y Protocolos de Seguridad Sanitaria ni garantiza el efectivo
      cumplimiento de las mismas por cada Oficina, y en consecuencia, no será
      responsable por cualquier incumplimiento a las Normas y Protocolos de
      Seguridad Sanitaria dictadas por las autoridades en relación a la pandemia
      COVID-19. Usted deberá consultar a cada Oficina respecto de cualquier
      información relativa a la implementación de las Normas y Protocolos de
      Seguridad Sanitaria dictadas por las autoridades por la pandemia COVID-19.
    </p>
    <p class="title">14. Ley aplicable y tribunal competente.</p>
    <p>
      Los presentes T&C y las normas que lo complementan se regirán por las
      leyes de la República Oriental del Uruguay, siendo competentes para
      cualquier controversia que pudiere llegar a suscitarse, los Tribunales
      Ordinarios con sede en la ciudad de Montevideo, República Oriental del
      Uruguay.
    </p>
  </div>
  } @if(this.node === 'ec'){
  <div class="main-wrapper">
    <h1>Terms of use</h1>
    <p>
      Please Read Carefully Before Using This Web Site Welcome to RE/MAX's web
      site, remax.com.eg. The goals of this web site include providing consumers
      with access to information about listings, properties and land for sale
      and for rent throughout the RE/MAX Organization and by RE/MAX Affiliates,
      as well as various real estate related issues that we think may be of
      interest to you. Company information and information on the RE/MAX
      Organization and RE/MAX Affiliates is also,provided on this site. To
      insure a safe, non-offensive environment for all visitors to this web
      site, we have established these "Terms of Use." By accessing any areas of
      remax.com.eg, you agree to be bound by the terms and conditions set forth
      below. If you do not agree to all of the Terms of Use, please do not use
      this site.
    </p>
    <p class="title">COPYRIGHT NOTICE</p>
    <p>
      The materials at this web site, as well as the organization and layout of
      this site, are copyrighted and are protected by United States and
      international copyright laws and treaty provisions. You may access,
      download and print materials on this web site solely for your personal and
      non-commercial use, however, any print out of this web site, or portions
      of the web site, must include RE/MAX ’s copyright notice. No right, title
      or interest in any of the materials contained on this web site is
      transferred to you as a result of accessing, downloading or printing such
      materials. Unless otherwise specified, you may not copy, modify,
      distribute, transmit, display, reproduce, publish, license, create
      derivative works from, link to or frame in another web site, use on any
      other web site, transfer or sell any information obtained from this web
      site, without written permission from RE/MAX . The foregoing prohibition
      expressly includes, but is not limited to, the practices of "screen
      scraping" or "database scraping" to obtain lists of users or other
      information.
    </p>
    <p class="title">TRADEMARKS</p>
    <p>
      RE/MAX®, Above the Crowd®, Take a Step Above The Crowd®, Out in
      Front®, It's the Experience®, The Real Estate Superstars®, the
      red-over-white-over blue horizontal bar design and the RE/MAX graphic hot
      air balloon logo are among the registered service marks of RE/MAX
      International, Inc. RE/MAX International's marks may not be used in any
      way without receiving RE/MAX International’s prior express written
      permission.
    </p>
    <p class="title">LINKS</p>
    <p>
      For your convenience, RE/MAX may provide links to various other web sites
      that may be of interest to you. However, RE/MAX does not control such web
      sites and is not responsible for their content nor is it responsible for
      the accuracy or reliability of any information, data, opinions, advice, or
      statements contained within such web sites. Furthermore, RE/MAX privacy
      policy is applicable only when you are on our web site. Once you link to
      another web site, you should read that site's privacy policy before
      disclosing any personal information. If you decide to access any of the
      third party sites linked to this web site, you do so at your own risk.
      RE/MAX reserves the right to terminate any link or linking program at any
      time.
    </p>
    <p class="title">DISCLAIMER</p>
    <p>
      THE MATERIALS CONTAINED IN THIS WEB SITE ARE PROVIDED ON AN "AS IS" AND
      “AS AVAILABLE” BASIS, WITHOUT REPRESENTATIONS OR WARRANTIES OF ANY KIND.
      RE/MAX EXPRESSLY DISCLAIMS ALL SUCH REPRESENTATIONS AND WARRANTIES, EITHER
      EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION WARRANTIES OF TITLE,
      NONINFRINGEMENT, OR IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A
      PARTICULAR PURPOSE. RE/MAX DOES NOT WARRANT THE ACCURACY OR COMPLETENESS
      OF THE INFORMATION, TEXT, GRAPHICS, LINKS OR OTHER ITEMS CONTAINED WITHIN
      THESE MATERIALS. RE/MAX MAY MAKE CHANGES TO THIS WEB SITE AT ANY TIME
      WITHOUT NOTICE. WHILE RE/MAX STRIVES TO KEEP THE INFORMATION ON THIS SITE
      ACCURATE AND CURRENT, RE/MAX CANNOT GUARANTEE THE ACCURACY, COMPLETENESS
      OR TIMELINESS OF THE INFORMATION. INFORMATION WITHIN THE RE/MAX WEB SITE
      MAY CONTAIN TECHNICAL INACCURACIES OR TYPOGRAPHICAL ERRORS. RE/MAX
      RESERVES THE RIGHT TO MAKE CHANGES, CORRECTIONS AND/OR IMPROVEMENTS TO THE
      INFORMATION CONTAINED WITHIN THIS WEB SITE, AT ANY TIME, WITHOUT NOTICE.
    </p>
    <p class="title">LIMITATION OF LIABILITY</p>
    <p>
      IN NO EVENT SHALL RE/MAX BE LIABLE FOR ANY DAMAGES WHATSOEVER ARISING OUT
      OF THE USE OF, OR INABILITY TO USE, THE WEB SITE, INCLUDING BUT NOT
      LIMITED TO DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES,
      EVEN IF RE/MAX HAS BEEN ADVISED OF THE POSSIBLITY OF SUCH DAMAGES. BECAUSE
      SOME JURISDICTIONS PROHIBIT THE EXCLUSION OR LIMITATION OF LIABILITY FOR
      CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO
      YOU.
    </p>
    <p class="title">UPDATES</p>
    <p>
      RE/MAX reserves the right to make changes to the remax.com web site and
      these Terms of Use at any time and may revise these Terms of Use at any
      time by updating this posting. You should visit this page from time to
      time to review the then-current Terms of Use because they are binding on
      you. Your use of this site following any such change constitutes your
      agreement to follow and be bound by the Terms of Use as changed. If any
      change is unacceptable to you, your only recourse is to terminate your use
      of the site.
    </p>
  </div>
  }
</div>
