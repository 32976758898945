<div class="contact-container">
  <div class="contact-wrapper">
    @if (!isFormCorrectlySent) {
      <div class="contact-wrapper__title">
        <h2 class="contact-wrapper__heading">Envíenos un mensaje</h2>
        <p class="contact-wrapper__description mat-typography">
          Completá el formulario para contactarnos sobre cualquier pregunta o
          comentario que tengas y nos contactaremos a la brevedad.
        </p>
      </div>
      <div class="contact-wrapper__form-container">
        <form
          [formGroup]="form"
          (ngSubmit)="onSubmitFormContact()"
          class="contact-form">
          <!-- Nombre y apellido -->
          <mat-form-field class="contact-form__field" appearance="outline">
            <mat-label>Nombre y apellido</mat-label>
            <input
              matInput
              formControlName="fullName"
              required
              name="fullName" />
            @if (fullName.invalid) {
              <mat-error>{{ getErrorMessage('fullName') }}</mat-error>
            }
          </mat-form-field>
          <!-- Email -->
          <mat-form-field class="contact-form__field" appearance="outline">
            <mat-label>Email</mat-label>
            <input
              type="email"
              matInput
              formControlName="email"
              required
              name="email" />
            @if (email.invalid) {
              <mat-error>{{ getErrorMessage('email') }}</mat-error>
            }
          </mat-form-field>
          <!-- Teléfono -->
          <mat-form-field class="contact-form__field" appearance="outline">
            <mat-label>Teléfono</mat-label>
            <input
              type="tel"
              matInput
              formControlName="phone"
              required
              name="phone" />
            @if (phone.invalid) {
              <mat-error>{{ getErrorMessage('phone') }}</mat-error>
            }
          </mat-form-field>
          <!-- Ubicación -->
          <mat-form-field
            class="contact-form__field"
            appearance="outline"
            name="ubication">
            <mat-label>Dirección</mat-label>
            <input matInput formControlName="ubication" required />
            @if (ubication.invalid) {
              <mat-error>{{ getErrorMessage('ubication') }}</mat-error>
            }
          </mat-form-field>
          <!-- Como nos conociste -->
          <mat-form-field class="contact-form__field" appearance="outline">
            <mat-label>Categoría</mat-label>
            <mat-select
              formControlName="categoryType"
              placeholder="Categoría"
              required>
              @for (pType of optionsCategory; track pType) {
                <mat-option [value]="pType">
                  {{ pType }}
                </mat-option>
              }
            </mat-select>
            @if (categoryType.invalid) {
              <mat-error>{{ getErrorMessage('categoryType') }}</mat-error>
            }
          </mat-form-field>
          <!-- Comentarios -->
          <mat-form-field class="contact-form__field" appearance="outline">
            <mat-label>Comentarios</mat-label>
            <textarea
              matInput
              required
              formControlName="comments"
              name="comments"
              id="comments"
              cols="30"
              rows="10"></textarea>
            @if (comments.invalid) {
              <mat-error>{{ getErrorMessage('comments') }}</mat-error>
            }
          </mat-form-field>
          <div class="contact-form__checkbox-container">
            <mat-checkbox (change)="onCheck($event)"></mat-checkbox>
            <span>Quiero recibir una copia del mensaje en mi email</span>
          </div>
          <div class="contact-form__button-container">
            <qr-button [config]="this.configButtonSend"></qr-button>
          </div>
          <!-- Disclaimer: Terminos y condiciones -->
          <div class="contact-form__terms-and-conditions">
            <p>
              Al enviar se están aceptando los
              <a href="/terminos-y-condiciones" target="blank"
                >Términos y Condiciones de Uso</a
              >
              y la
              <a href="/politica-de-privacidad" target="blank"
                >Política de Privacidad</a
              >
            </p>
          </div>
        </form>
      </div>
    } @else {
      <!-- Formulario: enviado correctamente -->
      <div class="contact-wrapper__form-sent">
        <div class="contact-wrapper__check">
          <div class="contact-wrapper__img"></div>
        </div>
        <p>
          Información enviada correctamente una oficina adherida a la red RE/MAX
          se pondrá en contacto con vos para continuar con el proceso
        </p>
      </div>
    }
  </div>
  <div class="contact-container__img">
    <div id="img" [class]="getHeroClassRegion()"></div>
  </div>
</div>
