import { BreadcrumbURLs } from './breadcrumb-urls.model';

export class SeoBreadcrumb {
  public static readonly TYPE_LIST_ITEM: string = 'ListItem';

  '@type': string;
  position: number = 0;
  name: string = '';
  item: string = '';
}

export class SeoBreadcrumbConfig {
  operationType?: string; // Type EJ: Venta o alquiler
  propertyType?: string; // Tipo de propiedad EJ: PH, CASA, DEPARTAMENTO
  rootLabel?: string; // Estado EJ: Buenos Aires
  countie?: string; // Condado / partido EJ: Avellaneda
  citie?: string; // Ciudad EJ: Crucecita
  neighborhood?: string; // Vecindario EJ: Barrio Guemes
  privateCommunitie?: string; // Barrio Privado EJ: Nombre del barrio privado
  title?: string; // Título de la propiedad EJ: Departamento de 4 ambientes
  urls?: BreadcrumbURLs; // Es la url que le pasamos completa
}
