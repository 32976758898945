<label
  [for]="config?.id"
  class="label"
  [ngClass]="{
    'label--valid': isValid,
    'label--disabled': isDisabled,
  }"
  >{{ config?.label }}</label
>
<div class="textarea">
  <textarea
    [id]="config?.id"
    [value]="value ? value : null"
    class="textarea__input"
    placeholder="Mensaje"
    type="text"
    (input)="change($event)"
    [class.textarea--is-valid]="isValid"
    [class.textarea--has-error]="hasError"
    [disabled]="isDisabled"
    (blur)="blurChange.emit(true)"></textarea>
  @if (isValid) {
    <qr-icon [config]="iconCheck" class="textarea__icon"></qr-icon>
  }
  @if (hasError) {
    <qr-icon [config]="iconError" class="textarea__icon"></qr-icon>
  }
</div>
@if (config?.hint && !hasError) {
  <small
    class="hint"
    [ngClass]="{
      'hint--error': hasError,
      'hint--ok': isValid,
      'hint--disabled': isDisabled,
    }"
    >{{ config?.hint }}</small
  >
}
@if (config?.error && hasError) {
  <small class="hint hint--error">
    {{ config?.error }}
  </small>
}
