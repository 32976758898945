import { Component, Input, Inject, OnInit } from '@angular/core';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { Associate } from '@app/core/models/associate.model';
import { environment } from '@base/environments/environment';
import { PictureSource } from '@app/core/models/picture-source';
import { Constants } from '@base/src/app/core/constants/constants';
import { UtilsService } from '@app/services/utils.service';
import { PictureSourceComponent } from '../ui/picture-source/picture-source.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'qr-card-info-broker',
  templateUrl: './qr-card-info-broker.component.html',
  styleUrls: ['./qr-card-info-broker.component.scss'],
  standalone: true,
  imports: [PictureSourceComponent, CommonModule],
})
export class QrCardInfoBrokerComponent implements OnInit {
  @Input() data: Associate = new Associate();
  @Input() isImgTop = false;
  resizedImageWebp: string = '';

  imageBucketResizeUrl: string = environment.imageBucketResizeUrl;
  imageError = false;
  // Picture and Source
  brokerResizedImages: PictureSource = {
    text: Constants.FOLDER_BROKERS,
    docType: 'image/' + Constants.DOCTYPE_WEBP,
    loading: PictureSource.LOADING_LAZY,
  };

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private utils: UtilsService
  ) {}

  ngOnInit(): void {
    this.resizePhotosWebp();
  }

  resizePhotosWebp(): void {
    if (this.data.rawPhoto == null) {
      this.resizedImageWebp = '';
      return;
    }

    this.resizedImageWebp = this.utils.generateImageWithSize(
      this.data.rawPhoto,
      '180x180'
    );
  }
}
