<nav>
  <div id="nav-wrapper" class="container">
    <qr-icon-button
      id="button-menu-mobile"
      icon="menu"
      (click)="openSideNav()"
      [config]="configButtonMenu"
      [ariaLabel]="'Menu mobile'"></qr-icon-button>

    <div class="remax-logo">
      <a href="/collection">
        <qr-media-img
          [config]="logoTheREMAXCollectionSVG"
          id="logo"></qr-media-img>
      </a>
    </div>

    <div id="menu-desktop">
      <ul>
        @for (item of menuItems; track item) {
          <li>
            @if (item.link === '/comprar-propiedades') {
              <a (click)="applyFilterAndRedirect()" role="button">
                {{ item.label | translate: locale.language }}
              </a>
            } @else {
              <a
                [href]="item.link"
                [target]="item.link?.includes('http') ? '_blank' : null"
                role="button">
                {{ item.label | translate: locale.language }}
              </a>
            }
          </li>
        }
      </ul>
    </div>
  </div>
</nav>
