@if (config) {
  <div class="input-autocomplete-container">
    <qr-input [config]="config" (inputChange)="inputChange($event)"></qr-input>
    <div class="options" [class.show]="isOptionsVisible">
      @for (option of this.filteredOptions; track option) {
        <button (click)="select(option)">
          <span class="option subheading-01-semi-bold" matRipple>
            {{ option }}
          </span>
        </button>
      }
    </div>
  </div>
}
