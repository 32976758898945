@if (!isImgTop) {
  <div
    class="flex-row container-qr-card-info-broker flex-start-center"
    itemscope
    itemtype="https://schema.org/RealEstateAgent">
    <!-- Modo de detalle de propiedad (Foto de perfil al costado)  -->
    <div class="photo-container flex-center-center">
      <picture-source
        [config]="brokerResizedImages"
        [src]="resizedImageWebp"
        [alt]="'Foto'"></picture-source>
    </div>
    <div class="flex" id="info-container">
      <div id="title">
        <h4>Corredor Público Inmo.</h4>
      </div>
      <div id="name">
        <h4 itemprop="name">{{ data.name | titlecase }}</h4>
      </div>
      <div id="license">
        <h4>{{ data.license | uppercase }}</h4>
      </div>
    </div>
  </div>
} @else {
  <div class="container-qr-card-info-broker flex-col flex-center-center">
    <!-- Perfil de agente  -->
    <div class="photo-container-top">
      <picture-source
        [config]="brokerResizedImages"
        [src]="resizedImageWebp"
        [alt]="'Foto'"></picture-source>
    </div>
    <div id="info-container" class="flex-col flex-center-center">
      <div id="name-top">
        <h2 itemprop="name">{{ data.name }}</h2>
      </div>
      <div id="license-top">
        <h4>{{ data.license | uppercase }}</h4>
      </div>
    </div>
  </div>
}
