<div id="terms-main-container">
  @if(this.node === 'ar'){
  <div class="main-wrapper">
    <h1>Política de Privacidad</h1>

    <p>
      La presente política de privacidad (en adelante la “Política de
      Privacidad”) se aplica al uso del sitio web publicado en el URL
      <a href="https://www.remax.com.ar/"> https://www.remax.com.ar/</a>
      (en adelante el “Sitio Web”) provisto por Remax Argentina S.R.L. (en
      adelante “RE/MAX Argentina y Uruguay”) del mismo modo que se define en los
      <a href="https://www.remax.com.ar/terminos-y-condiciones">
        Términos y Condiciones</a
      >
      De resultar necesario, RE/MAX Argentina y Uruguay podrá complementar la
      presente Política de Privacidad con información y/o definiciones
      utilizadas en los Términos y Condiciones.
    </p>
    <p>
      El registro en el Sitio Web otorga la condición de usuario de RE/MAX
      Argentina y Uruguay (en adelante “Usuario” y/o “Usuarios”) y establece la
      aceptación plena y sin ningún tipo de reservas de todas y cada una de las
      cláusulas establecidas en la <span> Política de Privacidad</span> en la
      versión que se encuentre publicada en el Sitio Web.
    </p>
    <p>
      RE/MAX Argentina y Uruguay se reserva la posibilidad de actualizar en
      cualquier momento la presente Política de Privacidad. Las nuevas versiones
      se encontrarán publicadas en el URL Política de Privacidad. En este
      sentido, se notificará al Usuario la actualización de la Política de
      Privacidad a través del correo electrónico.
    </p>
    <p>
      Por lo expuesto, la Política de Privacidad constituye un acuerdo válido y
      obligatorio entre el Usuario y RE/MAX Argentina y Uruguay con relación a
      la privacidad.
    </p>
    <p class="title">1. Recolección de información de los Usuarios. Fines</p>
    <p>
      1.1. RE/MAX Argentina y Uruguay puede solicitar los siguientes datos
      personales a los Usuarios; i. Nombre y apellido; ii. Teléfono, si el
      Usuario desea compartirlo; iii. Correo electrónico, únicamente cuando el
      Usuario utilice este método para registrarse en el Sitio Web (en adelante
      el “Dato Personal” o los “Datos Personales”, según corresponda). RE/MAX
      Argentina y Uruguay recopila los Datos Personales de los Usuarios a través
      del registro en el Sitio Web.
    </p>
    <p>
      1.2. El Usuario acepta que RE/MAX Argentina y Uruguay pueda recolectar
      información para mejorar la calidad del servicio que se brinda a través
      del Sitio Web, así como también, solicitará la información para llevar
      adelante acciones de promoción de RE/MAX Argentina y Uruguay. De este modo
      las ofertas, beneficios y nuevas oportunidades se adecuarán a las
      necesidades de los Usuarios, a fin de brindar la mejor experiencia posible
      con RE/MAX Argentina y Uruguay.
    </p>
    <p>
      1.3. Los Usuarios, por su parte, garantizan y responden, frente a
      cualquier caso, de la veracidad, exactitud, vigencia y autenticidad de la
      información aportada.
    </p>
    <p class="title">2. Uso de la información recolectada</p>
    <p>
      2.1. RE/MAX Argentina y Uruguay trata Datos Personales de los Usuarios,
      únicamente, gracias al consentimiento expreso que el Usuario otorga a
      través de la aceptación de la presente Política de Privacidad.
    </p>
    <p>
      2.2. RE/MAX Argentina y Uruguay almacenará y utilizará la información
      provista por el Usuario y la recolectada por RE/MAX Argentina y Uruguay
      por el plazo máximo que establezca la Ley 25.326 y/o la ley aplicable con
      el fin de analizarla y poder brindar a los Usuarios el mejor servicio
      posible.
    </p>
    <p>
      RE/MAX Argentina y Uruguay utilizará la información provista por los
      Usuarios y la recolectada por RE/MAX Argentina y Uruguay con el fin de
      analizarla y poder brindar a los Usuarios, datos relevantes para poder
      mejorar el Servicio y realizar acciones de publicidad. Para ello, RE/MAX
      Argentina y Uruguay, podrá:
      <br />
      <br />
      i. Conservar las búsquedas o filtros aplicados en el Sitio Web;
      <br /><br />ii. Almacenar las propiedades que sean clasificadas como
      favoritos por el Usuario; <br /><br />iii. Enviar a sus Usuarios,
      notificaciones, noticias y novedades de su interés, además de aquellas que
      revistan el carácter de notificaciones de índole institucional o legal. El
      Usuario tendrá la posibilidad de cancelar dichas notificaciones enviando
      un correo electrónico a la dirección<a
        href="mailto:atencion&#64;remax.com.ar">
        atencion&#64;remax.com.ar</a
      >con el asunto “Cancelar notificaciones”.
    </p>
    <p>
      2.4. RE/MAX Argentina y Uruguay podrá compartir la información con otras
      empresas de servicios o sitios de internet o similares con el objetivo de
      mejorar la calidad del servicio. Generalmente dichas empresas o sitios de
      internet poseen sus propias políticas de privacidad de datos a los fines
      de su protección. En cualquier caso, RE/MAX Argentina y Uruguay realizará
      todos los esfuerzos posibles para que la privacidad de la información que
      se comparta sea protegida de la mejor manera posible. En los casos que
      corresponda RE/MAX Argentina y Uruguay intentará firmar acuerdos expresos
      en materia de protección de datos y de privacidad de información.<br /><br />Sin
      perjuicio de lo mencionado, RE/MAX Argentina y Uruguay no será responsable
      por los daños provocados por tales empresas y/o sitios de internet en
      cuanto a su deber de protección, confidencialidad y privacidad de los
      datos que ellas manejan.
    </p>
    <p>
      2.5. RE/MAX Argentina y Uruguay compartirá la información recolectada, en
      caso de ser requerida por los tribunales, u organismos estatales
      nacionales similares o internacionales que así lo requieran y lo soliciten
      en la forma correspondiente.
    </p>

    <p>
      2.6. El Sitio Web puede contener enlaces que redirijan a otros sitios que
      no sean propiedad de RE/MAX Argentina y Uruguay. Frente a estos supuestos,
      RE/MAX Argentina y Uruguay no será responsable por el actuar de dichos
      sitios, a los cuales no se aplicará la presente Política de Privacidad.
      RE/MAX Argentina y Uruguay recomienda examinar la política de privacidad
      que rija en aquellos sitios para entender los procedimientos de
      recolección de información y como protegen sus datos personales.
    </p>

    <p class="title">3. El Sitio Web. Uso de cookies</p>
    <p>
      3.1. RE/MAX Argentina y Uruguay no comercializará de ninguna forma los
      Datos Personales con otras personas físicas o jurídicas. A los efectos de
      esta cláusula, el término comercializar incluye, vender, alquilar o
      negociar los Datos Personales. RE/MAX Argentina y Uruguay garantiza al
      Usuario el derecho de acceso, actualización, supresión o corrección de sus
      Datos Personales tratados por RE/MAX Argentina y Uruguay a través del
      Sitio Web. El Usuario podrá realizar las acciones mencionadas por sí mismo
      mediante el ingreso a su perfil en el Sitio Web.
    </p>

    <p class="title">4. Confidencialidad y seguridad de la información</p>
    <p>
      4.1. RE/MAX Argentina y Uruguay adopta todas las medidas de seguridad
      razonables destinadas a proteger la información de los Usuarios y del
      mismo modo impedir el acceso de terceros no autorizados. Los Datos
      Personales de los Usuarios serán conservados de forma estrictamente
      confidencial. En igual forma, RE/MAX Argentina y Uruguay exige a sus
      proveedores los mismos estándares de privacidad.
    </p>
    <p>
      4.2. Independientemente de lo mencionado en la cláusula 4.1., RE/MAX
      Argentina y Uruguay no podrá asegurar que terceros no autorizados no
      puedan eventualmente superar todas las medidas de seguridad y utilizar la
      información de los Usuarios en forma indebida. Frente a estas
      circunstancias, RE/MAX Argentina y Uruguay no será responsable por
      intercepciones ilegales o violación de sus sistemas o bases de datos por
      parte de personas no autorizadas así como por la indebida utilización de
      la información obtenida por esos medios.
    </p>
    <p class="title">5. Derechos de los Usuarios</p>
    <p>
      5.1. RE/MAX Argentina y Uruguay realizará sus mejores esfuerzos para
      facilitar el acceso de los Usuarios a los Datos Personales que hayan sido
      recopilados o almacenados por RE/MAX Argentina y Uruguay.
    </p>
    <p>
      El usuario podrá ejercer su derecho de acceder, actualizar, borrar o
      corregir su información personal a través del Sitio Web. En el supuesto
      que por cualquier motivo ello no fuera posible, el Usuario podrá
      contactarse a través del correo electrónico atencion&#64;remax.com.ar con
      el asunto “DATOS PERSONALES” y una foto de su Documento Nacional de
      Identidad solicitando el acceso, actualización, supresión o corrección de
      sus Datos Personales. RE/MAX Argentina y Uruguay responderá dentro de los
      10 (diez) días de efectuada la mencionada solicitud.
    </p>
    <p>
      RE/MAX Argentina y Uruguay podrá denegar dichas solicitudes cuando se
      encuentre obligada a ello o por contar con algún derecho a conservar
      dichos Datos de acuerdo a la legislación aplicable.
    </p>
    <p>
      EL TITULAR DE LOS DATOS PERSONALES TIENE LA FACULTAD DE EJERCER EL DERECHO
      DE ACCESO A LOS MISMOS EN FORMA GRATUITA A INTERVALOS NO INFERIORES A SEIS
      MESES, SALVO QUE SE ACREDITE UN INTERÉS LEGÍTIMO AL EFECTO CONFORME LO
      ESTABLECIDO EN EL ARTÍCULO 14, INCISO 3 DE LA LEY Nº 25.326 DE LA
      REPUBLICA ARGENTINA. LA AUTORIDAD DE APLICACIÓN (EN ARGENTINA, LA AGENCIA
      DE ACCESO A LA INFORMACIÓN PÚBLICA, ÓRGANO DE CONTROL DE LA LEY Nº
      25.326), TIENE LA ATRIBUCIÓN DE ATENDER LAS DENUNCIAS Y RECLAMOS QUE SE
      INTERPONGAN CON RELACIÓN AL INCUMPLIMIENTO DE LAS NORMAS SOBRE PROTECCIÓN
      DE DATOS PERSONALES.
    </p>
    <p>Para contactar a la AGENCIA DE ACCESO A LA INFORMACIÓN PÚBLICA:</p>
    <p>
      Sita en: Av. Pte. Julio A. Roca 710, Piso 2º - Ciudad de Buenos Aires
      <br />
      URL
      <a href="www.argentina.gob.ar/aaip"> www.argentina.gob.ar/aaip</a><br />
      Correo electrónico:
      <a href="mailto:datospersonales&#64;aaip.gob.ar">
        datospersonales&#64;aaip.gob.ar</a
      ><br />
      Tel. <span>+ 5411-2821-0047</span><br />
    </p>

    <p class="title">6. Transferencia internacional de Datos Personales</p>

    <p>
      6.1. Únicamente para cumplir con los fines establecidos en la presente
      Política de Privacidad y a efectos de prestar el servicio, RE/MAX
      Argentina y Uruguay podrá transferir los Datos Personales y/o la
      información personal a las autoridades, socios de RE/MAX Argentina y
      Uruguay y otros terceros, como por ejemplo aquellos proveedores de
      servicios de infraestructura técnica y alojamiento de datos que sean
      utilizados en relación con el Servicio.
    </p>
    <p>
      En algunos casos, como la República Argentina, la información personal
      puede transferirse fuera del país a países con los mismos o mejores
      niveles de protección. En caso de que la información personal se
      transfiera a países con niveles más bajos de protección, RE/MAX Argentina
      y Uruguay realizará esas transferencias sujetas a las salvaguardas y
      medidas de seguridad adecuadas, como las cláusulas estándar de protección
      de datos adoptadas en los respectivos acuerdos o aprobadas por la
      autoridad legal aplicable.
    </p>
    <p>
      6.2. Por consiguiente, el Usuario entiende, consiente y acepta, a través
      de la presente Política de Privacidad, que sus Datos Personales puedan ser
      transferidos a otros países, tengan o no un adecuado estándar de
      protección. El retiro del consentimiento por parte del Usuario no
      invalidará las acciones anteriores de RE/MAX Argentina y Uruguay.
    </p>

    <p class="title">7. Cambios en la estructura corporativa</p>
    <p>
      7.1. RE/MAX Argentina y Uruguay se reserva el derecho a transferir la
      información recolectada en caso de una venta o fusión de RE/MAX Argentina
      y Uruguay, o de una adquisición de los activos principales de RE/MAX
      Argentina y Uruguay, o cualquier otra forma de transferencia que RE/MAX
      Argentina y Uruguay pueda sufrir. En caso de presentarse esto, RE/MAX
      Argentina y Uruguay adoptará las medidas razonables en búsqueda de
      asegurar que dicha información sea utilizada de una manera consistente en
      la Política de Privacidad.
    </p>
    <p class="title">8. Excepciones</p>
    <p>
      8.1. No obstante cualquier otra previsión en contrario, RE/MAX Argentina y
      Uruguay podrá divulgar cierta información personal de los Usuarios, cuando
      crea de buena fe que esa divulgación resulte razonablemente necesaria
      para:
    </p>
    <p>
      i. cumplir una exigencia legal, tal como una orden de allanamiento, una
      citación o una orden judicial; <br />
      ii. cumplir un requerimiento de una autoridad gubernamental o reguladora;
      y/o <br />

      iii. proteger los derechos, propiedad o seguridad de RE/MAX Argentina y
      Uruguay, de los Usuarios, o de un tercero.
    </p>
    <p class="title">9. Menores</p>
    <p>
      9.1. El Sitio Web y/o servicios están dirigidos a mayores de edad, sin
      embargo, en el caso que un menor tenga acceso a los mismos, su uso deberá
      ser supervisado por sus padres, tutores o responsables legales. El Sitio
      Web está permitido sólo para quienes tengan edad legal para contratar y no
      se encuentren inhibidos legalmente o de algún modo vedados de ejercer
      actos jurídicos, derechos y/u obligaciones. Habida cuenta de ello, los
      menores de 18 años no tienen permitido el ingreso al Sitio Web y/o la
      utilización del servicio, así como tampoco suministrar ningún Dato
      Personal ni ningún otro tipo de información.
    </p>

    <p>
      9.2. Asimismo, toda vez que los menores de edad pueden no alcanzar a
      comprender debidamente la Política de Privacidad y sus implicancias, ni
      decidir válidamente sobre las opciones disponibles a través de su
      servicio, RE/MAX Argentina y Uruguay insta a todos los padres o
      representantes, tutores o adultos bajo cuya supervisión se encuentren los
      menores que accedan al Sitio Web de RE/MAX Argentina y Uruguay, a
      participar activa y cuidadosamente en las actividades que el menor realice
      en internet o través del Sitio Web, al servicio on-line que utilicen
      dichos menores, a la información a la que estos accedan, ya sea cuando
      dichos menores visiten el Sitio Web o cualquier otro sitio de terceros, a
      enseñarles y a guiarlos en cómo proteger su propia información personal
      mientras estén navegando en internet.
    </p>

    <p class="title">10. Modificaciones</p>

    <p>
      10.1. RE/MAX Argentina y Uruguay podrá modificar la presente Política de
      Privacidad en aquellos casos que lo considere oportuno. En caso de que las
      modificaciones sean sustanciales con relación al tratamiento de los Datos
      Personales recolectados en virtud de la utilización del Servicio, las
      mismas serán notificadas mediante la publicación de un aviso destacado en
      el Sitio Web y con el envío de un correo electrónico a la dirección
      declarada por el Usuario para el registro en caso de utilizar esta vía.
    </p>

    <p class="title">11. Contacto</p>
    <p>
      11.1. En caso que el Usuario tenga alguna duda acerca de la Política de
      Privacidad, o sobre la aplicación de la misma, deberá ponerse en contacto
      con RE/MAX Argentina y Uruguay, en cualquier momento, vía correo
      electrónico a
      <a href="mailto:atencion&#64;remax.com.ar"> atencion&#64;remax.com.ar</a>
    </p>
  </div>
  } @if(this.node === 'uy'){
  <div class="main-wrapper">
    <h1>Política de Privacidad</h1>

    <p>
      La presente política de privacidad (en adelante la “Política de
      Privacidad”) se aplica al uso del sitio web publicado en el URL
      <a href="https://www.remax.com.uy/"> https://www.remax.com.uy/</a>
      (en adelante el “Sitio Web”) provisto por Marroquil S.A. (RUT
      216195310011) (en adelante “RE/MAX Argentina y Uruguay”) del mismo modo
      que se define en los Términos y Condiciones publicados en
      <a href="https://www.remax.com.uy/terminos-y-condiciones"
        >https://www.remax.com.uy/terminos-y-condiciones</a
      >. De resultar necesario, RE/MAX Argentina y Uruguay podrá complementar la
      presente Política de Privacidad con información y/o definiciones
      utilizadas en los Términos y Condiciones.
    </p>
    <p>
      El mero acceso al Sitio Web otorga la condición de usuario de RE/MAX
      Argentina y Uruguay (en adelante “Usuario” y/o “Usuarios”) y establece la
      aceptación plena y sin ningún tipo de reservas de todas y cada una de las
      cláusulas establecidas en la Política de Privacidad en la versión que se
      encuentre publicada en el Sitio Web. De este modo, el Usuario reconoce que
      el uso del Sitio Web importa una aceptación de la Política de Privacidad y
      adhiere a ella en todos sus términos.
    </p>

    <p>
      RE/MAX Argentina y Uruguay se reserva la posibilidad de actualizar en
      cualquier momento la presente Política de Privacidad. Las nuevas versiones
      se encontrarán publicadas en el URL
      <a href="http://www.remax.com.uy/politica-de-privacidad"
        >http://www.remax.com.uy/politica-de-privacidad</a
      >. En este sentido, se notificará al Usuario la actualización de la
      Política de Privacidad a través del correo electrónico.
    </p>
    <p>
      Por lo expuesto, la Política de Privacidad constituye un acuerdo válido y
      obligatorio entre el Usuario y RE/MAX Argentina y Uruguay con relación a
      la privacidad.
    </p>

    <p class="title">1. Recolección de información de los Usuarios. Fines</p>
    <p>
      1.1. RE/MAX Argentina y Uruguay puede solicitar los siguientes datos
      personales a los Usuarios; i. Nombre y apellido; ii. Teléfono; iii. Correo
      electrónico; iv. Domicilio (en adelante el “Dato Personal” o los “Datos
      Personales”, según corresponda). RE/MAX Argentina y Uruguay recopila los
      Datos Personales de los Usuarios a través del formulario de contacto
      establecido en el Sitio Web.
    </p>
    <p>
      1.2. El Usuario acepta que RE/MAX Argentina y Uruguay pueda recolectar
      información para mejorar la calidad del servicio que se brinda a través
      del Sitio Web, así como también, solicitará la información para llevar
      adelante acciones de promoción de RE/MAX Argentina y Uruguay. De este modo
      las ofertas, beneficios y nuevas oportunidades se adecuarán a las
      necesidades de los Usuarios, a fin de brindar la mejor experiencia posible
      con RE/MAX Argentina y Uruguay.
    </p>
    <p>
      1.3. Los Usuarios, por su parte, garantizan y responden, frente a
      cualquier caso, de la veracidad, exactitud, vigencia y autenticidad de la
      información aportada.
    </p>
    <p class="title">2. Uso de la información recolectada</p>
    <p>
      2.1. RE/MAX Argentina y Uruguay trata Datos Personales de los Usuarios,
      únicamente, gracias al consentimiento expreso que el Usuario otorga a
      través de la aceptación de la presente Política de Privacidad.
    </p>
    <p>
      2.2. RE/MAX Argentina y Uruguay almacenará y utilizará la información
      provista por el Usuario y la recolectada por RE/MAX Argentina y Uruguay
      por el plazo máximo que establezca la Ley 25.326 y/o la ley aplicable con
      el fin de analizarla y poder brindar a los Usuarios el mejor servicio
      posible.
    </p>
    <p>
      2.3 RE/MAX Argentina y Uruguay utilizará la información provista por los
      Usuarios y la recolectada por RE/MAX Argentina y Uruguay con el fin de
      analizarla y poder brindar a los Usuarios, datos relevantes para poder
      mejorar el Servicio y realizar acciones de publicidad. Para ello, RE/MAX
      Argentina y Uruguay, podrá:
      <br />
      i. Conservar las búsquedas o filtros aplicados en el Sitio Web;
      <br />
      ii. Almacenar las propiedades que sean clasificadas como favoritos por el
      Usuario;
      <br />
      iii. Enviar a sus Usuarios, notificaciones, noticias y novedades de su
      interés, además de aquellas que revistan el carácter de notificaciones de
      índole institucional o legal. El Usuario tendrá la posibilidad de cancelar
      dichas notificaciones enviando un correo electrónico a la dirección
      <a href="mailto:atencion&#64;remax.com.uy">atencion&#64;remax.com.uy</a>
      con el asunto “Cancelar notificaciones”.
    </p>
    <p>
      2.4. RE/MAX Argentina y Uruguay podrá compartir la información con otras
      empresas de servicios o sitios de internet o similares con el objetivo de
      mejorar la calidad del servicio. Generalmente dichas empresas o sitios de
      internet poseen sus propias políticas de privacidad de datos a los fines
      de su protección. En cualquier caso, RE/MAX Argentina y Uruguay realizará
      todos los esfuerzos posibles para que la privacidad de la información que
      se comparta sea protegida de la mejor manera posible. En los casos que
      corresponda RE/MAX Argentina y Uruguay intentará firmar acuerdos expresos
      en materia de protección de datos y de privacidad de información.
      <br />
      Sin perjuicio de lo mencionado, RE/MAX Argentina y Uruguay no será
      responsable por los daños provocados por tales empresas y/o sitios de
      internet en cuanto a su deber de protección, confidencialidad y privacidad
      de los datos que ellas manejan.
    </p>

    <p>
      2.5. RE/MAX Argentina y Uruguay compartirá la información recolectada, en
      caso de ser requerida por los tribunales, u organismos estatales
      nacionales similares o internacionales que así lo requieran y lo soliciten
      en la forma correspondiente.
    </p>
    <p>
      2.6. El Sitio Web puede contener enlaces que redirijan a otros sitios que
      no sean propiedad de RE/MAX Argentina y Uruguay. Frente a estos supuestos,
      RE/MAX Argentina y Uruguay no será responsable por el actuar de dichos
      sitios, a los cuales no se aplicará la presente Política de Privacidad.
      RE/MAX Argentina y Uruguay recomienda examinar la política de privacidad
      que rija en aquellos sitios para entender los procedimientos de
      recolección de información y como protegen sus datos personales.
    </p>
    <p class="title">3. El Sitio Web. Uso de cookies</p>
    <p>
      3.1. RE/MAX Argentina y Uruguay no comercializará de ninguna forma los
      Datos Personales con otras personas físicas o jurídicas. A los efectos de
      esta cláusula, el término comercializar incluye, vender, alquilar o
      negociar los Datos Personales. RE/MAX Argentina y Uruguay garantiza al
      Usuario el derecho de acceso, actualización, supresión o corrección de sus
      Datos Personales tratados por RE/MAX Argentina y Uruguay a través del
      Sitio Web. El Usuario podrá realizar las acciones mencionadas por sí mismo
      mediante el ingreso a su perfil en el Sitio Web.
    </p>
    <p class="title">4. Confidencialidad y seguridad de la información</p>
    <p>
      4.1. RE/MAX Argentina y Uruguay adopta todas las medidas de seguridad
      razonables destinadas a proteger la información de los Usuarios y del
      mismo modo impedir el acceso de terceros no autorizados. Los Datos
      Personales de los Usuarios serán conservados de forma estrictamente
      confidencial. En igual forma, RE/MAX Argentina y Uruguay exige a sus
      proveedores los mismos estándares de privacidad.
    </p>
    <p>
      4.2. Independientemente de lo mencionado en la cláusula 4.1., RE/MAX
      Argentina y Uruguay no podrá asegurar que terceros no autorizados no
      puedan eventualmente superar todas las medidas de seguridad y utilizar la
      información de los Usuarios en forma indebida. Frente a estas
      circunstancias, RE/MAX Argentina y Uruguay no será responsable por
      intercepciones ilegales o violación de sus sistemas o bases de datos por
      parte de personas no autorizadas así como por la indebida utilización de
      la información obtenida por esos medios.
    </p>
    <p class="title">5. Derechos de los Usuarios</p>
    <p>
      5.1. RE/MAX Argentina y Uruguay realizará sus mejores esfuerzos para
      facilitar el acceso de los Usuarios a los Datos Personales que hayan sido
      recopilados o almacenados por RE/MAX Argentina y Uruguay.
    </p>
    <p>
      El usuario podrá ejercer su derecho de acceder, actualizar, borrar o
      corregir su información personal a través del Sitio Web. En el supuesto
      que por cualquier motivo ello no fuera posible, el Usuario podrá
      contactarse a través del correo electrónico
      <a href="mailto:atencion&#64;remax.com.uy">atencion&#64;remax.com.uy</a>
      con el asunto “DATOS PERSONALES” y una foto de su Documento Nacional de
      Identidad solicitando el acceso, actualización, supresión o corrección de
      sus Datos Personales. RE/MAX Argentina y Uruguay responderá dentro de los
      10 (diez) días de efectuada la mencionada solicitud.
    </p>
    <p>
      RE/MAX Argentina y Uruguay podrá denegar dichas solicitudes cuando se
      encuentre obligada a ello o por contar con algún derecho a conservar
      dichos Datos de acuerdo a la legislación aplicable.
    </p>
    <p>
      EL TITULAR DE LOS DATOS PERSONALES TIENE LA FACULTAD DE EJERCER EL DERECHO
      DE ACCESO A LOS MISMOS EN FORMA GRATUITA A INTERVALOS NO INFERIORES A SEIS
      MESES, SALVO QUE SE ACREDITE UN INTERÉS LEGÍTIMO AL EFECTO CONFORME LO
      ESTABLECIDO EN EL ARTÍCULO 14, INCISO 1° DE LA LEY Nº 18.331 DE LA
      REPUBLICA ORIENTAL DEL URUGUAY. LA AUTORIDAD DE APLICACIÓN (EN URUGUAY, LA
      AGENCIA DE GOBIERNO ELECTRÓNICO Y SOCIEDAD DE LA INFORMACIÓN Y DEL
      CONOCIMIENTO (“AGESIC”), ÓRGANO DE CONTROL DE LA MENCIONADA LEY), TIENE LA
      ATRIBUCIÓN DE ATENDER LAS DENUNCIAS Y RECLAMOS QUE SE INTERPONGAN CON
      RELACIÓN AL INCUMPLIMIENTO DE LAS NORMAS SOBRE PROTECCIÓN DE DATOS
      PERSONALES. PERSONALES.
    </p>
    <p>
      Para contactar a la Unidad Reguladora y de Control de Datos Personales:
      <br />
      Sita en: Liniers 1324 piso 4 <br />
      URL:
      <a href="https://www.gub.uy/unidad-reguladora-control-datos-personales/">
        https://www.gub.uy/unidad-reguladora-control-datos-personales/</a
      ><br />
      Tel. <span> 2901 0065, opción 3</span>
    </p>
    <p class="title">6. Transferencia internacional de Datos Personales</p>
    <p>
      6.1. Únicamente para cumplir con los fines establecidos en la presente
      Política de Privacidad y a efectos de prestar el servicio, RE/MAX
      Argentina y Uruguay podrá transferir los Datos Personales y/o la
      información personal a las autoridades, socios de RE/MAX Argentina y
      Uruguay y otros terceros, como por ejemplo aquellos proveedores de
      servicios de infraestructura técnica y alojamiento de datos que sean
      utilizados en relación con el Servicio.
      <br />
      En algunos casos, como la República Argentina, la información personal
      puede transferirse fuera del país a países con los mismos o mejores
      niveles de protección. En caso de que la información personal se
      transfiera a países con niveles más bajos de protección, RE/MAX Argentina
      y Uruguay realizará esas transferencias sujetas a las salvaguardas y
      medidas de seguridad adecuadas, como las cláusulas estándar de protección
      de datos adoptadas en los respectivos acuerdos o aprobadas por la
      autoridad legal aplicable.
    </p>
    <p>
      6.2. Por consiguiente, el Usuario entiende, consiente y acepta, a través
      de la presente Política de Privacidad, que sus Datos Personales puedan ser
      transferidos a otros países, tengan o no un adecuado estándar de
      protección. El retiro del consentimiento por parte del Usuario no
      invalidará las acciones anteriores de RE/MAX Argentina y Uruguay.
    </p>

    <p></p>
    <p class="title">7. Cambios en la estructura corporativa</p>
    <p>
      7.1. RE/MAX Argentina y Uruguay se reserva el derecho a transferir la
      información recolectada en caso de una venta o fusión de RE/MAX Argentina
      y Uruguay, o de una adquisición de los activos principales de RE/MAX
      Argentina y Uruguay, o cualquier otra forma de transferencia que RE/MAX
      Argentina y Uruguay pueda sufrir. En caso de presentarse esto, RE/MAX
      Argentina y Uruguay adoptará las medidas razonables en búsqueda de
      asegurar que dicha información sea utilizada de una manera consistente en
      la Política de Privacidad.
    </p>
    <p class="title">8. Excepciones</p>
    <p>
      8.1. No obstante cualquier otra previsión en contrario, RE/MAX Argentina y
      Uruguay podrá divulgar cierta información personal de los Usuarios, cuando
      crea de buena fe que esa divulgación resulte razonablemente necesaria
      para:
      <br />
      i. cumplir una exigencia legal, tal como una orden de allanamiento, una
      citación o una orden judicial;
      <br />
      ii. cumplir un requerimiento de una autoridad gubernamental o reguladora;
      y/o y/o<br />
      iii. proteger los derechos, propiedad o seguridad de RE/MAX Argentina y
      Uruguay, de los Usuarios, o de un tercero.
    </p>
    <p class="title">9. Menores</p>
    <p>
      9.1. El Sitio Web y/o servicios están dirigidos a mayores de edad, sin
      embargo, en el caso que un menor tenga acceso a los mismos, su uso deberá
      ser supervisado por sus padres, tutores o responsables legales. El Sitio
      Web está permitido sólo para quienes tengan edad legal para contratar y no
      se encuentren inhibidos legalmente o de algún modo vedados de ejercer
      actos jurídicos, derechos y/u obligaciones. Habida cuenta de ello, los
      menores de 18 años no tienen permitido el ingreso al Sitio Web y/o la
      utilización del servicio, así como tampoco suministrar ningún Dato
      Personal ni ningún otro tipo de información.
    </p>

    <p></p>
    <p>
      9.2. Asimismo, toda vez que los menores de edad pueden no alcanzar a
      comprender debidamente la Política de Privacidad y sus implicancias, ni
      decidir válidamente sobre las opciones disponibles a través de su
      servicio, RE/MAX Argentina y Uruguay insta a todos los padres o
      representantes, tutores o adultos bajo cuya supervisión se encuentren los
      menores que accedan al Sitio Web de RE/MAX Argentina y Uruguay, a
      participar activa y cuidadosamente en las actividades que el menor realice
      en internet o través del Sitio Web, al servicio on-line que utilicen
      dichos menores, a la información a la que estos accedan, ya sea cuando
      dichos menores visiten el Sitio Web o cualquier otro sitio de terceros, a
      enseñarles y a guiarlos en cómo proteger su propia información personal
      mientras estén navegando en internet.
    </p>
    <p class="title">9. Modificaciones</p>
    <p>
      10.1. RE/MAX Argentina y Uruguay podrá modificar la presente Política de
      Privacidad en aquellos casos que lo considere oportuno. En caso de que las
      modificaciones sean sustanciales con relación al tratamiento de los Datos
      Personales recolectados en virtud de la utilización del Servicio, las
      mismas serán notificadas mediante la publicación de un aviso destacado en
      el Sitio Web y con el envío de un correo electrónico a la dirección
      declarada por el Usuario para el registro en caso de utilizar esta vía.
    </p>
    <p class="title">9. Contacto</p>
    <p>
      11.1. En caso que el Usuario tenga alguna duda acerca de la Política de
      Privacidad, o sobre la aplicación de la misma, deberá ponerse en contacto
      con RE/MAX Argentina y Uruguay, en cualquier momento, vía correo
      electrónico a
      <a href="mailto:atencion&#64;remax.com.uy">atencion&#64;remax.com.uy</a>
    </p>
  </div>
  } @if(this.node === 'ec'){
  <div class="main-wrapper">
    <h1>Política de Privacidad</h1>
    <p>
      Su privacidad es muy importante para nosotros. La política de RE/MAX es
      respetar y proteger la privacidad de los visitantes de nuestro sitio web.
      El objetivo de esta política de privacidad es proporcionarle una
      descripción del tipo de información que recopilamos y cómo usamos esa
      información. RE/MAX no recopila datos de identificación individual en los
      usuarios. No recopilamos información de identificación personal en nuestro
      sitio web a menos que elija entregárnosla a través de un mensaje de correo
      electrónico o mediante la función de búsqueda guardada. Incluso cuando
      elige darnos información, la mantenemos confidencial y no proporcionaremos
      su dirección de correo electrónico a ningún tercero. Cuando los invitados
      visitan el sitio web, recopilamos cierta información básica que no
      identifica a los usuarios individuales. Esto incluye la cantidad de
      tráfico que visita el sitio, qué páginas se visitan y por cuánto tiempo,
      de dónde provienen los visitantes y qué ISP están utilizando. Usamos la
      información que recopilamos para mejorar la calidad de nuestro sitio web,
      mejorar o adaptar la información que ofrecemos y hacer que su experiencia
      en nuestro sitio sea lo más valiosa y eficiente posible.
    </p>
    <p class="title">Enlaces a otros sitios web.</p>
    <p>
      Tenga en cuenta que cuando hace clic en enlaces que lo llevan a sitios web
      de terceros, estará sujeto a sus respectivas políticas de privacidad. Si
      bien RE/MAX respalda la protección de la privacidad en Internet, RE/MAX no
      puede ser responsable de las acciones de terceros. Le recomendamos que lea
      la declaración de privacidad publicada cuando interactúe con cualquier
      sitio web.
    </p>

    <p class="title">
      Consentimiento para recopilar información no identificable.
    </p>
    <p>
      Al utilizar nuestro sitio web, acepta la recopilación y el uso de su
      información por parte de RE/MAX como se describe en esta política. Si
      RE/MAX actualiza esta política de privacidad, dichos cambios se publicarán
      en esta página. Para que siempre esté al tanto de la información que
      recopilamos y cómo la utilizamos, le recomendamos que revise esta página
      regularmente.
    </p>
  </div>
  }
</div>
