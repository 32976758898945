<div id="indice-remax-container">
  <div id="cover-wrapper">
    <qr-media-img [config]="logoIndiceREMAX" class="title-index"></qr-media-img>
    <div id="img-portada"></div>
  </div>

  <div class="chips-container">
    @for (item of chips; track item) {
    <div class="item-chip">
      <p (click)="goTo(item, 'mobile')">{{item}}</p>
    </div>
    }
  </div>
  <div class="chips-container-desktop">
    @for (item of chips; track item) {
    <div class="item-chip">
      <p (click)="goTo(item, 'desktop')">{{item}}</p>
    </div>
    }
  </div>
  <div class="data-container">
    <h2 id="Índice">Índice m2 real</h2>
    <div class="img-container" id="index"></div>
    <div class="indice-section-container">
      <div class="block"></div>
      <div class="img-container" id="index-desktop"></div>
      <div class="text-block">
        <h3>¿Qué es el Índice m2 real?</h3>
        <p class="margin-p">
          Es un estudio realizado con datos analizados por la red federal de
          oficinas adheridas a la marca RE/MAX con el análisis metodológico,
          técnico y estadístico por parte de UCEMA y el respaldo de Reporte
          Inmobiliario en el que se dan a conocer precios promedios efectivos
          por metro cuadrado.
        </p>
        <p class="">
          El trabajo consistió en elaborar un índice representativo del precio
          promedio del metro cuadrado de inmueble efectivo en la Ciudad de
          Buenos Aires, que permite tener una referencia de valor real promedio
          y su evolución en el tiempo, y que permita comparar con el precio de
          venta promedio del metro cuadrado publicado.
        </p>
      </div>
    </div>
    <h3 class="mobile">¿Qué es el Índice m2 real?</h3>
    <p class="margin-p mobile">
      Es un estudio realizado con datos analizados por la red federal de
      oficinas adheridas a la marca RE/MAX con el análisis metodológico, técnico
      y estadístico por parte de UCEMA y el respaldo de Reporte Inmobiliario en
      el que se dan a conocer precios promedios efectivos por metro cuadrado.
    </p>
    <p class="mobile">
      El trabajo consistió en elaborar un índice representativo del precio
      promedio del metro cuadrado de inmueble efectivo en la Ciudad de Buenos
      Aires, que permite tener una referencia de valor real promedio y su
      evolución en el tiempo, y que permita comparar con el precio de venta
      promedio del metro cuadrado publicado.
    </p>

    <!--
    <div class="intro-container">
      <div class="intro-background"></div>
      <h1 id="Introducción">Introducción</h1>
      <div class="intro-wrapper">
        <h2>
          Por Sebastián Sosa – Presidente RE/MAX Argentina y Uruguay
        </h2>
        <p class="margin-p">
          Cada una de las oficinas adheridas a la marca RE/MAX
          entiende que el momento de comprar o vender una propiedad es
          un momento delicado que genera inseguridades porque están
          sobre la mesa deseos, sentimientos, metas personales y, por
          supuesto, factores económicos. Con esto en mente, el año
          pasado elaboramos un estudio de construcción de precios para
          ayudar a entender el mercado inmobiliario y sus
          particularidades en un contexto que hizo que los precios se
          comportaron de forma distinta.
        </p>
        <p class="margin-p">
          El papel de las oficinas adheridas a RE/MAX siempre ha sido
          acompañar al cliente y consideramos muy importante que los
          colegas del rubro y clientes puedan acceder a información
          valiosa que facilite el entendimiento de los precios de las
          propiedades y que les sirva de guía para el momento de tomar
          una decisión de compra o venta de una vivienda.
        </p>
        <p class="margin-p">
          En este sentido, nos parece importante comprometernos aún
          más con la gente y dar a conocer los promedios de precios
          efectivos en algunas de las plazas más importantes del país.
          RE/MAX es una red integrada por más de 150 oficinas en 42
          ciudades del país, lo que permite tener un volumen
          importante de operaciones en diferentes zonas de Argentina.
        </p>
        <p class="margin-p">
          Con el caudal de información que proviene de las oficinas
          adheridas a la red, nos sentimos con la responsabilidad de
          compartirla para aportar claridad sobre los precios reales
          que se están manejando actualmente en el mercado y que
          muchas veces se distorsionan.
        </p>
        <p class="margin-p">
          Para llevar adelante este informe, conformamos un equipo
          interdisciplinario en el contamos con el apoyo de una
          institución tan prestigiosa como Universidad del CEMA y el
          respaldo de los expertos de Reporte Inmobiliario, juntamos
          fuerzas para construir este análisis y poder llegar a
          definir con mayor precisión los precios finales de las
          propiedades y las diferencias que hay entre el valor que se
          publica contra el que se cierra la negociación.
        </p>
        <p>
          A partir de los datos de operaciones de ventas de
          departamentos del año 2020 y los primeros cinco meses del
          año en curso, tanto de publicación, como de precio efectivo,
          decidimos aliarnos para elaborar este informe y hacer
          pública esta información que hasta el momento estaba solo
          disponible hacia el interior de cada oficina de gestión
          independiente
        </p>
      </div>
    </div>

    <h1 id="Introducción" class="mobile">Introducción</h1>
    <h2 class="mobile">
      Por Sebastián Sosa – Presidente RE/MAX Argentina y Uruguay
    </h2>
    <p class="margin-p mobile">
      Cada una de las oficinas adheridas a la marca RE/MAX entiende que el
      momento de comprar o vender una propiedad es un momento delicado que
      genera inseguridades porque están sobre la mesa deseos,
      sentimientos, metas personales y, por supuesto, factores económicos.
      Con esto en mente, el año pasado elaboramos un estudio de
      construcción de precios para ayudar a entender el mercado
      inmobiliario y sus particularidades en un contexto que hizo que los
      precios se comportaron de forma distinta.
    </p>
    <p class="margin-p mobile">
      El papel de las oficinas adheridas a RE/MAX siempre ha sido
      acompañar al cliente y consideramos muy importante que los colegas
      del rubro y clientes puedan acceder a información valiosa que
      facilite el entendimiento de los precios de las propiedades y que
      les sirva de guía para el momento de tomar una decisión de compra o
      venta de una vivienda.
    </p>
    <p class="margin-p mobile">
      En este sentido, nos parece importante comprometernos aún más con la
      gente y dar a conocer los promedios de precios efectivos en algunas
      de las plazas más importantes del país. RE/MAX es una red integrada
      por más de 150 oficinas en 42 ciudades del país, lo que permite
      tener un volumen importante de operaciones en diferentes zonas de
      Argentina.
    </p>
    <p class="margin-p mobile">
      Con el caudal de información que proviene de las oficinas adheridas
      a la red, nos sentimos con la responsabilidad de compartirla para
      aportar claridad sobre los precios reales que se están manejando
      actualmente en el mercado y que muchas veces se distorsionan.
    </p>
    <p class="margin-p mobile">
      Para llevar adelante este informe, conformamos un equipo
      interdisciplinario en el contamos con el apoyo de una institución
      tan prestigiosa como Universidad del CEMA y el respaldo de los
      expertos de Reporte Inmobiliario, juntamos fuerzas para construir
      este análisis y poder llegar a definir con mayor precisión los
      precios finales de las propiedades y las diferencias que hay entre
      el valor que se publica contra el que se cierra la negociación.
    </p>
    <p class="mobile">
      A partir de los datos de operaciones de ventas de departamentos del
      año 2020 y los primeros cinco meses del año en curso, tanto de
      publicación, como de precio efectivo, decidimos aliarnos para
      elaborar este informe y hacer pública esta información que hasta el
      momento estaba solo disponible hacia el interior de cada oficina de
      gestión independiente
    </p>
    -->
    <h2 class="dif" id="Metodologías">Metodologías</h2>
    <p class="metodology">
      Para elaborar el índice se utilizó una base de datos de la cual se
      filtraron operaciones correspondientes a la Ciudad Autónoma de Buenos
      Aires.
    </p>
    <div class="indice-section-container" id="last">
      <div class="block" id="second"></div>
      <div class="img-container" id="metodology-desktop"></div>
      <div class="text-block" id="right">
        <h3>Los datos disponibles de las operaciones vienen dados por:</h3>
        <ul>
          <li class="font">
            Nombre del Barrio o Localidad donde se realiza la transacción.
          </li>
          <li class="font">
            Cantidad de ambientes del inmueble comercializado.
          </li>
          <li class="font">Precio efectivo por m2.</li>
          <li class="font">Cantidad de m2.</li>
          <li class="font">Mes de la transacción</li>
        </ul>
      </div>
    </div>
    <div class="ol-wrapper">
      <h2>El procesamiento de la base de datos consistió en:</h2>
      <ol>
        <li class="font">Separar las operaciones por ciudad.</li>
        <li class="font">
          Ordenarlas en función del mes y en función del precio promedio por m2,
          de menor a mayor.
        </li>
        <li class="font">
          Analizar los datos elaborando diferentes índices comparativos, que se
          sintetizan en los siguientes:

          <ul class="list">
            <li class="font">
              Índice de precio promedio de m2 por mes, tomando el promedio
              simple de precios de m2 de cada mes.
            </li>
            <li class="font">
              Índice de precio promedio de m2 por mes, tomando el total de
              precios efectivos en el mes dividido por la cantidad de m2
              transaccionados.
            </li>
          </ul>
        </li>
        <li class="font">
          Se eliminaron distorsiones y sesgos provenientes de datos atípicos.
        </li>
        <li class="font">
          Finalmente, se elaboraron índices tomando como referencia
          departamentos de un ambiente, de dos ambientes y de tres ambientes, se
          calculó el precio promedio del m2 para cada mes, y se analizó su
          evolución. En algunos casos los resultados del mes de abril o mayo del
          2020 fueron descartados en función del impacto concreto que la
          pandemia y las restricciones impuestas para su control provocaron en
          el mercado, paralizando casi por completo su actividad.
        </li>
      </ol>
      <p>
        De esta manera se logró obtener datos precisos respecto a los valores
        del metro cuadrado de departamentos en la Ciudad Autónoma de Buenos
        Aires.
      </p>
    </div>

    <div class="ol-wrapper" id="report">
      <h2 id="DesktopReport">Reportes</h2>
      @if(options && options.length>0){
      <div class="dropdown-select">
        <div class="icon" id="calendar"></div>
        <div class="border"></div>
        <mat-select
          [placeholder]="placeholder"
          (selectionChange)="loadReportsByYear($event.value)">
          @for (option of options; track option) {
          <mat-option [value]="option" class="mat-option-abm"
            >{{ option }}</mat-option
          >
          }
        </mat-select>
      </div>
      }

      <div class="index-wrap">
        @if(index && index.length>0){
        <ng-container>
          @for (item of index; track item.title) {
          <div class="index-container">
            <a [href]="item.url" target="_blank">
              <h3 class="title">{{item.title}}</h3></a
            >
          </div>
          }
        </ng-container>
        } @else {
        <ng-template>
          <p class="no-data">
            No hay informes disponibles para el año seleccionado
          </p>
        </ng-template>
        }
      </div>
    </div>
    <div class="img-container mobile" id="method"></div>
    <h3 class="mobile">
      Los datos disponibles de las operaciones vienen dados por:
    </h3>
    <ul class="mobile">
      <li class="font">
        Nombre del Barrio o Localidad donde se realiza la transacción.
      </li>
      <li class="font">Cantidad de ambientes del inmueble comercializado.</li>
      <li class="font">Precio efectivo por m2.</li>
      <li class="font">Cantidad de m2.</li>
      <li class="font">Mes de la transacción</li>
    </ul>
    <h2 class="mobile">El procesamiento de la base de datos consistió en:</h2>
    <ol class="mobile">
      <li class="font">Separar las operaciones por ciudad.</li>
      <li class="font">
        Ordenarlas en función del mes y en función del precio promedio por m2,
        de menor a mayor.
      </li>
      <li class="font">
        Analizar los datos elaborando diferentes índices comparativos, que se
        sintetizan en los siguientes:

        <ul class="list">
          <li class="font">
            Índice de precio promedio de m2 por mes, tomando el promedio simple
            de precios de m2 de cada mes.
          </li>
          <li class="font">
            Índice de precio promedio de m2 por mes, tomando el total de precios
            efectivos en el mes dividido por la cantidad de m2 transaccionados.
          </li>
        </ul>
      </li>
      <li class="font">
        Se eliminaron distorsiones y sesgos provenientes de datos atípicos.
      </li>
      <li class="font">
        Finalmente, se elaboraron índices tomando como referencia departamentos
        de un ambiente, de dos ambientes y de tres ambientes, se calculó el
        precio promedio del m2 para cada mes, y se analizó su evolución. En
        algunos casos los resultados del mes de abril o mayo del 2020 fueron
        descartados en función del impacto concreto que la pandemia y las
        restricciones impuestas para su control provocaron en el mercado,
        paralizando casi por completo su actividad.
      </li>
    </ol>

    <p class="mobile">
      De esta manera se logró obtener datos precisos respecto a los valores del
      metro cuadrado de departamentos en la Ciudad Autónoma de Buenos Aires.
    </p>
    <h2 class="mobile" id="MobileReport">Reportes</h2>
    @if(options && options.length>0){
    <div class="dropdown-select mobile">
      <div class="icon" id="calendar"></div>
      <div class="border"></div>
      <mat-select
        [placeholder]="placeholder"
        (selectionChange)="loadReportsByYear($event.value)">
        @for (option of options; track option) {
        <mat-option [value]="option" class="mat-option-abm"
          >{{ option }}</mat-option
        >
        }
      </mat-select>
    </div>
    } @if(index && index.length>0){
    <ng-container>
      @for (item of index; track item.title) {
      <div class="index-container mobile">
        <a [href]="item.url" target="_blank">
          <h3 class="title">{{item.title}}</h3></a
        >
      </div>
      }
    </ng-container>
    } @else {
    <ng-template>
      <p class="no-data">
        No hay informes disponibles para el año seleccionado
      </p>
    </ng-template>
    }
  </div>
</div>
