<div class="container-login-user">
  <form [formGroup]="myFormGroup">
    <h2 class="subheading-03-bold">Ingresá tu email</h2>
    <div class="input-box">
      <mat-form-field class="qr-form-field" appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" name="email" />
        <mat-error>{{ getErrorMessage('email') }}</mat-error>
      </mat-form-field>
    </div>
    <div class="input-box">
      <mat-form-field class="qr-form-field" appearance="outline">
        <mat-label>Contraseña</mat-label>
        <input
          matInput
          formControlName="password"
          name="password"
          [type]="showPassword ? 'text' : 'password'" />
        <mat-error>{{ getErrorMessage('password') }}</mat-error>
        <div class="see-password">
          <qr-icon-button
            [icon]="showPassword ? 'eye-cross-out' : 'eye'"
            (click)="onSeePassword()"
            [config]="configButtonSeePassword"></qr-icon-button>
        </div>
      </mat-form-field>
    </div>
  </form>
  <div class="button-box">
    <qr-button
      [config]="this.configButtonContinue"
      (click)="onLogin()"></qr-button>
  </div>
  @if (loading) {
    <div class="loader-container">
      <mat-spinner diameter="24" color="#FFFFFF"></mat-spinner>
    </div>
  }
  @if (errorServiceLoginMessage) {
    <div class="text-error service-error">
      {{ this.errorServiceLoginMessage }}
    </div>
  }
  <p class="paragraph-01-regular">
    <a href="/olvide-contraseña" class="simple-link">Olvidé mi contraseña</a>
  </p>
  <div class="line"></div>
  <div class="social-media-login-box">
    <div
      class="button"
      matRipple
      (click)="onLoginFacebook()"
      aria-hidden="true">
      <qr-media-img [config]="iconFacebookLoginSVG"></qr-media-img>
      <span>Ingresar con Facebook</span>
    </div>
    <div class="button" matRipple (click)="onLoginGoogle()" aria-hidden="true">
      <qr-media-img [config]="iconGoogleLoginSVG"></qr-media-img>
      <span>Ingresar con Google</span>
    </div>
  </div>
  <p class="paragraph-01-regular">
    Si no tenés cuenta <a href="/registro">Regístrate acá</a>
  </p>
  <div class="msg-terms">
    <span>Al registrarme acepto los</span>
    <a href="/terminos-y-condiciones"> términos, condiciones</a>
    <span> y </span>
    <a href="/politica-de-privacidad">politicas de privacidad</a>
  </div>
</div>
