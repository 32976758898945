<div class="be-agent-container" fxFlex="100">
  <div id="background">
    <div id="title">
      <h1>Unite a la Red RE/MAX</h1>
      <h2>
        {{ node !== 'ec' ? 'Súmate a una oficina adherida a la Red más exitosa
        del mundo.' : 'Súmate a la red más exitosa del mundo'}}
      </h2>
    </div>
    <div id="img-container"></div>
  </div>
  <div id="section-container">
    <div class="item-section" (click)="toggleSections(1)">
      <div class="item-dropdown">
        <h3>¿Qué significa RE/MAX?</h3>
        <qr-icon-button
          [ngClass]="{ icon: this.isSectionOneOpen }"
          icon="arrow-drop-down"
          [config]="this.configButtonExpand">
        </qr-icon-button>
      </div>
    </div>
    <div class="sectionOpen" [ngClass]="{ open: isSectionOneOpen }">
      <h4>Real Estate Maximus.</h4>
      <ul>
        <li>
          <p>Máximos Resultados.</p>
        </li>
        <li>
          <p>Máxima Rentabilidad.</p>
        </li>
        <li>
          <p>Máxima Cooperación.</p>
        </li>
        <li>
          <p>Máxima Formación.</p>
        </li>
        <li>
          <p>Máximo Servicio al cliente.</p>
        </li>
      </ul>
      <h4>
        RE/MAX revolucionó el mercado inmobiliario con este concepto y cambió
        las reglas del sector inmobiliario tradicional.
      </h4>
    </div>
    <div class="item-section" (click)="toggleSections(2)">
      <div class="item-dropdown">
        <h3>¿Qué hace un Agente de una oficina RE/MAX?</h3>
        <qr-icon-button
          [ngClass]="{ icon: this.isSectionTwoOpen }"
          icon="arrow-drop-down"
          [config]="this.configButtonExpand">
        </qr-icon-button>
      </div>
    </div>
    <div class="sectionOpen" [ngClass]="{ open: isSectionTwoOpen }">
      <ul>
        @if (node !== 'ec') {
        <li>
          <p>Auxilia al Corredor Inmobiliario de cada oficina.</p>
        </li>
        }
        <li>
          <p>Estudia el mercado que trabaja el equipo.</p>
        </li>
        <li>
          <p>Clasifica los nuevos clientes del equipo.</p>
        </li>
        <li>
          <p>Elabora planes de Marketing para los inmuebles en cartera.</p>
        </li>
        <li>
          <p>
            {{node === 'ec' ? 'Da seguimiento a las necesidades de los
            clientes.' : 'Da seguimiento a las necesidades de los clientes,
            siempre supervisado por el Corredor Inmobiliario de la oficina.' }}
          </p>
        </li>
        <li>
          <p>
            {{node === 'ec' ? 'Brinda asistencia a los clientes para concretar
            la venta de los inmuebles en cartera.' : 'Brinda asistencia a los
            clientes para que el Corredor Inmobiliario pueda concretar la venta
            de los inmuebles en cartera.'}}
          </p>
        </li>
      </ul>
    </div>
    <div class="item-section" (click)="toggleSections(3)">
      <div class="item-dropdown">
        <h3>¿Con qué apoyo cuenta?</h3>
        <qr-icon-button
          [ngClass]="{ icon: this.isSectionThreeOpen }"
          icon="arrow-drop-down"
          [config]="this.configButtonExpand">
        </qr-icon-button>
      </div>
    </div>
    <div class="sectionOpen" [ngClass]="{ open: isSectionThreeOpen }">
      <div id="section-three">
        <p>
          Los Agentes RE/MAX cuentan con todos los beneficios de pertenecer a la
          red inmobiliaria líder en el país y en el mundo. Esto facilita su
          actividad, pudiendo en pocos meses alcanzar ingresos considerables.
        </p>
        <h4>Imagen de la marca y de la notoriedad</h4>
        <p>
          Una gran garantía de éxito es adherirse a una marca consolidada por
          más de 47 años que goza del mayor prestigio en el mercado
          internacional. La marca no sólo es el logotipo más reconocido del
          sector inmobiliario internacional, sino que posee una identidad
          asociada con una imagen de credibilidad, confianza y servicio que
          "abre puertas", facilitando el rol de los agentes a la hora de
          realizar sus tareas.
        </p>
        <h4>Campañas de Publicidad</h4>
        <p>Millones de impresiones de la marca en {{country}} y en el mundo.</p>
        <h4>Cooperación entre las Oficinas RE/MAX y Negocios Compartidos</h4>
        <p>
          RE/MAX brinda acceso a los clientes e inmuebles de toda la red RE/MAX,
          lo que abre la posibilidad de compartir negocios con todos los equipos
          RE/MAX a nivel local, nacional e internacional. Esta ventaja permite
          concretar más negocios que cualquier inmobiliaria tradicional.
        </p>
        <h4>Tecnología de Vanguardia</h4>
        <p>
          {{node === 'ec' ? 'Soporte de un software de gestión de clientes e
          inmuebles que permite tener actualizado el historial de la cartera de
          clientes, organizar actividades diarias y mostrar inmuebles sin salir
          de la oficina' : 'Soporte de un software de gestión de clientes e
          inmuebles que permite tener actualizado el historial de la cartera de
          clientes del Corredor Inmobiliario, organizar actividades diarias y
          mostrar inmuebles sin salir de la oficina.'}}
        </p>
      </div>
    </div>
    <div class="item-section" id="no-border" (click)="toggleSections(4)">
      <div class="item-dropdown">
        <h3>¿Cuáles son los beneficios de ser Agente de una oficina RE/MAX?</h3>
        <qr-icon-button
          [ngClass]="{ icon: this.isSectionFourOpen }"
          icon="arrow-drop-down"
          [config]="this.configButtonExpand">
        </qr-icon-button>
      </div>
    </div>
    <div class="sectionOpen" [ngClass]="{ open: isSectionFourOpen }">
      <ul>
        @if (node !== 'ec') {
        <li>
          <p>
            Liderazgo y supervisión de un Corredor Inmobiliario matriculado,
            responsable de cada oficina.
          </p>
        </li>
        }
        <li>
          <p>Servicios prestados por una Empresa Líder Mundial.</p>
        </li>
        <li>
          <p>Marca Global.</p>
        </li>
        <li>
          <p>Tecnología.</p>
        </li>
        <li>
          <p>Formación Continua.</p>
        </li>
        <li>
          <p>Realización personal.</p>
        </li>
        <li>
          <p>
            Oportunidad única de relacionarse con otros emprendedores en un
            clima de cordialidad.
          </p>
        </li>
        <li>
          <p>Independencia y flexibilidad de horarios.</p>
        </li>
      </ul>
      <p>
        En la red RE/MAX el único límite es tu capacidad de emprender. La
        Formación RE/MAX te permite alcanzar tus objetivos y convertirte en
        líder. Sentí el orgullo de tener algo propio, construido con tu éxito.
        La realización personal está a tu alcance. ¡Sumate a los mejores!
      </p>
      <p class="mt">
        En {{node === 'ec' ? country : 'Argentina y Uruguay'}}, miles de Agentes
        de la Red RE/MAX son parte de este proyecto exitoso. ¡Sumate a RE/MAX!
      </p>
    </div>
  </div>
  <div id="sub-title">
    <h1>
      {{node !== 'ec' ? 'Da el primer paso y contanos sobre vos' : 'Da el primer
      paso y cuéntanos sobre ti'}}
    </h1>
  </div>

  <div id="section-form">
    <div id="img-form"></div>

    <div id="form" class="be-agent-wrapper">
      <!-- Formulario -->
      @if (!isFormCorrectlySent) {
      <div class="be-agent-wrapper__form-container">
        <form
          [formGroup]="form"
          (ngSubmit)="onSubmitForm()"
          class="be-agent-form">
          <!-- Nombre y apellido -->
          <mat-form-field class="be-agent-form__field" appearance="outline">
            <mat-label>Nombre y apellido</mat-label>
            <input
              matInput
              formControlName="fullName"
              required
              name="fullName" />
            @if (fullName.invalid) {
            <mat-error>{{getErrorMessage('fullName')}}</mat-error>
            }
          </mat-form-field>
          <!-- Email -->
          <mat-form-field class="be-agent-form__field" appearance="outline">
            <mat-label>Email</mat-label>
            <input
              type="email"
              matInput
              formControlName="email"
              required
              name="email" />
            @if (email.invalid) {
            <mat-error>{{getErrorMessage('email')}}</mat-error>
            }
          </mat-form-field>
          <!-- Teléfono -->
          <mat-form-field class="be-agent-form__field" appearance="outline">
            <mat-label>Teléfono</mat-label>
            <input
              type="tel"
              matInput
              formControlName="phone"
              required
              name="phone" />
            @if (phone.invalid) {
            <mat-error>{{getErrorMessage('phone')}}</mat-error>
            }
          </mat-form-field>
          <!-- Ubicación -->
          <!-- Abro contenedor Ubicación -->
          @defer( on idle ){
          <qr-google-autocomplete
            class="google-autocomplete"
            formControlName="ubication"
            placeholder="Dirección"
            [required]="true"
            (onChange)="selectUbication($event)"
            [errorText]="getErrorMessage('ubication')"></qr-google-autocomplete>
          }
          <!-- Experiencia -->
          <mat-form-field class="be-agent-form__field" appearance="outline">
            <mat-label>Experiencia</mat-label>
            <textarea
              matInput
              required
              formControlName="experience"
              name="experience"
              id=""
              cols="30"
              rows="10"></textarea>
            @if (experience.invalid) {
            <mat-error>{{getErrorMessage('experience')}}</mat-error>
            }
          </mat-form-field>
          <div id="text">
            <p>
              Capacitaciones, cursos, idiomas, conocimientos en informática y
              experiencia en el sector inmobiliario
            </p>
          </div>
          <!-- Como nos conociste -->
          <mat-form-field class="be-agent-form__field" appearance="outline">
            <mat-label>¿Cómo nos conociste?</mat-label>
            <mat-select
              formControlName="howYouKnownUs"
              placeholder="¿Cómo nos conociste?"
              required>
              @for (pType of beAgentSelect; track pType) {
              <mat-option [value]="pType"> {{ pType.value }} </mat-option>
              }
            </mat-select>
            @if (howYouKnownUs.invalid) {
            <mat-error>{{getErrorMessage('howYouKnownUs')}}</mat-error>
            }
          </mat-form-field>
          <!-- Botón: Enviar -->
          <div class="be-agent-form__button-container">
            <qr-button [config]="this.configButtonSend"></qr-button>
          </div>
          <!-- Disclaimer: Terminos y condiciones -->
          <div class="be-agent-form__terms-and-conditions">
            <p>
              Al enviar se están aceptando los
              <a href="/terminos-y-condiciones" target="blank"
                >Términos y Condiciones de Uso</a
              >
              y la
              <a href="/politica-de-privacidad" target="blank"
                >Política de Privacidad</a
              >
            </p>
          </div>
        </form>
      </div>
      } @else {
      <!-- Formulario: enviado correctamente -->
      <div class="be-agent-wrapper__form-sent">
        <div class="be-agent-wrapper__check">
          <div class="be-agent-wrapper__img"></div>
        </div>
        <p>
          Información enviada correctamente un agente se pondrá en contacto con
          vos para continuar con el proceso
        </p>
      </div>
      }
    </div>
  </div>
  <div id="disclaimer">
    @if (node !== 'ec') {
    <span>
      Para ejercer la actividad inmobiliaria se debe cumplir con los
      requerimientos de la ley de corretaje dentro de su jurisdicción. Todas las
      operaciones son objeto de intermediación y conclusión por un corredor
      matriculado. Los Agentes no pueden realizar los actos que, por
      disposiciones legales, se encuentran reservados exclusivamente a los
      corredores públicos matriculados. Cada Oficina es de gestión y propiedad
      independiente.
    </span>
    }
  </div>
</div>
