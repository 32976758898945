import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  DOCUMENT,
  Location,
  isPlatformBrowser,
  isPlatformServer,
} from '@angular/common';
import { environment } from '@base/environments/environment';
import { Constants } from '@base/src/app/core/constants/constants';
@Injectable({ providedIn: 'root' })
export class SEOUtilsService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private location: Location,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  setCanonicalTag(): void {
    let countryDomain = '';
    switch (environment.node) {
      case Constants.NODE_URUGUAY:
        countryDomain = 'uy';
        break;
      case Constants.NODE_ECUADOR:
        countryDomain = 'ec';
        break;
      default:
        countryDomain = 'ar';
        break;
    }
    const URL = 'https://www.remax.com.' + countryDomain + this.location.path();
    if (isPlatformServer(this.platformId)) {
      const head = this.document.getElementsByTagName('head')[0];
      let element: HTMLLinkElement | null =
        this.document.querySelector(`link[rel='canonical']`) || null;
      if (element == null) {
        element = this.document.createElement('link') as HTMLLinkElement;
        head.appendChild(element);
      }
      element.setAttribute('rel', 'canonical');
      element.setAttribute('href', URL);
    } else if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        const existingLink = document.querySelector('link[rel="canonical"]');
        if (existingLink) {
          existingLink.setAttribute('href', URL);
        }
      }, 1000); // Con este timeout lo actualiza
    }
  }
}
