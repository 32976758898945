<!-- MOBILE VERSION  -->

@if (this.totalPages > 1) {
  <div id="container" class="flex-row hide-gt-sm">
    @if (this.actualPage !== 0) {
      <div class="flex-center-center arrow-select">
        <button matRipple aria-label="Preview Page">
          <div
            class="first-arrow"
            aria-hidden="true"
            (click)="previousPage()"></div>
        </button>
      </div>
    }
    <div class="flex-center-center flex-row p-container-paginator">
      <p>
        Página
        <strong> {{ this.actualPage + 1 }} </strong> de
        {{ this.totalPages }}
      </p>
    </div>
    @if (this.actualPage !== this.totalPages - 1) {
      <div class="arrow-select flex-center-center">
        <button matRipple (click)="nextPage()" aria-label="Next Page">
          <div class="last-arrow"></div>
        </button>
      </div>
    }
  </div>
}

<!-- DESKTOP VERSION  -->
@if (this.totalPages > 1) {
  <div id="container" class="flex-row hide-lt-md">
    @if (this.actualPage !== 0) {
      <div class="flex-center-center arrow-select mr-8">
        <button matRipple (click)="previousPage()" aria-label="Preview Page">
          <div class="first-arrow"></div>
        </button>
      </div>
    }
    @for (p of pages; track p; let i = $index) {
      <div>
        <p
          class="number flex-center-center"
          matRipple
          aria-hidden="true"
          [ngClass]="{ selected: p === this.actualPage }"
          (click)="onPageChange(p)">
          {{ p + 1 }}
        </p>
      </div>
    }
    @if (this.actualPage !== this.totalPages - 1) {
      <div class="arrow-select flex-center-center">
        <button matRipple (click)="nextPage()" aria-label="Next Page">
          <div class="last-arrow"></div>
        </button>
      </div>
    }
  </div>
}
