import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'qr-multimedia-buttons',
  templateUrl: './qr-multimedia-buttons.component.html',
  styleUrls: ['./qr-multimedia-buttons.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class QrMultimediaButtonsComponent {
  @Input() style: {
    photo: string;
    video: string;
    p360: string;
    tour: string;
  } = {
    photo: 'selected',
    video: 'unselected',
    p360: 'unselected',
    tour: 'unselected',
  };

  @Output() onselect = new EventEmitter<string>();

  photoSelected() {
    if (this.style.photo != 'disabled') {
      for (const key of Object.keys(this.style) as Array<
        keyof typeof this.style
      >) {
        if (this.style[key] == 'selected') {
          this.style[key] = 'unselected';
        }
      }
      this.style.photo = 'selected';
      this.onselect.emit('photo');
    }
  }

  videoSelected() {
    if (this.style.video != 'disabled') {
      for (const key of Object.keys(this.style) as Array<
        keyof typeof this.style
      >) {
        if (this.style[key] == 'selected') {
          this.style[key] = 'unselected';
        }
      }
      this.style.video = 'selected';
      this.onselect.emit('video');
    }
  }

  p360Selected() {
    if (this.style.p360 != 'disabled') {
      this.onselect.emit('360');
    }
  }

  tourSelected() {
    if (this.style.tour != 'disabled') {
      for (const key of Object.keys(this.style) as Array<
        keyof typeof this.style
      >) {
        if (this.style[key] == 'selected') {
          this.style[key] = 'unselected';
        }
      }
      this.style.tour = 'selected';
      this.onselect.emit('tour');
      this.style.tour = 'unselected';
      this.style.photo = 'selected';
      this.onselect.emit('photo');
    }
  }
}
