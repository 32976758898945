export class BreadcrumbURLs {
  base?: string; // Sin ningún filtro
  operationType?: string; // Tipo de operación
  propertyType?: string; // Tipo de propiedad
  rootLabel?: string; // Ubicación (RootLabel/(Subregion/State))
  county?: string; // Ubicación (Countie/Condado/Partido)
  city?: string; // Ubicación (Citie/Ciudad)
  neighborhood?: string; // Ubicación (Neighborhood/Barrio)
  privateCommunity?: string; // Ubicación (privatecommunitie/Barrio privado)
}
