export class QRBanner {
  // Alto de la imagen (Es obligatorio para la performance de la web)
  public static CLASSNAME_BANNER_LENDAR = 'banner__border--lendar';
  public static CLASSNAME_BANNER_GARANTOR = 'banner__border--garantor';
  docFolder: string | undefined;
  // Nombre de la carpeta EJ: home/webp
  docName: string | undefined; // Nombre del archivo EJ banner-lendar
  docType: string | undefined; // Tipo de documento, puede ser WEBP o GIF
  text: string | undefined; // Texto descriptivo del banner a utilizar EJ: Publicidad
  link: string | undefined; // Link de URL de destino EJ: https://lendar.com.ar
  alt: string | undefined; // Texto alternativo del banner. EJ: Banner Lendar
  className?: string | undefined; // Clase propia del banner. EJ: c-banner--lendar
  width?: string | undefined; // Ancho de la imagen (Es obligatorio para la performance de la web)
  height?: string | undefined;
}
