<div id="qr-card-details-emprendimientos-item-container">
  <div class="desktop-container">
    <div class="photo-container">
      <picture-source
        [config]="entrepreneurshipResizedImages"
        [src]="resizedImageWebp"
        alt="Foto"></picture-source>
    </div>

    <div class="title-item">
      <p class="subheading-03-regular grey-nero" matTooltip="{{ unit.title }}">
        {{ utils.truncateString(unit.title, 15) }}
      </p>

      @if (!unit.priceExposure) {
        <p class="heading-03-bold grey-nero">
          {{ 'listing-detail.request-price' | translate: locale.language }}
        </p>
      } @else {
        <p class="heading-03-bold grey-nero">
          {{ unit.price | qrNumber }} {{ unit.currency.value }}
        </p>
      }
    </div>
  </div>

  <div class="features-container">
    @for (item of features; track item) {
      <div class="item-container">
        <div class="icon-container">
          <div class="icon" [id]="item.icon"></div>
        </div>
        <p>
          <span class="subheading-03-bold grey-nero">{{ item.quantity }}</span>
          <span class="subheading-03-regular grey-nero">{{ item.name }}</span>
        </p>
      </div>
    }
  </div>

  <div class="button-see-more">
    <qr-button [config]="buttonSeeMore" (eventClick)="onOpenUnit()"></qr-button>
  </div>
</div>
