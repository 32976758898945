import { Component, OnInit } from '@angular/core';
import { Constants } from '@base/src/app/core/constants/constants';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { filter } from 'rxjs/operators';
import { ContactsBeAgentService } from '@app/services/contacts-be-agent.service';
import { environment } from '@base/environments/environment';
import { UtilsService } from '@app/services/utils.service';
import { IQrResponse } from '@app/core/models/IQrResponse';
import { QrButtonComponent } from '@app/components/qr-button/qr-button.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { QrIconButtonComponent } from '@app/components/qr-icon-button/qr-icon-button.component';
import { QrGoogleAutocompleteComponent } from '@app/components/qr-google-autocomplete/qr-google-autocomplete.component';
import { ContactBeAgent } from '@base/src/app/core/models/contact-be-agent.model';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-be-agent',
  templateUrl: './detail.page.html',
  styleUrls: ['./detail.page.scss'],
  standalone: true,
  imports: [
    QrButtonComponent,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    CommonModule,
    ReactiveFormsModule,
    QrIconButtonComponent,
    QrGoogleAutocompleteComponent,
    MatCheckboxModule,
    MatInputModule,
  ],
})
export class BeAgentPage implements OnInit {
  configButtonExpand = {
    style: Constants.BUTTON_COLOR_GREY_BASIC,
    height: Constants.BUTTON_HEIGHT_48PX,
  };

  isSectionOneOpen = false;
  isSectionTwoOpen = false;
  isSectionThreeOpen = false;
  isSectionFourOpen = false;

  isFormCorrectlySent = false;

  form: FormGroup;
  fullName = new FormControl('', Validators.required);
  email = new FormControl('', [Validators.required, Validators.email]);
  phone = new FormControl('', [Validators.required]);
  ubication = new FormControl('', Validators.required);
  experience = new FormControl('', Validators.required);
  howYouKnownUs = new FormControl('', Validators.required);

  // Botón: Enviar
  configButtonSend = {
    style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
    height: Constants.BUTTON_HEIGHT_48PX,
    text: 'Enviar',
    isDisabled: true,
  };

  beAgentSelect: any[] = [];

  node = environment.node;
  country = '';

  constructor(
    fb: FormBuilder,
    private contactsBeAgentService: ContactsBeAgentService,
    private utils: UtilsService
  ) {
    this.form = fb.group({
      fullName: this.fullName,
      email: this.email,
      phone: this.phone,
      ubication: this.ubication,
      experience: this.experience,
      howYouKnownUs: this.howYouKnownUs,
    });
  }

  ngOnInit() {
    switch (this.node) {
      case Constants.NODE_ARGENTINA:
        this.country = 'Argentina';
        break;
      case Constants.NODE_URUGUAY:
        this.country = 'Uruguay';
        break;
      case Constants.NODE_ECUADOR:
        this.country = 'Ecuador';
        break;

      default:
        this.country = 'Argentina';
        break;
    }

    const title = 'Unite a la Red RE/MAX';
    const description =
      'Súmate a una oficina adherida a la Red más exitosa del mundo.';

    this.utils.setPageMetaTags(title, description);

    this.beAgentSelect = Constants.BE_AGENT_SELECT;
    this.form.statusChanges
      .pipe(
        filter((status: string) => {
          status == 'VALID'
            ? (this.configButtonSend.isDisabled = false)
            : (this.configButtonSend.isDisabled = true);
          return this.form.valid;
        })
      )
      .subscribe();
  }

  getClassRegion(): string {
    return environment.node;
  }

  getErrorMessage(field: string): string {
    let errorMsg = '';
    switch (field) {
      case 'fullName':
        if (this.fullName.hasError('required'))
          errorMsg = 'Ingrese un nombre y apellido';
        break;
      case 'email':
        if (this.email.hasError('required'))
          errorMsg = 'Ingrese un email valido';
        if (this.email.hasError('email'))
          errorMsg = 'Ingrese un email con formato valido';
        break;
      case 'phone':
        if (this.phone.hasError('required')) errorMsg = 'Ingrese un teléfono';
        break;
      case 'ubication':
        if (this.ubication.hasError('required'))
          errorMsg = 'Ingrese una dirección';
        break;
      case 'propertyType':
        if (this.howYouKnownUs.hasError('required'))
          errorMsg = 'Seleccione cómo nos conociste';
        break;
      case 'rooms':
        if (this.experience.hasError('required'))
          errorMsg = 'Ingrese una experiencia';
        break;
      default:
        errorMsg = 'error';
        break;
    }
    return errorMsg;
  }

  toggleSections(id: number): void {
    switch (id) {
      case 1:
        this.isSectionOneOpen = !this.isSectionOneOpen;
        break;
      case 2:
        this.isSectionTwoOpen = !this.isSectionTwoOpen;
        break;
      case 3:
        this.isSectionThreeOpen = !this.isSectionThreeOpen;
        break;
      case 4:
        this.isSectionFourOpen = !this.isSectionFourOpen;
        break;
      default:
        break;
    }
  }

  async onSubmitForm(): Promise<void> {
    try {
      const contactBeAgent: ContactBeAgent = new ContactBeAgent();

      contactBeAgent.name = this.form.get('fullName')?.value;
      contactBeAgent.phone = this.form.get('phone')?.value;
      contactBeAgent.email = this.form.get('email')?.value;
      contactBeAgent.ubication = this.form.get('ubication')?.value;
      contactBeAgent.howYouKnownUs =
        this.form.get('howYouKnownUs')?.value.value;
      contactBeAgent.experience = this.form.get('experience')?.value;
      const result: IQrResponse<any> =
        await this.contactsBeAgentService.addGet(contactBeAgent);

      if (result.code == 200) {
        this.isFormCorrectlySent = true;
      } else {
        alert('contactBeAgent.onSubmit: ERROR ' + result.message);
      }
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    } catch (error) {
      alert('contactBeAgent.onSubmit: ERROR ' + error);
    }
  }

  selectUbication(value: any): void {
    console.log('selectUbication', value);
  }
}
