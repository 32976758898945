import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class Countries {
  list = [
    {
      country_code: 'al',
      country_en: 'Albania',
      phone_code: 355,
    },
    {
      country_code: 'dz',
      country_en: 'Algeria',
      phone_code: 213,
    },
    {
      country_code: 'af',
      country_en: 'Afghanistan',
      phone_code: 93,
    },
    {
      country_code: 'ar',
      country_en: 'Argentina',
      phone_code: 54,
    },
    {
      country_code: 'ae',
      country_en: 'Emiratos Arabes',
      phone_code: 971,
    },
    {
      country_code: 'aw',
      country_en: 'Aruba',
      phone_code: 297,
    },
    {
      country_code: 'om',
      country_en: 'Omán',
      phone_code: 968,
    },
    {
      country_code: 'az',
      country_en: 'Azerbaijan',
      phone_code: 994,
    },
    {
      country_code: 'eg',
      country_en: 'Egipto',
      phone_code: 20,
    },
    {
      country_code: 'et',
      country_en: 'Etiopia',
      phone_code: 251,
    },
    {
      country_code: 'ie',
      country_en: 'Irlanda',
      phone_code: 353,
    },
    {
      country_code: 'ee',
      country_en: 'Estonia',
      phone_code: 372,
    },
    {
      country_code: 'ad',
      country_en: 'Andorra',
      phone_code: 376,
    },
    {
      country_code: 'ao',
      country_en: 'Angola',
      phone_code: 244,
    },
    {
      country_code: 'ai',
      country_en: 'Anguilla',
      phone_code: 1264,
    },
    {
      country_code: 'ag',
      country_en: 'Antigua',
      phone_code: 1268,
    },
    {
      country_code: 'at',
      country_en: 'Austria',
      phone_code: 43,
    },
    {
      country_code: 'au',
      country_en: 'Australia',
      phone_code: 61,
    },
    {
      country_code: 'mo',
      country_en: 'Macau',
      phone_code: 853,
    },
    {
      country_code: 'bb',
      country_en: 'Barbados',
      phone_code: 1246,
    },
    {
      country_code: 'pg',
      country_en: 'Papua Nueva Guinea',
      phone_code: 675,
    },
    {
      country_code: 'bs',
      country_en: 'Bahamas',
      phone_code: 1242,
    },
    {
      country_code: 'pk',
      country_en: 'Pakistan',
      phone_code: 92,
    },
    {
      country_code: 'py',
      country_en: 'Paraguay',
      phone_code: 595,
    },
    {
      country_code: 'ps',
      country_en: 'Palestina',
      phone_code: 970,
    },
    {
      country_code: 'bh',
      country_en: 'Bahrein',
      phone_code: 973,
    },
    {
      country_code: 'pa',
      country_en: 'Panama',
      phone_code: 507,
    },
    {
      country_code: 'br',
      country_en: 'Brasil',
      phone_code: 55,
    },
    {
      country_code: 'by',
      country_en: 'Bielorrusia',
      phone_code: 375,
    },
    {
      country_code: 'bm',
      country_en: 'Bermuda',
      phone_code: 1441,
    },
    {
      country_code: 'bg',
      country_en: 'Bulgaria',
      phone_code: 359,
    },
    {
      country_code: 'mp',
      country_en: 'Islas Mariana del Norte',
      phone_code: 1670,
    },
    {
      country_code: 'bj',
      country_en: 'Benin',
      phone_code: 229,
    },
    {
      country_code: 'be',
      country_en: 'Belgica',
      phone_code: 32,
    },
    {
      country_code: 'is',
      country_en: 'Islandia',
      phone_code: 354,
    },
    {
      country_code: 'pr',
      country_en: 'Puerto Rico',
      phone_code: 1,
    },
    {
      country_code: 'pl',
      country_en: 'Polonia',
      phone_code: 48,
    },
    {
      country_code: 'ba',
      country_en: 'Bosnia y Herzegovina',
      phone_code: 387,
    },
    {
      country_code: 'bo',
      country_en: 'Bolivia',
      phone_code: 591,
    },
    {
      country_code: 'bz',
      country_en: 'Belice',
      phone_code: 501,
    },
    {
      country_code: 'pw',
      country_en: 'Palau',
      phone_code: 680,
    },
    {
      country_code: 'bw',
      country_en: 'Botswana',
      phone_code: 267,
    },
    {
      country_code: 'bt',
      country_en: 'Bhutan',
      phone_code: 975,
    },
    {
      country_code: 'bf',
      country_en: 'Burkina Faso',
      phone_code: 226,
    },
    {
      country_code: 'bi',
      country_en: 'Burundi',
      phone_code: 257,
    },
    {
      country_code: 'kp',
      country_en: 'Corea del Norte',
      phone_code: 850,
    },
    {
      country_code: 'gq',
      country_en: 'Guinea Ecuatorial',
      phone_code: 240,
    },
    {
      country_code: 'dk',
      country_en: 'Dinamarca',
      phone_code: 45,
    },
    {
      country_code: 'de',
      country_en: 'Alemania',
      phone_code: 49,
    },
    {
      country_code: 'tl',
      country_en: 'Timor Oriental',
      phone_code: 670,
    },
    {
      country_code: 'tg',
      country_en: 'Togo',
      phone_code: 228,
    },
    {
      country_code: 'dm',
      country_en: 'Dominica',
      phone_code: 1767,
    },
    {
      country_code: 'do',
      country_en: 'República Dominicana',
      phone_code: 1809,
    },
    {
      country_code: 'ru',
      country_en: 'Rusia',
      phone_code: 7,
    },
    {
      country_code: 'ec',
      country_en: 'Ecuador',
      phone_code: 593,
    },
    {
      country_code: 'er',
      country_en: 'Eritrea',
      phone_code: 291,
    },
    {
      country_code: 'fr',
      country_en: 'Francia',
      phone_code: 33,
    },
    {
      country_code: 'fo',
      country_en: 'Islas Faroe',
      phone_code: 298,
    },
    {
      country_code: 'pf',
      country_en: 'Polinesia Francesa',
      phone_code: 689,
    },
    {
      country_code: 'gf',
      country_en: 'Guayana Francesa',
      phone_code: 594,
    },
    {
      country_code: 'pm',
      country_en: 'San Pedro y Miquelon',
      phone_code: 508,
    },
    {
      country_code: 'va',
      country_en: 'Ciudad del Vaticano',
      phone_code: 39,
    },
    {
      country_code: 'ph',
      country_en: 'Filipinas',
      phone_code: 63,
    },
    {
      country_code: 'fj',
      country_en: 'Fiji',
      phone_code: 679,
    },
    {
      country_code: 'fi',
      country_en: 'Finlandia',
      phone_code: 358,
    },
    {
      country_code: 'cv',
      country_en: 'Cabo Verde',
      phone_code: 238,
    },
    {
      country_code: 'fk',
      country_en: 'Islas Malvinas',
      phone_code: 500,
    },
    {
      country_code: 'gm',
      country_en: 'Gambia',
      phone_code: 220,
    },
    {
      country_code: 'cg',
      country_en: 'Republica del Congo',
      phone_code: 242,
    },
    {
      country_code: 'cd',
      country_en: 'Republica Democratica del Congo',
      phone_code: 243,
    },
    {
      country_code: 'co',
      country_en: 'Colombia',
      phone_code: 57,
    },
    {
      country_code: 'cr',
      country_en: 'Costa Rica',
      phone_code: 506,
    },
    {
      country_code: 'gd',
      country_en: 'Granada',
      phone_code: 1473,
    },
    {
      country_code: 'gl',
      country_en: 'Groenlandia',
      phone_code: 299,
    },
    {
      country_code: 'ge',
      country_en: 'Georgia',
      phone_code: 995,
    },
    {
      country_code: 'gg',
      country_en: 'Guernsey',
      phone_code: 44,
    },
    {
      country_code: 'cu',
      country_en: 'Cuba',
      phone_code: 53,
    },
    {
      country_code: 'gp',
      country_en: 'Guadalupe',
      phone_code: 590,
    },
    {
      country_code: 'gu',
      country_en: 'Guam',
      phone_code: 1671,
    },
    {
      country_code: 'gy',
      country_en: 'Guyana',
      phone_code: 592,
    },
    {
      country_code: 'kz',
      country_en: 'Kazajistan',
      phone_code: 7,
    },
    {
      country_code: 'ht',
      country_en: 'Haiti',
      phone_code: 509,
    },
    {
      country_code: 'kr',
      country_en: 'Corea del Sur',
      phone_code: 82,
    },
    {
      country_code: 'nl',
      country_en: 'Holanda',
      phone_code: 31,
    },
    {
      country_code: 'bq',
      country_en: 'Caribe Neerlandes',
      phone_code: 599,
    },
    {
      country_code: 'me',
      country_en: 'Montenegro',
      phone_code: 382,
    },
    {
      country_code: 'hn',
      country_en: 'Honduras',
      phone_code: 504,
    },
    {
      country_code: 'ki',
      country_en: 'Kiribati',
      phone_code: 686,
    },
    {
      country_code: 'dj',
      country_en: 'Yibuti',
      phone_code: 253,
    },
    {
      country_code: 'kg',
      country_en: 'Kirguistan',
      phone_code: 996,
    },
    {
      country_code: 'gn',
      country_en: 'Guinea',
      phone_code: 224,
    },
    {
      country_code: 'gw',
      country_en: 'Guinea-Bissau',
      phone_code: 245,
    },
    {
      country_code: 'ca',
      country_en: 'Canada',
      phone_code: 1,
    },
    {
      country_code: 'gh',
      country_en: 'Ghana',
      phone_code: 233,
    },
    {
      country_code: 'ga',
      country_en: 'Gabon',
      phone_code: 241,
    },
    {
      country_code: 'kh',
      country_en: 'Camboya',
      phone_code: 855,
    },
    {
      country_code: 'cz',
      country_en: 'Republica Checa',
      phone_code: 420,
    },
    {
      country_code: 'zw',
      country_en: 'Zimbabue',
      phone_code: 263,
    },
    {
      country_code: 'cm',
      country_en: 'Camerun',
      phone_code: 237,
    },
    {
      country_code: 'qa',
      country_en: 'Qatar',
      phone_code: 974,
    },
    {
      country_code: 'ky',
      country_en: 'Islas Caiman',
      phone_code: 1345,
    },
    {
      country_code: 'km',
      country_en: 'Comoras',
      phone_code: 269,
    },
    {
      country_code: 'xk',
      country_en: 'Kosovo',
      phone_code: 381,
    },
    {
      country_code: 'ci',
      country_en: 'Costa de Marfil',
      phone_code: 225,
    },
    {
      country_code: 'kw',
      country_en: 'Kuwait',
      phone_code: 965,
    },
    {
      country_code: 'hr',
      country_en: 'Croacia',
      phone_code: 385,
    },
    {
      country_code: 'ke',
      country_en: 'Kenia',
      phone_code: 254,
    },
    {
      country_code: 'ck',
      country_en: 'Islas Cook',
      phone_code: 682,
    },
    {
      country_code: 'cw',
      country_en: 'Curazao',
      phone_code: 599,
    },
    {
      country_code: 'lv',
      country_en: 'Letonia',
      phone_code: 371,
    },
    {
      country_code: 'ls',
      country_en: 'Lesoto',
      phone_code: 266,
    },
    {
      country_code: 'la',
      country_en: 'Laos',
      phone_code: 856,
    },
    {
      country_code: 'lb',
      country_en: 'Libano',
      phone_code: 961,
    },
    {
      country_code: 'lt',
      country_en: 'Lituania',
      phone_code: 370,
    },
    {
      country_code: 'lr',
      country_en: 'Liberia',
      phone_code: 231,
    },
    {
      country_code: 'ly',
      country_en: 'Libia',
      phone_code: 218,
    },
    {
      country_code: 'li',
      country_en: 'Liechtenstein',
      phone_code: 423,
    },
    {
      country_code: 're',
      country_en: 'Reunion',
      phone_code: 262,
    },
    {
      country_code: 'lu',
      country_en: 'Luxemburgo',
      phone_code: 352,
    },
    {
      country_code: 'rw',
      country_en: 'Ruanda',
      phone_code: 250,
    },
    {
      country_code: 'ro',
      country_en: 'Rumania',
      phone_code: 40,
    },
    {
      country_code: 'mg',
      country_en: 'Madagascar',
      phone_code: 261,
    },
    {
      country_code: 'im',
      country_en: 'Isle Of Man',
      phone_code: 44,
    },
    {
      country_code: 'mv',
      country_en: 'Maldivas',
      phone_code: 960,
    },
    {
      country_code: 'mt',
      country_en: 'Malta',
      phone_code: 356,
    },
    {
      country_code: 'mw',
      country_en: 'Malaui',
      phone_code: 265,
    },
    {
      country_code: 'my',
      country_en: 'Malasia',
      phone_code: 60,
    },
    {
      country_code: 'ml',
      country_en: 'Mali',
      phone_code: 223,
    },
    {
      country_code: 'mk',
      country_en: 'Macedonia',
      phone_code: 389,
    },
    {
      country_code: 'mh',
      country_en: 'Islas Marshall',
      phone_code: 692,
    },
    {
      country_code: 'mq',
      country_en: 'Martinica',
      phone_code: 596,
    },
    {
      country_code: 'yt',
      country_en: 'Mayotte',
      phone_code: 262,
    },
    {
      country_code: 'mu',
      country_en: 'Mauricio',
      phone_code: 230,
    },
    {
      country_code: 'mr',
      country_en: 'Mauritania',
      phone_code: 222,
    },
    {
      country_code: 'us',
      country_en: 'Estados Unidos',
      phone_code: 1,
    },
    {
      country_code: 'as',
      country_en: 'Samoa Americana',
      phone_code: 1684,
    },
    {
      country_code: 'vi',
      country_en: 'Islas Virgenes US',
      phone_code: 1340,
    },
    {
      country_code: 'mn',
      country_en: 'Mongolia',
      phone_code: 976,
    },
    {
      country_code: 'ms',
      country_en: 'Montserrat',
      phone_code: 1664,
    },
    {
      country_code: 'bd',
      country_en: 'Bangladesh',
      phone_code: 880,
    },
    {
      country_code: 'pe',
      country_en: 'Peru',
      phone_code: 51,
    },
    {
      country_code: 'fm',
      country_en: 'Estados Federados de Micronesia',
      phone_code: 691,
    },
    {
      country_code: 'mm',
      country_en: 'Birmania',
      phone_code: 95,
    },
    {
      country_code: 'md',
      country_en: 'Moldavia',
      phone_code: 373,
    },
    {
      country_code: 'ma',
      country_en: 'Marruecos',
      phone_code: 212,
    },
    {
      country_code: 'mc',
      country_en: 'Monaco',
      phone_code: 377,
    },
    {
      country_code: 'mz',
      country_en: 'Mozambique',
      phone_code: 258,
    },
    {
      country_code: 'mx',
      country_en: 'Mexico',
      phone_code: 52,
    },
    {
      country_code: 'na',
      country_en: 'Namibia',
      phone_code: 264,
    },
    {
      country_code: 'za',
      country_en: 'Sudafrica',
      phone_code: 27,
    },
    {
      country_code: 'ss',
      country_en: 'Sudan del Sur',
      phone_code: 211,
    },
    {
      country_code: 'nr',
      country_en: 'Nauru',
      phone_code: 674,
    },
    {
      country_code: 'ni',
      country_en: 'Nicaragua',
      phone_code: 505,
    },
    {
      country_code: 'np',
      country_en: 'Nepal',
      phone_code: 977,
    },
    {
      country_code: 'ne',
      country_en: 'Niger',
      phone_code: 227,
    },
    {
      country_code: 'ng',
      country_en: 'Nigeria',
      phone_code: 234,
    },
    {
      country_code: 'nu',
      country_en: 'Niue',
      phone_code: 683,
    },
    {
      country_code: 'no',
      country_en: 'Noruega',
      phone_code: 47,
    },
    {
      country_code: 'nf',
      country_en: 'Isla Norfolk',
      phone_code: 672,
    },
    {
      country_code: 'pt',
      country_en: 'Portugal',
      phone_code: 351,
    },
    {
      country_code: 'jp',
      country_en: 'Japon',
      phone_code: 81,
    },
    {
      country_code: 'se',
      country_en: 'Suecia',
      phone_code: 46,
    },
    {
      country_code: 'ch',
      country_en: 'Suiza',
      phone_code: 41,
    },
    {
      country_code: 'sv',
      country_en: 'El Salvador',
      phone_code: 503,
    },
    {
      country_code: 'ws',
      country_en: 'Samoa',
      phone_code: 685,
    },
    {
      country_code: 'rs',
      country_en: 'Serbia',
      phone_code: 381,
    },
    {
      country_code: 'sl',
      country_en: 'Sierra Leona',
      phone_code: 232,
    },
    {
      country_code: 'sn',
      country_en: 'Senegal',
      phone_code: 221,
    },
    {
      country_code: 'cy',
      country_en: 'Chipre',
      phone_code: 357,
    },
    {
      country_code: 'sc',
      country_en: 'Islas Seychelles',
      phone_code: 248,
    },
    {
      country_code: 'sa',
      country_en: 'Arabia Saudita',
      phone_code: 966,
    },
    {
      country_code: 'bl',
      country_en: 'San Bartolome',
      phone_code: 590,
    },
    {
      country_code: 'st',
      country_en: 'Sao Tome and Principe',
      phone_code: 239,
    },
    {
      country_code: 'sh',
      country_en: 'Santa Helena',
      phone_code: 290,
    },
    {
      country_code: 'kn',
      country_en: 'San Cristobal y Nieves',
      phone_code: 1869,
    },
    {
      country_code: 'lc',
      country_en: 'Saint Lucia',
      phone_code: 1758,
    },
    {
      country_code: 'mf',
      country_en: 'Isla de San Martin',
      phone_code: 590,
    },
    {
      country_code: 'sx',
      country_en: 'Sint Maarten',
      phone_code: 599,
    },
    {
      country_code: 'sm',
      country_en: 'San Marino',
      phone_code: 378,
    },
    {
      country_code: 'vc',
      country_en: 'San Vicente y las Granadinas',
      phone_code: 1784,
    },
    {
      country_code: 'lk',
      country_en: 'Sri Lanka',
      phone_code: 94,
    },
    {
      country_code: 'sk',
      country_en: 'Eslovaquia',
      phone_code: 421,
    },
    {
      country_code: 'si',
      country_en: 'Eslovenia',
      phone_code: 386,
    },
    {
      country_code: 'sz',
      country_en: 'Esuatini',
      phone_code: 268,
    },
    {
      country_code: 'sd',
      country_en: 'Sudan',
      phone_code: 249,
    },
    {
      country_code: 'sr',
      country_en: 'Surinam',
      phone_code: 597,
    },
    {
      country_code: 'sb',
      country_en: 'Islas Solomon',
      phone_code: 677,
    },
    {
      country_code: 'so',
      country_en: 'Somalia',
      phone_code: 252,
    },
    {
      country_code: 'tj',
      country_en: 'Tayikistan',
      phone_code: 992,
    },
    {
      country_code: 'tw',
      country_en: 'Taiwan',
      phone_code: 886,
    },
    {
      country_code: 'th',
      country_en: 'Tailandia',
      phone_code: 66,
    },
    {
      country_code: 'tz',
      country_en: 'Tanzania',
      phone_code: 255,
    },
    {
      country_code: 'to',
      country_en: 'Tonga',
      phone_code: 676,
    },
    {
      country_code: 'tc',
      country_en: 'Turks and Caicos Islands',
      phone_code: 1649,
    },
    {
      country_code: 'tt',
      country_en: 'Trinidad y Tobago',
      phone_code: 1868,
    },
    {
      country_code: 'tn',
      country_en: 'Tunez',
      phone_code: 216,
    },
    {
      country_code: 'tv',
      country_en: 'Tuvalu',
      phone_code: 688,
    },
    {
      country_code: 'tr',
      country_en: 'Turquia',
      phone_code: 90,
    },
    {
      country_code: 'tm',
      country_en: 'Turkmenistan',
      phone_code: 993,
    },
    {
      country_code: 'tk',
      country_en: 'Tokelau',
      phone_code: 690,
    },
    {
      country_code: 'wf',
      country_en: 'Wallis y Futuna',
      phone_code: 681,
    },
    {
      country_code: 'vu',
      country_en: 'Vanuatu',
      phone_code: 678,
    },
    {
      country_code: 'gt',
      country_en: 'Guatemala',
      phone_code: 502,
    },
    {
      country_code: 've',
      country_en: 'Venezuela',
      phone_code: 58,
    },
    {
      country_code: 'bn',
      country_en: 'Brunei',
      phone_code: 673,
    },
    {
      country_code: 'ug',
      country_en: 'Uganda',
      phone_code: 256,
    },
    {
      country_code: 'ua',
      country_en: 'Ucrania',
      phone_code: 380,
    },
    {
      country_code: 'uy',
      country_en: 'Uruguay',
      phone_code: 598,
    },
    {
      country_code: 'uz',
      country_en: 'Uzbekistan',
      phone_code: 998,
    },
    {
      country_code: 'gr',
      country_en: 'Grecia',
      phone_code: 30,
    },
    {
      country_code: 'es',
      country_en: 'España',
      phone_code: 34,
    },
    {
      country_code: 'eh',
      country_en: 'Western Sahara',
      phone_code: 212,
    },
    {
      country_code: 'hk',
      country_en: 'Hong Kong',
      phone_code: 852,
    },
    {
      country_code: 'sg',
      country_en: 'Singapur',
      phone_code: 65,
    },
    {
      country_code: 'nc',
      country_en: 'Nueva Caledonia',
      phone_code: 687,
    },
    {
      country_code: 'nz',
      country_en: 'Nueva Zelanda',
      phone_code: 64,
    },
    {
      country_code: 'hu',
      country_en: 'Hungria',
      phone_code: 36,
    },
    {
      country_code: 'sy',
      country_en: 'Siria',
      phone_code: 963,
    },
    {
      country_code: 'jm',
      country_en: 'Jamaica',
      phone_code: 1876,
    },
    {
      country_code: 'am',
      country_en: 'Armenia',
      phone_code: 374,
    },
    {
      country_code: 'ye',
      country_en: 'Yemen',
      phone_code: 967,
    },
    {
      country_code: 'iq',
      country_en: 'Iraq',
      phone_code: 964,
    },
    {
      country_code: 'ir',
      country_en: 'Iran',
      phone_code: 98,
    },
    {
      country_code: 'il',
      country_en: 'Israel',
      phone_code: 972,
    },
    {
      country_code: 'it',
      country_en: 'Italia',
      phone_code: 39,
    },
    {
      country_code: 'in',
      country_en: 'India',
      phone_code: 91,
    },
    {
      country_code: 'id',
      country_en: 'Indonesia',
      phone_code: 62,
    },
    {
      country_code: 'gb',
      country_en: 'Reino Unido',
      phone_code: 44,
    },
    {
      country_code: 'vg',
      country_en: 'Islas Virgenes Britanicas',
      phone_code: 1284,
    },
    {
      country_code: 'io',
      country_en: 'Territorio Britanico del Oceano Indico',
      phone_code: 246,
    },
    {
      country_code: 'jo',
      country_en: 'Jordania',
      phone_code: 962,
    },
    {
      country_code: 'vn',
      country_en: 'Vietnam',
      phone_code: 84,
    },
    {
      country_code: 'zm',
      country_en: 'Zambia',
      phone_code: 260,
    },
    {
      country_code: 'je',
      country_en: 'Jersey',
      phone_code: 44,
    },
    {
      country_code: 'td',
      country_en: 'Chad',
      phone_code: 235,
    },
    {
      country_code: 'gi',
      country_en: 'Gibraltar',
      phone_code: 350,
    },
    {
      country_code: 'cl',
      country_en: 'Chile',
      phone_code: 56,
    },
    {
      country_code: 'cf',
      country_en: 'Republica del Africa Central',
      phone_code: 236,
    },
    {
      country_code: 'cn',
      country_en: 'China',
      phone_code: 86,
    },
  ];

  getList(): {
    country_code: string;
    country_en: string;
    phone_code: number;
  }[] {
    return this.list;
  }
}
