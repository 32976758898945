import { OfficeEmail } from './office-email.model';
import { OfficePhone } from './office-phone.model';

export class Office {
  id: string;
  name: string;
  slug?: string;
  description: string;
  address: string;
  location: any;
  emails: OfficeEmail[];
  phones: OfficePhone[];
  internalId: string;
  nodeId: string;
  photo: string;
  rawPhoto: string;

  constructor() {
    this.id = '';
    this.name = '';
    this.description = '';
    this.address = '';
    this.location = {};
    this.emails = [];
    this.phones = [];
    this.internalId = '';
    this.nodeId = '';
    this.photo = '';
    this.rawPhoto = '';
  }
}
