@if (isOpen) {
  <div
    id="container"
    [style.width.px]="width"
    [style.height.px]="height"
    [style.left.px]="positionX"
    [style.top.px]="positionY">
    <div
      id="wrapper"
      [style.paddingTop.px]="paddingTopBottom"
      [style.paddingBottom.px]="paddingTopBottom"
      [style.paddingLeft.px]="padding"
      [style.paddingRight.px]="padding">
      <template #dinamicComponent></template>
    </div>
    <div id="apply">
      <div id="border-apply"></div>
      <qr-button
        (click)="apply()"
        id="button-apply"
        [config]="this.configButtonApply"></qr-button>
    </div>
  </div>
}
@if (isOpen) {
  <div id="overlay" aria-hidden="true" (click)="close()"></div>
}
