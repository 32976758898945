export class ContactForm {
  id: string = '';
  name: string = '';
  phone: string = '';
  email: string = '';
  locality: string = '';
  category: string = '';
  comments: string = '';
  receiveCopy: boolean = false;
}
