<div
  id="container"
  [class]="this.config.style"
  [style.line-height.px]="this.config.height"
  [style.height.px]="this.config.height"
  [style.paddingTop.px]="this.config.paddingVertical"
  [style.paddingBottom.px]="this.config.paddingVertical"
  [style.paddingLeft.px]="this.config.paddingHorizontal"
  [style.paddingRight.px]="this.config.paddingHorizontal"
  matRipple>
  <span (click)="onClick($event)" aria-hidden="true"
    >{{ this.config.text | translate: locale.language }}
    {{ this.config.textSecondary }}
  </span>
  <div
    aria-hidden="true"
    (click)="onClick($event)"
    id="icon"
    [class]="config.icon"></div>
</div>
