import { QRIcon } from './qr-icon.model';

export class QRToggle {
  // Jerarquía
  public static readonly HIERARCHY_PRIMARY_SOLID: string =
    'toggle__button--primary-solid';

  public static readonly HIERARCHY_PRIMARY_BORDER: string =
    'toggle__button--primary-border';

  options!: ToggleOption[];

  hierarchy!: string;
}

export class ToggleOption {
  value?: string;
  icon?: QRIcon;
}
