import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  HostListener,
  Input,
} from '@angular/core';
import { Constants } from '@base/src/app/core/constants/constants';
import { DomSanitizer } from '@angular/platform-browser';
import { QrIconButtonComponent } from '../qr-icon-button/qr-icon-button.component';
import { SwiperOptions } from 'swiper/types';
import { SwiperDirective } from '../../directive/swiper.directive';

export class PhotoPanorama {
  isOpen?: boolean = false;
  photos: string[] = [];
}

@Component({
  selector: 'qr-photo-panorama',
  templateUrl: './qr-photo-panorama.component.html',
  styleUrls: ['./qr-photo-panorama.component.scss'],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [QrIconButtonComponent, SwiperDirective],
})
export class QrPhotoPanoramaComponent {
  @Input() config!: PhotoPanorama;
  indexSelected = 0;
  swiperConfig: SwiperOptions = {
    spaceBetween: 0,
    slidesPerView: 1,
    centeredSlides: true,
    centeredSlidesBounds: true,
    navigation: {
      nextEl: '.next',
      prevEl: '.prev',
    },
    grabCursor: false,
    watchSlidesProgress: true,
  }; // Galería de fotos

  configButtonClose = {
    style: Constants.BUTTON_COLOR_GREY_BASIC,
    height: Constants.BUTTON_HEIGHT_48PX,
  }; // Botón: cerrar

  // Eventos de teclado
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (event.key == 'Escape' && this.config.isOpen == true) {
      // Tecla ESC: Cierra Diálogo
      this.close();
    }
  }

  constructor(private domSanitize: DomSanitizer) {}

  galleryIndexChange(indexToMove: any): void {
    this.indexSelected = indexToMove;
  }

  close(): void {
    this.config.isOpen = false;
  }

  sanitizeURL(url: any) {
    const pannellumDir =
      'https://cdn.pannellum.org/2.5/pannellum.htm#panorama=';
    const parameters = '&autoLoad=true&autoRotate=-2';
    return this.domSanitize.bypassSecurityTrustResourceUrl(
      pannellumDir + url + parameters
    );
  }
}
