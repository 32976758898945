<div class="container-qr-card-details-prop flex-col flex-start-start">
  <div id="first-item">
    @if (data) {
      <div id="title-container">
        <h2>
          {{
            'listing-detail.description'
              | translate: locale.language
              | titlecase
          }}
        </h2>
      </div>
      <div id="title" class="flex-col flex-start-start">
        <h3 class="title-description">
          {{ data.title }}
        </h3>
        <h3
          class="title-description space"
          id="last"
          [ngClass]="{ 'full-height': showExtraDescription }"
          [innerHtml]="data.description"></h3>
      </div>
      @if (!showExtraDescription && !hideButton) {
        <div class="button-container">
          <qr-button-with-icon
            [config]="buttonViewMore"
            class="view-more-button"
            (click)="toggleExtraDescription()">
          </qr-button-with-icon>
        </div>
      }
      @if (showExtraDescription && !hideButton) {
        <div class="button-container">
          <qr-button-with-icon
            [config]="buttonLessView"
            class="view-more-button"
            (click)="toggleExtraDescription()">
          </qr-button-with-icon>
        </div>
      }
    } @else {
      <qr-skeleton-loader
        id="skeleton-loader"
        type="basic-data"></qr-skeleton-loader>
    }
  </div>
  <div id="second-item">
    @if (data) {
      <div class="flex-col flex-start-start">
        @if (!this.isEntrepreneurship) {
          <div class="flex-fill">
            <div class="flex-row flex-fill gap-8">
              <!-- Superficie total -->
              @if (data.dimensionTotalBuilt && data.dimensionTotalBuilt > 0) {
                <div class="flex-row column-item flex-50 flex-lt-md-100">
                  <div id="total-surface" class="icon"></div>
                  @if (
                    data.type !== PROPERTY_TYPE_CAMPO &&
                    data.type !== PROPERTY_TYPE_CHACRA
                  ) {
                    <span class="feature-detail">
                      {{
                        'listing-detail.feature_dimension_total_built'
                          | translate: locale.language
                      }}: {{ data.dimensionTotalBuilt | qrNumber }}
                      {{ 'general.square_meter' | translate: locale.language }}
                    </span>
                  }
                  @if (
                    data.type === PROPERTY_TYPE_CAMPO ||
                    data.type === PROPERTY_TYPE_CHACRA
                  ) {
                    <span class="feature-detail">
                      {{
                        'listing-detail.feature_dimension_total_built'
                          | translate: locale.language
                      }}:
                      {{ data.dimensionLand || 0 | metersToHectare | qrNumber }}
                    </span>
                  }
                </div>
              }
              <!-- Superficie cubierta -->
              @if (data.dimensionCovered && data.dimensionCovered > 0) {
                <div
                  class="flex-lt-md-100 flex-50 flex-row column-item flex-50">
                  <div id="cover-surface" class="icon"></div>
                  <span class="feature-detail">
                    {{
                      'listing-detail.feature_dimension_covered'
                        | translate: locale.language
                    }}: {{ data.dimensionCovered | qrNumber }}
                    {{ 'general.square_meter' | translate: locale.language }}
                  </span>
                </div>
              }
              <!-- Superficie semicubierta -->
              @if (data.dimensionSemicovered && data.dimensionSemicovered > 0) {
                <div class="flex-lt-md-100 flex-50 flex-row column-item">
                  <div id="semi-cover-surface" class="icon"></div>
                  <span class="feature-detail">
                    {{
                      'listing-detail.feature_dimension_semicovered'
                        | translate: locale.language
                    }}: {{ data.dimensionSemicovered | qrNumber }}
                    {{ 'general.square_meter' | translate: locale.language }}
                  </span>
                </div>
              }
              <!-- Superficie terreno -->
              @if (data.dimensionLand && data.dimensionLand > 0) {
                <div class="flex-lt-md-100 flex-50 flex-row column-item">
                  <div id="lot-surface" class="icon"></div>
                  @if (
                    data.type !== PROPERTY_TYPE_CAMPO &&
                    data.type !== PROPERTY_TYPE_CHACRA
                  ) {
                    <span class="feature-detail">
                      {{
                        'listing-detail.feature_dimension_land'
                          | translate: locale.language
                      }}: {{ data.dimensionLand | qrNumber }}
                      {{ 'general.square_meter' | translate: locale.language }}
                    </span>
                  }
                  @if (
                    data.type === PROPERTY_TYPE_CAMPO ||
                    data.type === PROPERTY_TYPE_CHACRA
                  ) {
                    <span class="feature-detail">
                      {{
                        'listing-detail.feature_dimension_land'
                          | translate: locale.language
                      }}: {{ data.dimensionLand | metersToHectare | qrNumber }}
                    </span>
                  }
                </div>
              }
              <!-- Ambientes -->
              @if (data.totalRooms && data.totalRooms > 0) {
                <div class="flex-lt-md-100 flex-50 flex-row column-item">
                  <div id="rooms" class="icon"></div>
                  <span class="feature-detail">
                    {{
                      'listing-detail.feature_total_rooms'
                        | translate: locale.language
                    }}:
                    {{ data.totalRooms }}
                  </span>
                </div>
              }
              <!-- Baños -->
              @if (data.bathrooms && data.bathrooms > 0) {
                <div class="flex-lt-md-100 flex-50 flex-row column-item">
                  <div id="bathrooms" class="icon"></div>
                  <span class="feature-detail">
                    {{
                      'listing-detail.feature_bathrooms'
                        | translate: locale.language
                    }}:
                    {{ data.bathrooms }}
                  </span>
                </div>
              }
              <!-- Toilettes -->
              @if (data.toilets && data.toilets > 0) {
                <div class="flex-lt-md-100 flex-50 flex-row column-item">
                  <div id="toilettes" class="icon"></div>
                  <span class="feature-detail">
                    {{
                      'listing-detail.feature_toilets'
                        | translate: locale.language
                    }}:
                    {{ data.toilets }}
                  </span>
                </div>
              }
              <!-- Dormitorios -->
              @if (data.bedrooms && data.bedrooms > 0) {
                <div class="flex-lt-md-100 flex-50 flex-row column-item">
                  <div id="bedrooms" class="icon"></div>
                  <span class="feature-detail">
                    {{
                      'listing-detail.feature_bedrooms'
                        | translate: locale.language
                    }}:
                    {{ data.bedrooms }}
                  </span>
                </div>
              }
              <!-- Garage -->
              @if (data.parkingSpaces && data.parkingSpaces > 0) {
                <div class="flex-lt-md-100 flex-50 flex-row column-item">
                  <div id="garage" class="icon"></div>
                  <span class="feature-detail">
                    {{
                      'listing-detail.feature_parking'
                        | translate: locale.language
                    }}:
                    {{ data.parkingSpaces }}
                  </span>
                </div>
              }
              <!-- Antigüedad -->
              @if (data.yearBuilt && data.type !== '18' && data.type !== '19') {
                <div class="flex-lt-md-100 flex-50 flex-row column-item">
                  <div id="antiquity" class="icon"></div>
                  <p class="feature-detail-text">
                    <span
                      >{{
                        'listing-detail.feature_antiquity'
                          | translate: locale.language
                          | titlecase
                      }}:</span
                    >
                    <span>
                      {{
                        yearBuiltCalc >= 0
                          ? this.utilsService.getAntiquity(data.yearBuilt)
                          : ('list.brandNew' | translate: locale.language)
                      }}</span
                    >
                    @if (yearBuiltCalc >= 0) {
                      <span>
                        {{ 'general.years' | translate: locale.language }}</span
                      >
                    }
                  </p>
                </div>
              }
              <!-- Deptos. en el edificio -->
              @if (data.totalUnits && data.totalUnits > 0) {
                <div class="flex-lt-md-100 flex-50 flex-row column-item">
                  <div id="apartments-in-building" class="icon"></div>
                  <span class="feature-detail">
                    {{
                      'listing-detail.feature_total_units'
                        | translate: locale.language
                    }}:
                    {{ data.totalUnits }}
                  </span>
                </div>
              }
              <!-- Expensas -->
              @if (data.expensesPrice && data.expensesPrice > 0) {
                <div class="flex-lt-md-100 flex-50 flex-row column-item">
                  <div id="expenses" class="icon"></div>
                  <span class="feature-detail">
                    {{
                      'listing-detail.feature_expenses'
                        | translate: locale.language
                    }}:
                    {{
                      data.expensesCurrency ? data.expensesCurrency.value : null
                    }}
                    {{ data.expensesPrice | qrNumber }}
                  </span>
                </div>
              }
              <!-- Apto crédito -->
              @if (data.aptCredit) {
                <div class="flex-lt-md-100 flex-50 flex-row column-item">
                  <div id="suitable-credit" class="icon"></div>
                  <span class="feature-detail">
                    {{
                      'listing-detail.feature_apt_credit'
                        | translate: locale.language
                    }}:
                    {{ 'general.yes' | translate: locale.language | titlecase }}
                  </span>
                </div>
              }
              <!-- Ofrece financiamiento -->
              @if (data.financing) {
                <div class="flex-lt-md-100 flex-50 flex-row column-item">
                  <div id="offer-financing" class="icon"></div>
                  <span class="feature-detail">
                    {{
                      'listing-detail.feature_financing'
                        | translate: locale.language
                    }}:
                    {{ 'general.yes' | translate: locale.language | titlecase }}
                  </span>
                </div>
              }
              <!-- Apto profesional -->
              @if (data.professionalUse) {
                <div class="flex-lt-md-100 flex-50 flex-row column-item">
                  <div id="suitable-professional" class="icon"></div>
                  <span class="feature-detail">
                    {{
                      'listing-detail.feature_professional_use'
                        | translate: locale.language
                    }}:
                    {{ 'general.yes' | translate: locale.language | titlecase }}
                  </span>
                </div>
              }
              <!-- Pisos en la propiedad -->
              @if (data.floors && data.floors > 0) {
                <div class="flex-lt-md-100 flex-50 flex-row column-item">
                  <div id="flats-on-the-property" class="icon"></div>
                  <span class="feature-detail">
                    {{
                      'listing-detail.feature_total_floors'
                        | translate: locale.language
                    }}:
                    {{ data.floors }}
                  </span>
                </div>
              }
            </div>
          </div>
        }
        @if (this.isEntrepreneurship) {
          <h3 class="grey-nero subheading-01-bold" id="features-title">
            {{
              'listing-detail.feature_caracteristic'
                | translate: locale.language
            }}
          </h3>
        }
        <!--- Características de la propiedad (Expandible) -->
        <div
          id="third-item"
          class="flex-col flex-start-start"
          [ngClass]="{
            open: showExtraFeatures || this.isEntrepreneurship,
          }">
          <div class="flex-row flex-fill gap-8">
            @for (feature of this.features; track feature) {
              @if (feature.isTitle) {
                <div class="flex-row column-item view-more">
                  <p class="subheading-03-bold grey-nero w-100">
                    {{
                      'listing-detail.feature_' + feature.value
                        | translate: locale.language
                        | titlecase
                    }}
                  </p>
                </div>
              } @else {
                <div class="flex-lt-md-100 flex-50 flex-row column-item">
                  <p class="subheading-03-regular grey-nero w-100">
                    {{
                      'listing-detail.feature_' + feature.lang
                        | translate: locale.language
                        | titlecase
                    }}
                  </p>
                </div>
              }
            }
          </div>
          @if (showExtraFeatures && !this.isEntrepreneurship) {
            <div class="button-container">
              <qr-button-with-icon
                [config]="buttonViewLess"
                (click)="toggleExtraFeatures()"
                class="view-more-button">
              </qr-button-with-icon>
            </div>
          }
        </div>
        @if (!showExtraFeatures && !this.isEntrepreneurship) {
          <div class="button-container">
            <qr-button-with-icon
              [config]="buttonViewMore"
              (click)="toggleExtraFeatures()"
              class="view-more-button">
            </qr-button-with-icon>
          </div>
        }
      </div>
    } @else {
      <qr-skeleton-loader
        id="skeleton-loader"
        type="basic-data"></qr-skeleton-loader>
    }
    <!-- Código de publicación -->
    @if (!this.isEntrepreneurship) {
      <div class="flex-row mt-16 flex-start-center">
        <span id="publication-code">
          {{ 'listing-detail.publication_code' | translate: locale.language }}:
          {{ data.internalId }}
        </span>
      </div>
    }
  </div>
</div>
