<div class="user-account-container">
  <div class="side-menu">
    <ul (click)="onVisibleContent()">
      <li>
        <a routerLink="/cuenta/datos" [routerLinkActive]="['active']">Datos</a>
      </li>
      @if (!loggedinBySocialNetwork) {
        <li>
          <a
            routerLink="/cuenta/cambiar-contraseña"
            [routerLinkActive]="['active']"
            >Cambiar contraseña</a
          >
        </li>
      }
      <li>
        <a routerLink="/cuenta/eliminar" [routerLinkActive]="['active']"
          >Eliminar cuenta</a
        >
      </li>
    </ul>
  </div>

  @if (contentVisible) {
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  }
</div>
