import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { Constants } from '@base/src/app/core/constants/constants';
import { QRMediaImage } from '@app/core/models/qr-media-img';
import { ReportService } from '@app/services/report.service';
import { UtilsService } from '@app/services/utils.service';
import { QrFilter } from '@app/core/models/qr-filter';
import { QrFilterCustom } from '@app/core/models/qr-filter-custom';
import { QrMediaImgComponent } from '@app/components/qr-media-img/qr-media-img.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { IQrFilterType } from '@base/src/app/core/enum/IQrFilterType';
import { IQrResponse } from '@base/src/app/core/models/IQrResponse';
import { Subject, takeUntil } from 'rxjs';
import { IQrPage } from '@base/src/app/core/models/IQrPage';
@Component({
  selector: 'app-indice-remax',
  templateUrl: './detail.page.html',
  styleUrls: ['./detail.page.scss'],
  standalone: true,
  imports: [QrMediaImgComponent, MatOptionModule, MatSelectModule],
})
export class IndiceRemaxLandingPage implements OnInit, OnDestroy {
  chips = ['Índice', 'Metodologías', 'Reportes'];

  isSelected = false;
  options: any[] = [];
  index: any[] = [];
  linkedDropdown: boolean = false;
  placeholder: string = '';

  configSwiperChips: any = {
    paginationClickable: true,
    navigation: {
      nextEl: '.button-next',
      prevEl: '.button-prev',
    },
    watchSlidesProgress: true,
    watchSlidesVisibility: true,
    slidesPerView: '3',
    spaceBetween: 16,
    watchOverflow: true,
  };

  // Logo ÍNDICE RE/MAX
  logoIndiceREMAX: QRMediaImage = {
    text: 'Indice RE/MAX',
    baseURL: '',
    docFolder: 'assets/media/svg/indice-remax',
    docName: 'logo-indice-remax',
    docType: Constants.DOCTYPE_SVG,
    alt: 'Índice RE/MAX',
    width: 'auto',
    height: '150px',
    loading: QRMediaImage.LOADING_LAZY,
    decoding: QRMediaImage.DECODING_ASYNC,
  };

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private reportService: ReportService,
    @Inject(DOCUMENT) private document: Document,
    private utils: UtilsService
  ) {}

  ngOnInit(): void {
    this.getYearsDropdown();
    this.setMetaTags();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getYearsDropdown() {
    this.placeholder = new Date().getFullYear().toString();
    this.loadReportsByYear(Number(this.placeholder));
    let actualYear = Number(this.placeholder);
    const firstData = 2021;
    for (actualYear; actualYear >= firstData; actualYear--) {
      this.options.push(actualYear);
    }
    // this.options.unshift(actualYear);
  }

  getTitle(title: string): string {
    const string = title.split(' – ');
    const value = string[1].split(' ');
    return value[0].toLowerCase();
  }

  goTo(id: string, device: 'mobile' | 'desktop'): void {
    const ELEMENT = document.getElementById(id);
    const REPORT_ELEMENT =
      id === 'Reportes'
        ? device === 'mobile'
          ? document.getElementById('MobileReport')
          : document.getElementById('DesktopReport')
        : ELEMENT;
    if (!REPORT_ELEMENT) return;
    REPORT_ELEMENT.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  loadReportsByYear(year: number): void {
    const FILTER: QrFilter = new QrFilter();
    const filter: QrFilterCustom = new QrFilterCustom(
      IQrFilterType.eq,
      'year',
      year
    );
    FILTER.addFilter(filter);
    const QUERY: string = FILTER.toQueryStringBe();
    this.index = [];
    this.reportService
      .getParams<string, IQrResponse<IQrPage>>(QUERY)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: IQrResponse<IQrPage>) => {
          if (response.data.data && response.data.data.length > 0) {
            response.data.data.forEach(element => {
              element.url =
                '/indice-remax/' +
                element.month.toString() +
                '-' +
                element.year.toString() +
                '-' +
                element.id;
              this.index.push(element);
            });
          }
        },
        error: error => {
          console.error('Error occurred:', error);
        },
      });
  }

  setMetaTags(): void {
    const title = 'Índice Re/Max de valuación de inmuebles | RE/MAX';
    const description =
      'El Índice Re/Max presenta el precio promedio por m2 de un inmueble en Buenos Aires Argentina permitiendo comparar su precio de venta en el mercado.';

    this.utils.setPageMetaTags(title, description);
  }
}
