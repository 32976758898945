<div #content>
  <div style="position: absolute">
    <div
      [id]="marker.id"
      [ngClass]="{
        marker: isZoomClose,
        'marker-small': !isZoomClose,
        'apartments-in-building': !isZoomClose && marker.entrepreneurship,
      }">
      <div [class]="marker.icon" class="icon"></div>
      <span>{{ marker.label }}</span>
    </div>
  </div>
</div>
