import { Routes, UrlMatchResult, UrlSegment } from '@angular/router';
import { PublicLayout } from './layouts/public/public.component';
import { HomePage } from './pages/public/home/home.page';
import { ContactPage } from './pages/public/contact/contact.component';
import { SellPage } from './pages/public/sell/sell.component';
import { LoginUserPage } from './pages/public/user-web/login-user/login-user.component';
import { UserRegisterPage } from './pages/public/user-web/user-register/user-register.component';
import { ForgottenPasswordPage } from './pages/public/user-web/forgotten-password/forgotten-password.component';
import { FavoritesPage } from './pages/public/user-web/favorites/favorites.component';
import { UserAccountPage } from './pages/public/user-web/user-account/user-account.component';
import { UserAccountDataComponent } from './components/user-account-data/user-account-data.component';
import { UserAccountChangePasswordComponent } from './components/user-account-change-password/user-account-change-password.component';
import { UserAccountDeleteComponent } from './components/user-account-delete/user-account-delete.component';
import { ConsumerPage } from './pages/public/consumer-information/consumer.page';
import { BeAgentPage } from './pages/public/be-agent/detail.page';
import { TermsAndConditionsPage } from './pages/public/terms-and-conditions/detail.page';
import { PrivacyPoliticPage } from './pages/public/privacy-politic/detail.page';
import { MoreThanMovingPage } from './pages/public/more-than-moving/detail.page';
import { LandingPage } from './pages/public/landing/detail.page';
import { ListingListPage } from './pages/public/listing-list/list.page';
import { Error404Page } from './pages/public/404/404.page';
import { IndiceRemaxSinglePage } from './pages/public/indice-remax-single/detail.page';
import { environment } from '@base/environments/environment';
import { IndiceRemaxLandingPage } from './pages/public/indice-remax-landing/detail.page';
import { QRCompsPage } from './pages/public/qrcomps/qrcomps.component';
import { ListingDetailPage } from './pages/public/listing-detail/listing-detail.page';
import { CollectionHomePage } from './pages/collection/collection-home/home.page';
import { CollectionLayout } from './layouts/collection/collection.component';
import { string2Number } from './core/utils/string2number';
import { EntrepreneurshipListPage } from './pages/public/entrepreneurship-list/entrepreneurship-list.page';
import { EntrepreneurshipDetailPage } from './pages/public/entrepreneurship-detail/entrepreneurship-detail.page';
import { UnitDetailPage } from './pages/public/unit-detail/unit-detail.page';
import { AgentDetailPage } from './pages/public/agent-detail/agent-detail.page';
import { BrokerDetailPage } from './pages/public/broker-detail/broker-detail.page';
import { OfficeDetailPage } from './pages/public/office-detail/office-detail.page';
import { RemaxNoSeVaPage } from './pages/public/remax-no-se-va/detail.page';
import { SaveSearchPage } from './pages/public/user-web/save-search/save-search.component';

function propertyMatcher(segments: UrlSegment[]): UrlMatchResult | null {
  // Define las categorías válidas
  const validCategories = [
    'departamentos',
    'casas',
    'apartamentos',
    'oficinas',
    'locales',
    'propiedades',
    'terrenos',
  ];

  if (!segments || segments.length < 1) return null;
  const partsUrl = segments[0].path.split('-');

  if (validCategories.includes(partsUrl[0]) && partsUrl[1] === 'en') {
    return {
      consumed: segments,
      posParams: {
        category: new UrlSegment(partsUrl[0], {}),
        wildcard: new UrlSegment(partsUrl[2], {}),
      },
    };
  }

  if (segments[0].path == 'oficinas-temporales') {
    return {
      consumed: segments,
      posParams: {
        category: new UrlSegment(partsUrl[0], {}),
        wildcard: new UrlSegment(partsUrl[1], {}),
      },
    };
  }

  if (validCategories.includes(segments[0].path)) {
    return { consumed: segments };
  }

  return null;
}

function landingMatcher(segments: UrlSegment[]): UrlMatchResult | null {
  const validRoutes = [
    //encontrá más propiedades
    'propiedades-en-la-plata',
    'propiedades-en-santa-fe',
    'propiedades-en-mendoza',
    'propiedades-en-bariloche',
    'propiedades-en-salta',
    'propiedades-en-pilar',
    'propiedades-en-mar-del-plata',
    'propiedades-en-palermo',
    'propiedades-en-rosario',
    'propiedades-en-nordelta',
    'propiedades-en-caballito',
    'propiedades-en-belgrano',
    'propiedades-en-san-isidro',
    'propiedades-en-tigre',
    'propiedades-en-ciudad-de-buenos-aires',
    'propiedades-en-vicente-lopez',
    'propiedades-en-cordoba',
    'propiedades-en-neuquen',
    'propiedades-en-montevideo',
    'propiedades-en-punta-del-este',
    //otros
    'locales-comerciales',
    'oficinas',
  ];

  if (segments.length === 1 && validRoutes.includes(segments[0].path)) {
    return { consumed: segments };
  }

  return null;
}

function indiceMatcher(url: UrlSegment[]): UrlMatchResult | null {
  const resultUrl = url[0].path.startsWith('indice-remax');
  if (resultUrl) {
    const result = environment.node != 'ar';
    return result == true
      ? null
      : {
          consumed: [url[0]],
        };
  }
  return null;
}

function inmueblesMatcher(url: UrlSegment[]): UrlMatchResult | null {
  const resultUrl = url[0].path.startsWith('inmuebles');
  if (resultUrl) {
    return {
      consumed: [url[0]],
    };
  }
  return null;
}

function officeMatcher(url: UrlSegment[]) {
  const value = url[0].path;
  let result = true;
  if (value.includes('-')) {
    const p = value.split('-');
    if (string2Number(p[0]) > 0) {
      result = false;
    }
  }

  return result == true
    ? {
        consumed: [url[0]],
        posParams: {
          id: url[0],
        },
      }
    : null;
}

function mlsidMatcher(url: UrlSegment[]) {
  const value = url[0].path;
  let result = true;
  if (value.includes('-')) {
    const p = value.split('-');
    if (string2Number(p[0]) > 0) {
      result = false;
    }
  }
  return result == true
    ? null
    : {
        consumed: [url[0]],
        posParams: {
          mlsid: url[0],
        },
      };
}

export const routes: Routes = [
  {
    path: 'collection',
    component: CollectionLayout,
    children: [
      {
        path: '',
        component: CollectionHomePage,
        title: 'RE/MAX Collection | Especialistas en propiedades premium',
      },
    ],
  },
  {
    path: '',
    component: PublicLayout,
    children: [
      { path: '', pathMatch: 'full', component: HomePage, title: 'Home page' },
      {
        path: 'iniciar-sesion',
        component: LoginUserPage,
        title: 'Iniciar sesión | RE/MAX',
      },
      {
        path: 'olvide-contraseña',
        component: ForgottenPasswordPage,
      },
      {
        path: 'registro',
        component: UserRegisterPage,
        title: 'Registro de usuario | RE/MAX',
      },
      {
        path: 'favoritos',
        component: FavoritesPage,
      },
      {
        path: 'busquedas',
        component: SaveSearchPage,
      },
      {
        path: 'cuenta',
        redirectTo: 'cuenta/datos',
        pathMatch: 'full',
      },
      {
        path: 'cuenta',
        component: UserAccountPage,
        children: [
          {
            path: 'datos',
            component: UserAccountDataComponent,
          },
          {
            path: 'cambiar-contraseña',
            component: UserAccountChangePasswordComponent,
          },
          {
            path: 'eliminar',
            component: UserAccountDeleteComponent,
          },
        ],
      },
      {
        path: 'contacto',
        component: ContactPage,
        title: 'Envíenos un mensaje | RE/MAX',
      },
      {
        path: 'emprendimientos',
        component: EntrepreneurshipListPage,
      },
      {
        path: 'emprendimientos/:slug',
        component: EntrepreneurshipDetailPage,
      },
      {
        path: 'entrepreneurship/:id',
        component: EntrepreneurshipDetailPage,
      },
      {
        path: 'units/:slug',
        component: UnitDetailPage,
      },
      {
        path: 'alquilar-propiedades',
        component: ListingListPage,
      },
      {
        path: 'listings/rent',
        component: ListingListPage,
      },
      {
        path: 'comprar-propiedades',
        component: ListingListPage,
      },
      {
        path: 'listings/buy',
        component: ListingListPage,
      },
      {
        path: 'listings/:slug',
        component: ListingDetailPage,
      },
      { path: 'sell', component: SellPage, title: 'Vendé tu propiedad' },
      { path: 'vender', component: SellPage, title: 'Vendé tu propiedad' },
      {
        path: 'consumer',
        component: ConsumerPage,
      },
      {
        path: 'agent/:id',
        component: AgentDetailPage,
      },
      {
        path: 'broker/:id',
        component: BrokerDetailPage,
      },
      {
        path: 'be-agent',
        component: BeAgentPage,
      },
      {
        path: 'terminos-y-condiciones',
        component: TermsAndConditionsPage,
      },
      {
        path: 'politica-de-privacidad',
        component: PrivacyPoliticPage,
      },
      {
        path: 'muchomasquemudarte',
        component: MoreThanMovingPage,
      },
      {
        matcher: landingMatcher,
        component: LandingPage,
      },
      {
        matcher: propertyMatcher,
        component: ListingListPage,
      },
      {
        path: 'officeDetail/:id',
        component: OfficeDetailPage,
      },
      {
        path: 'listingsByInternalId/:internalId',
        component: ListingDetailPage,
      },
      {
        path: '404',
        component: Error404Page,
      },
      {
        path: 'indice-remax/:id',
        component: IndiceRemaxSinglePage,
      },
      {
        matcher: inmueblesMatcher,
        component: LandingPage,
      },
      {
        matcher: indiceMatcher,
        component: IndiceRemaxLandingPage,
      },
      {
        path: 'hablemos-de-verdad',
        component: RemaxNoSeVaPage,
      },
      { path: 'qrcomps', component: QRCompsPage, title: 'QR Comps' },
      {
        matcher: officeMatcher,
        component: OfficeDetailPage,
      },
      {
        matcher: mlsidMatcher,
        component: ListingDetailPage,
      },
      { path: '**', redirectTo: '' },
    ],
  },
];
