export class ResizedImage {
  jpgL?: string;
  jpgM?: string;
  jpgS?: string;
  jpgXS?: string;
  webpL?: string;
  webpM?: string;
  webpS?: string;
  webpXS?: string;
  public static readonly SMALL: string = '264x144';
  public static readonly LARGE: string = '860x440';
  public static readonly AUTO: string = 'AUTOx860';
}
