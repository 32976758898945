import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpService } from '@app/core/services/http.service';

@Injectable({ providedIn: 'root' })
export class ListingTypesSellService extends HttpService {
  constructor(http: HttpClient) {
    super(http, 'listingTypesSell/');
  }
}
