import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Filter } from '@app/core/models/filters.model';
import { BehaviorSubject } from 'rxjs';
import { FavoritesService } from '../../../../services/favorites.service';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Constants } from '@base/src/app/core/constants/constants';
import { Auth } from 'aws-amplify';
import { QrPaginationComponent } from '@app/components/qr-pagination/qr-pagination.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { QrButtonComponent } from '@app/components/qr-button/qr-button.component';
import { FormsModule } from '@angular/forms';
import { UserFavoriteItemComponent } from '@app/components/user-favorite-item/user-favorite-item.component';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss'],
  standalone: true,
  imports: [
    QrPaginationComponent,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    CommonModule,
    QrButtonComponent,
    FormsModule,
    UserFavoriteItemComponent,
  ],
})
export class FavoritesPage implements OnInit {
  favoritesList: any[] = [];

  checkboxDeleteAll = false;
  someSelected = false;
  loader = false;
  animationDelete = false;
  visiblePagination = false;

  //Paginacion
  page = 0;
  totalItems = 0;
  pageSize = 5;
  isLoading = new BehaviorSubject(false);
  isBrowser: boolean;

  configButtonEmptyAndExplore = {
    style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
    height: Constants.BUTTON_HEIGHT_48PX,
    text: 'Buscar propiedades',
  };

  constructor(
    private favoritesService: FavoritesService,
    private titleService: Title,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.titleService.setTitle('Mis favoritos | RE/MAX');

    //conseguir info del user logueado
    this.loader = true;
    Auth.currentAuthenticatedUser()
      .then(() => {
        this.loadFavorites();
      })
      .catch(() => {
        // console.log('Ningún user logueado.');
        this.loader = false;
      });
  }

  async loadFavorites(): Promise<void> {
    this.visiblePagination = false;
    this.checkboxDeleteAll = false;
    this.favoritesList = [];
    this.loader = true;

    const filter = new Filter();
    filter.addFilter('size', this.pageSize);
    filter.addFilter('page', this.page);

    await this.favoritesService
      .getMyFavorites(filter)
      .then(response => {
        if (response && response.data && response.data.data) {
          response.data.data.forEach((e: any) => {
            const item = {
              listingId: e.listingId,
              id: e.id,
              photo: e.photo,
              slug: e.slug,
              title: e.title,
              address: e.address,
              currency: e.currency,
              price: e.price,
              userId: e.userId,
              checkToDelete: false,
              animationDeleted: false,
              published: e.published,
            };
            this.favoritesList.push(item);
          });
          this.loader = false;
          this.totalItems = response.data.totalItems;
          this.visiblePagination = this.totalItems > 5;
        }
      })
      .catch(() => {
        console.log('ocurrió un error al traer los favoritos ⛔');
        this.loader = false;
      });
  }

  onSelectItem(): void {
    this.someSelected = false;
    let aux = 0;

    this.favoritesList.forEach((e: any) => {
      if (e.checkToDelete) {
        aux = aux + 1;
      }
    });

    this.someSelected = aux > 0;

    if (aux == 0) {
      this.checkboxDeleteAll = false;
    }
  }

  //Delete con el botón de tacho de basura (de a 1)
  onDeleteItem(listingIdDeleted: any): void {
    this.favoritesList.forEach((e: any) => {
      if (e.listingId == listingIdDeleted) {
        e.animationDeleted = true;
      }
    });

    this.onDeleteFavorites([listingIdDeleted]);
  }

  //Delete de la seleccion (varios)
  onDeleteSelection(): void {
    const listIds: any[] = [];
    this.favoritesList.forEach((e: any) => {
      if (e.checkToDelete) {
        e.animationDeleted = true;
        listIds.push(e.listingId);
      }
    });
    this.onDeleteFavorites(listIds);
  }

  onDeleteFavorites(listIds: string[]): void {
    this.favoritesService
      .deleteFavoritesByListingId({ listingIds: listIds }) //los elimina del BE
      .then(() => {
        //Si se eliminó del BE, los elimina visualmente
        setTimeout(async () => {
          listIds.forEach(e => {
            this.favoritesList = this.favoritesList.filter(
              (favorite: any) => favorite.listingId != e
            );
          });
          if (this.favoritesList.length == 0) {
            this.page = this.page - 1 < 0 ? 0 : this.page - 1;
            await this.loadFavorites();
          }
        }, 250); //El tiempo de la animación
      });
  }

  confirmAllDelete(): void {
    this.someSelected = false;
    this.favoritesList.forEach((e: any) => {
      e.checkToDelete = this.checkboxDeleteAll;
    });
  }

  async pagination($event: any): Promise<void> {
    if (this.isBrowser) {
      const elmnt = document.getElementById('favorites-container');
      if (elmnt) elmnt.scrollIntoView({ behavior: 'smooth' });
    }
    this.page = $event;
    await this.loadFavorites();
    if (this.favoritesList.length == 0) {
      this.page = 0;
      this.loadFavorites();
    }
  }
}
