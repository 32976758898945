import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpService } from '../core/services/http.service';
import { first } from 'rxjs/operators';
import { environment } from '@base/environments/environment';
import { IQrResponse } from '../core/models/IQrResponse';
import { Office } from '../core/models/office.model';
@Injectable({ providedIn: 'root' })
export class OfficesService extends HttpService {
  constructor(http: HttpClient) {
    super(http, 'offices/');
  }

  findBySlug(slug: string): Promise<IQrResponse<Office>> {
    return new Promise((resolve, reject) => {
      this.http
        .get(environment.apiUrl + this._url + 'findBySlug/' + slug)
        .pipe(first())
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }
}
