import { DatePipe } from '@angular/common';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SaveSearchComponent } from '@base/src/app/components/dialogs-content/search-save/search-save.component';
import {
  QrDialogComponent,
  QRDialogConfig,
} from '@base/src/app/components/qr-dialog/qr-dialog.component';
import { ButtonComponent } from '@base/src/app/components/ui/button/button.component';
import { TagComponent } from '@base/src/app/components/ui/tag/tag.component';
import { Constants } from '@base/src/app/core/constants/constants';
import { SaveFilter } from '@base/src/app/core/interfaces/save-filters.interface';
import { UserSaveSearch } from '@base/src/app/core/interfaces/user-save-search.interface';
import { Button } from '@base/src/app/core/models/button.model';
import { Filter } from '@app/core/models/filters.model';
import { Icon } from '@base/src/app/core/models/icon.model';
import { IQrPage } from '@base/src/app/core/models/IQrPage';
import { IQrResponse } from '@base/src/app/core/models/IQrResponse';
import { ItemParam } from '@base/src/app/core/models/ItemParam';
import { Tag } from '@base/src/app/core/models/tag.model';
import { ParamsDomain } from '@base/src/app/domain/params.domain';
import { L10nTranslationService } from 'angular-l10n';
import { Auth } from 'aws-amplify';
import { Subject, Observable, takeUntil, BehaviorSubject } from 'rxjs';
import { QrPaginationComponent } from '@base/src/app/components/qr-pagination/qr-pagination.component';
import { UserSearchSaveService } from '@base/src/app/services/user-search.service';

@Component({
  selector: 'app-save-search',
  templateUrl: './save-search.component.html',
  styleUrls: ['./save-search.component.scss'],
  standalone: true,
  imports: [
    QrDialogComponent,
    ButtonComponent,
    TagComponent,
    DatePipe,
    QrPaginationComponent,
  ],
})
export class SaveSearchPage implements OnInit, OnDestroy {
  public userSaveSearch: UserSaveSearch[] = [];
  public formattedUrl: string = '';
  public page: number = 0;
  public totalItems: number = 0;
  public pageSize: number = 10;
  public isLoading = new BehaviorSubject(false);
  public visiblePagination: boolean = false;

  @ViewChild(SaveSearchComponent) saveSearchComponent!: SaveSearchComponent;

  public TAG_PRIMARY_BORDER: Tag = {
    color: Tag.COLOR_PRIMARY_BORDER,
  };

  public buttonTonalWithIcon: Button = {
    id: 'btn-tonal',
    hierarchy: Button.HIERARCHY_TONAL,
    size: Button.SIZE_48_PX,
    value: 'Ir a la búsqueda',
    icon: Icon.NAME_BOOKMARK,
  };

  public buttonDrawIcon: Button = {
    id: 'btn-grey-border',
    hierarchy: Button.HIERARCHY_GREY_BORDER,
    size: Button.SIZE_48_PX,
    icon: Icon.NAME_DRAW,
    iconOnly: true,
    iconTooltipOnly: 'Editar',
  };

  public buttonDeleteIcon: Button = {
    id: 'btn-grey-border',
    hierarchy: Button.HIERARCHY_GREY_BORDER,
    size: Button.SIZE_48_PX,
    icon: Icon.NAME_DELETE,
    iconOnly: true,
    iconTooltipOnly: 'Borrar',
  };

  public dialogData: QRDialogConfig = {
    isDialogOpen: false,
    titleFilter: 'Edita esta búsqueda',
    configButtonLeft: {
      style: Constants.BUTTON_COLOR_PRIMARY_BASIC,
      height: Constants.BUTTON_HEIGHT_48PX,
      text: 'Cancelar',
      id: 'clean-filters',
      selected: false,
      isDisabled: false,
      changeStyleOnSelected: false,
      styleOnSelected: '',
    },
    configButtonRight: {
      style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
      height: Constants.BUTTON_HEIGHT_48PX,
      text: 'Guardar cambios',
      id: 'apply',
      selected: false,
      isDisabled: false,
      changeStyleOnSelected: false,
      styleOnSelected: '',
    },
    dinamicComponent: 'save-search-edit',
    isTopbarVisible: true,
    isBotbarVisible: true,
    isHeightAutoFitContent: true,
  };

  public dialogDelete: QRDialogConfig = {
    isDialogOpen: false,
    titleFilter: 'Borrar búsqueda',
    configButtonLeft: {
      style: Constants.BUTTON_COLOR_PRIMARY_BASIC,
      height: Constants.BUTTON_HEIGHT_48PX,
      text: 'Cancelar',
      id: 'clean-filters',
      selected: false,
      isDisabled: false,
      changeStyleOnSelected: false,
      styleOnSelected: '',
    },
    configButtonRight: {
      style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
      height: Constants.BUTTON_HEIGHT_48PX,
      text: 'Eliminar búsqueda',
      id: 'delete',
      selected: false,
      isDisabled: false,
      changeStyleOnSelected: false,
      styleOnSelected: '',
    },
    dinamicComponent: 'save-search-delete',
    isTopbarVisible: true,
    isBotbarVisible: true,
    isHeightAutoFitContent: true,
  };

  private readonly destroy$: Subject<void> = new Subject<void>();
  private currentSearch: UserSaveSearch | null = null;
  private isBrowser: boolean = false;
  private readonly listingTypes$: Promise<ItemParam[]>;
  private readonly dataOperation$: Promise<ItemParam[]>;
  private readonly dataFeatures$: Promise<ItemParam[]>;
  private readonly currencyTypes$: Promise<ItemParam[]>;
  private readonly yearBuild$: Promise<ItemParam[]>;
  private readonly roomsTypes$: Promise<ItemParam[]>;
  private readonly bathroomsTypes$: Promise<ItemParam[]>;
  private readonly bedroomsTypes$: Promise<ItemParam[]>;
  private readonly parkingSpacesType$: Promise<ItemParam[]>;
  private readonly dataStage$: Promise<ItemParam[]>;

  constructor(
    private userSearchSaveService: UserSearchSaveService,
    private paramsDomain: ParamsDomain,
    private translation: L10nTranslationService,
    private titleService: Title
  ) {
    this.listingTypes$ = this.paramsDomain.getListingsTypes();
    this.dataOperation$ = this.paramsDomain.getOperationsTypes();
    this.dataFeatures$ = this.paramsDomain.getFeaturesTypes();
    this.currencyTypes$ = this.paramsDomain.getCurrenciesTypes();
    this.roomsTypes$ = this.paramsDomain.getRoomsTypes();
    this.bathroomsTypes$ = this.paramsDomain.getBathRoomsTypes();
    this.bedroomsTypes$ = this.paramsDomain.getBedroomsTypes();
    this.parkingSpacesType$ = this.paramsDomain.getParkingSpacesTypes();
    this.yearBuild$ = this.paramsDomain.getYearsBuildTypes();
    this.dataStage$ = this.paramsDomain.getStage();
  }

  ngOnInit(): void {
    this.titleService.setTitle('Búsquedas Guardadas | RE/MAX');
    Auth.currentAuthenticatedUser()
      .then(() => {
        this.fetchUserSaveSearch();
        this.loadUserSaveSearches();
      })
      .catch(error => {
        console.error('Error fetching authenticated user:', error);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public openDeleteModal(search: UserSaveSearch): void {
    this.currentSearch = search;
    this.dialogDelete = {
      ...this.dialogDelete,
      isDialogOpen: true,
    };
    this.dialogDelete = Object.assign({}, this.dialogDelete);
  }

  public openSaveSearch(search: UserSaveSearch): void {
    this.currentSearch = search;
    this.dialogData = {
      ...this.dialogData,
      isDialogOpen: true,
    };
    this.dialogData = { ...this.dialogData };
  }

  public update(eventData: string): void {
    if (this.currentSearch) {
      const BODY: { name: string } = {
        name: eventData,
      };
      this.userSearchSaveService
        .updateData<string, { name: string }>(`${this.currentSearch.id}`, BODY)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            this.userSaveSearch = this.userSaveSearch.map(s => {
              if (s.id === this.currentSearch?.id) {
                return { ...s, name: BODY.name };
              }
              return s;
            });
          },
          error: err => {
            console.error('Error al editar la búsqueda', err);
          },
        });
    }
  }

  public onDelete(): void {
    if (this.currentSearch) {
      this.userSearchSaveService
        .deleteData<string>(this.currentSearch.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            this.userSaveSearch = this.userSaveSearch.filter(
              search => search.id !== this.currentSearch?.id
            );
            if (this.userSaveSearch.length == 0) {
              this.page = this.page - 1 < 0 ? 0 : this.page - 1;
              this.loadUserSaveSearches();
            }
          },
          error: err => {
            console.error('Error al eliminar la búsqueda', err);
          },
        });
    }
  }

  public formatUrl(): void {
    this.userSaveSearch.forEach((search: UserSaveSearch) => {
      search.formattedUrl = `${search.moduleType}${this.correctQueryFormat(
        search.url
      )}`;
    });
  }

  async pagination($event: number): Promise<void> {
    if (this.isBrowser) {
      const ELMT = document.getElementById('save-searches');
      ELMT?.scrollIntoView({ behavior: 'smooth' });
    }
    this.page = $event;
    await this.loadUserSaveSearches();
    if (this.userSaveSearch.length == 0) {
      this.page = 0;
      this.loadUserSaveSearches();
    }
  }

  private fetchUserSaveSearch(): Observable<IQrResponse<IQrPage>> {
    const FILTER = new Filter();
    FILTER.addFilter('size', this.pageSize);
    FILTER.addFilter('page', this.page);

    const QUERY: string = FILTER.toQueryString();
    return this.userSearchSaveService
      .getParams<string, IQrResponse<IQrPage>>(QUERY)
      .pipe(takeUntil(this.destroy$));
  }

  private loadUserSaveSearches(): void {
    this.fetchUserSaveSearch().subscribe({
      next: (response: IQrResponse<IQrPage>) => {
        if (response.code === 200) {
          this.userSaveSearch = response.data.data;
          this.totalItems = response.data.totalItems;
          this.visiblePagination = this.totalItems > 10;
          this.userSaveSearch.forEach(search => (search.tags = []));
          this.loadTags();
          this.formatUrl();
        }
      },
      error: error => {
        console.error('Error occurred:', error);
      },
    });
  }

  private async loadTags(): Promise<void> {
    try {
      const REPONSE: IQrResponse<IQrPage> | undefined =
        await this.fetchUserSaveSearch().toPromise();
      if (REPONSE?.code === 200) {
        await Promise.all([
          this.loadTypes(),
          this.loadStages(),
          this.loadOperationTypes(),
          this.loadBathroom(),
          this.loadParkingSpaces(),
          this.loadRooms(),
          this.loadBedrooms(),
          this.loadAptCredit(),
          this.loadLocation(),
          this.loadPrice(),
          this.loadEntrepreneurshipTag(),
        ]);
      }
    } catch (error) {
      console.error('Error occurred:', error);
    }
  }

  private async loadTypes() {
    const TYPES: ItemParam[] = await this.listingTypes$;
    this.userSaveSearch.forEach(search => {
      if (search.params?.filters) {
        const FILTERS: SaveFilter[] = search.params.filters;
        FILTERS.filter(filter => filter.key !== null).forEach(filter => {
          if (filter.key === 'typeId' && typeof filter.value === 'string') {
            const TYPE_IDS: number[] = filter.value
              .split(',')
              .map(id => parseInt(id, 10));
            const NEW_TAGS: string[] = TYPES.filter(type =>
              TYPE_IDS.includes(type.id)
            ).map(type =>
              this.translation.translate('property-type.' + type.value)
            );
            search.tags = [...(search.tags || []), ...NEW_TAGS];
          }
        });
      }
    });
  }

  private async loadStages() {
    const TYPES: ItemParam[] = await this.dataStage$;
    this.userSaveSearch.forEach(search => {
      if (search.params?.filters) {
        const FILTERS: SaveFilter[] = search.params.filters;
        FILTERS.filter(filter => filter.key !== null).forEach(filter => {
          if (filter.key === 'eStageId' && typeof filter.value === 'string') {
            const ESTAGE_IDS: number[] = filter.value
              .split(',')
              .map(id => parseInt(id, 10));
            const NEW_TAGS: string[] = TYPES.filter(type =>
              ESTAGE_IDS.includes(type.id)
            ).map(type => {
              let typeOperation: string;

              switch (type.value) {
                case 'sale':
                  typeOperation = 'Venta';
                  break;
                case 'rent':
                  typeOperation = 'Alquiler';
                  break;
                case 'presale':
                  typeOperation = 'Pre venta';
                  break;
                case 'finished':
                  typeOperation = 'Terminado';
                  break;
                case 'in_construction':
                  typeOperation = 'En construcción';
                  break;
                case 'pozo':
                  typeOperation = 'Pozo';
                  break;
                default:
                  typeOperation = 'alquiler-temporario';
              }

              return this.translation.translate(`${typeOperation}`);
            });
            search.tags = [...(search.tags || []), ...NEW_TAGS];
          }
        });
      }
    });
  }

  private async loadOperationTypes() {
    const TYPES: ItemParam[] = await this.dataOperation$;
    this.userSaveSearch.forEach(search => {
      if (search.params?.filters) {
        const FILTERS: SaveFilter[] = search.params.filters;
        FILTERS.filter(filter => filter.key !== null).forEach(filter => {
          if (
            filter.key === 'operationId' &&
            typeof filter.value === 'string'
          ) {
            const OPERATIONS_IDS: number[] = filter.value
              .split(',')
              .map(id => parseInt(id, 10));
            const NEW_TAGS: string[] = TYPES.filter(type =>
              OPERATIONS_IDS.includes(type.id)
            ).map(type => {
              let typeOperation: string;
              switch (type.value) {
                case 'sale':
                  typeOperation = 'Venta';
                  break;
                case 'rent':
                  typeOperation = 'Alquiler';
                  break;
                default:
                  typeOperation = 'Alquiler temporario';
              }

              return this.translation.translate(`${typeOperation}`);
            });

            search.tags = [...(search.tags || []), ...NEW_TAGS];
          }
        });
      }
    });
  }

  private async loadBathroom() {
    const TYPES: ItemParam[] = await this.parkingSpacesType$;
    this.userSaveSearch.forEach(search => {
      if (search.params?.filters) {
        const FILTERS: SaveFilter[] = search.params.filters;
        FILTERS.filter(filter => filter.key !== null).forEach(filter => {
          if (filter.key === 'bathrooms' && typeof filter.value === 'string') {
            const TYPE_IDS: number[] = filter.value
              .split(',')
              .map(id => parseInt(id, 10));
            const NEW_TAGS: string[] = TYPES.filter(type =>
              TYPE_IDS.includes(type.id)
            ).map(type => `${type.value} Baño`);
            search.tags = [...(search.tags || []), ...NEW_TAGS];
          }
        });
      }
    });
  }

  private async loadParkingSpaces() {
    const TYPES: ItemParam[] = await this.bathroomsTypes$;
    this.userSaveSearch.forEach(search => {
      if (search.params?.filters) {
        const FILTERS: SaveFilter[] = search.params.filters;
        FILTERS.filter(filter => filter.key !== null).forEach(filter => {
          if (
            filter.key === 'parkingSpaces' &&
            typeof filter.value === 'string'
          ) {
            const TYPE_IDS: number[] = filter.value
              .split(',')
              .map(id => parseInt(id, 10));
            const NEW_TAGS: string[] = TYPES.filter(type =>
              TYPE_IDS.includes(type.id)
            ).map(type => `${type.value} Cochera`);
            search.tags = [...(search.tags || []), ...NEW_TAGS];
          }
        });
      }
    });
  }

  private async loadRooms() {
    const TYPES: ItemParam[] = await this.roomsTypes$;
    this.userSaveSearch.forEach(search => {
      if (search.params?.filters) {
        const FILTERS: SaveFilter[] = search.params.filters;
        FILTERS.filter(filter => filter.key !== null).forEach(filter => {
          if (filter.key === 'totalRooms' && typeof filter.value === 'string') {
            const TYPE_IDS: number[] = filter.value
              .split(',')
              .map(id => parseInt(id, 10));
            const NEW_TAGS: string[] = TYPES.filter(type =>
              TYPE_IDS.includes(type.id)
            ).map(type => `${type.value} Ambientes`);
            search.tags = [...(search.tags || []), ...NEW_TAGS];
          }
        });
      }
    });
  }

  private async loadBedrooms() {
    const TYPES: ItemParam[] = await this.bedroomsTypes$;
    this.userSaveSearch.forEach(search => {
      if (search.params?.filters) {
        const FILTERS: SaveFilter[] = search.params.filters;
        FILTERS.filter(filter => filter.key !== null).forEach(filter => {
          if (filter.key === 'totalRooms' && typeof filter.value === 'string') {
            const TYPE_IDS: number[] = filter.value
              .split(',')
              .map(id => parseInt(id, 10));
            const NEW_TAGS: string[] = TYPES.filter(type =>
              TYPE_IDS.includes(type.id)
            ).map(type => `${type.value} Dromitorio`);
            search.tags = [...(search.tags || []), ...NEW_TAGS];
          }
        });
      }
    });
  }

  private async loadAptCredit() {
    this.userSaveSearch.forEach(search => {
      if (search.params?.filters) {
        const FILTERS: SaveFilter[] = search.params.filters;
        FILTERS.filter(filter => filter.key !== null).forEach(filter => {
          if (filter.key === 'aptCredit' && filter.value === true) {
            const NEW_TAG: string = 'Apto crédito';
            search.tags = [...(search.tags || []), NEW_TAG];
          }
        });
      }
    });
  }

  private async loadLocation() {
    this.userSaveSearch.forEach(search => {
      if (search.params?.filters) {
        const FILTERS: SaveFilter[] = search.params.filters;
        FILTERS.filter(filter => filter.key !== null).forEach(filter => {
          if (
            filter.key === 'in' &&
            filter.operation === 'locations' &&
            typeof filter.value === 'string'
          ) {
            // Limpia el valor de ubicación y agrega el tag
            const CLEANED_LOCATION: string = this.cleanLocation(filter.value);
            const NEW_TAG: string = CLEANED_LOCATION;
            search.tags = [...(search.tags || []), NEW_TAG];
          }
        });
      }
    });
  }

  private cleanLocation(value: string): string {
    const CLEANED_VALUE: string = value
      .replace(/<\/?[^>]+>/gi, '')
      .replace(/:::/g, '')
      .replace(/::/g, '')
      .replace(/:/g, '')
      .replace(/#/g, ' ')
      .replace(/@/g, '')
      .replace(/[0-9]/g, '')
      .trim();

    return CLEANED_VALUE;
  }

  private async loadPrice() {
    this.userSaveSearch.forEach(search => {
      if (search.params?.filters) {
        const FILTERS: SaveFilter[] = search.params.filters;
        FILTERS.filter(filter => filter.key !== null).forEach(filter => {
          if (
            filter.key === '1' &&
            filter.operation === 'pricein' &&
            typeof filter.value === 'string'
          ) {
            const FORMATTED_PRICE_RANGE: string = this.formatPriceRange(
              filter.value
            );
            const NEW_TAG: string = FORMATTED_PRICE_RANGE;
            search.tags = [...(search.tags || []), NEW_TAG];
          }
        });
      }
    });
  }

  private async loadEntrepreneurshipTag() {
    this.userSaveSearch.forEach(search => {
      if (search.params?.filters) {
        const FILTERS: SaveFilter[] = search.params.filters;
        FILTERS.filter(filter => filter.key !== null).forEach(filter => {
          if (
            filter.key === 'entrepreneurship' &&
            filter.operation === 'eq' &&
            filter.value === true
          ) {
            const NEW_TAG: string =
              this.translation.translate('Emprendimiento');
            search.tags = [...(search.tags || []), NEW_TAG];
          }
        });
      }
    });
  }

  private formatPriceRange(value: string): string {
    const [min, max] = value.split(':');
    return `${min} - ${max}`;
  }

  private correctQueryFormat(query: string): string {
    const REGEX: RegExp = /(in|eq|lte|gte)=([a-zA-Z]+):([\w\d,]+)/g;

    const FORMAT_QUERY: string = query.replace(
      REGEX,
      (match, prefix, field, value) => {
        return `${prefix}:${field}=${value}`;
      }
    );

    return FORMAT_QUERY;
  }
}
